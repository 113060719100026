module.exports = [
["10k", require('@material-icons/svg/svg/10k/baseline.svg'), {className: 'material'}],
["10mp", require('@material-icons/svg/svg/10mp/baseline.svg'), {className: 'material'}],
["11mp", require('@material-icons/svg/svg/11mp/baseline.svg'), {className: 'material'}],
["123", require('@material-icons/svg/svg/123/baseline.svg'), {className: 'material'}],
["12mp", require('@material-icons/svg/svg/12mp/baseline.svg'), {className: 'material'}],
["13mp", require('@material-icons/svg/svg/13mp/baseline.svg'), {className: 'material'}],
["14mp", require('@material-icons/svg/svg/14mp/baseline.svg'), {className: 'material'}],
["15mp", require('@material-icons/svg/svg/15mp/baseline.svg'), {className: 'material'}],
["16mp", require('@material-icons/svg/svg/16mp/baseline.svg'), {className: 'material'}],
["17mp", require('@material-icons/svg/svg/17mp/baseline.svg'), {className: 'material'}],
["18mp", require('@material-icons/svg/svg/18mp/baseline.svg'), {className: 'material'}],
["19mp", require('@material-icons/svg/svg/19mp/baseline.svg'), {className: 'material'}],
["1k", require('@material-icons/svg/svg/1k/baseline.svg'), {className: 'material'}],
["1k_plus", require('@material-icons/svg/svg/1k_plus/baseline.svg'), {className: 'material'}],
["1x_mobiledata", require('@material-icons/svg/svg/1x_mobiledata/baseline.svg'), {className: 'material'}],
["20mp", require('@material-icons/svg/svg/20mp/baseline.svg'), {className: 'material'}],
["21mp", require('@material-icons/svg/svg/21mp/baseline.svg'), {className: 'material'}],
["22mp", require('@material-icons/svg/svg/22mp/baseline.svg'), {className: 'material'}],
["23mp", require('@material-icons/svg/svg/23mp/baseline.svg'), {className: 'material'}],
["24mp", require('@material-icons/svg/svg/24mp/baseline.svg'), {className: 'material'}],
["2k", require('@material-icons/svg/svg/2k/baseline.svg'), {className: 'material'}],
["2k_plus", require('@material-icons/svg/svg/2k_plus/baseline.svg'), {className: 'material'}],
["2mp", require('@material-icons/svg/svg/2mp/baseline.svg'), {className: 'material'}],
["30fps", require('@material-icons/svg/svg/30fps/baseline.svg'), {className: 'material'}],
["30fps_select", require('@material-icons/svg/svg/30fps_select/baseline.svg'), {className: 'material'}],
["360", require('@material-icons/svg/svg/360/baseline.svg'), {className: 'material'}],
["3d_rotation", require('@material-icons/svg/svg/3d_rotation/baseline.svg'), {className: 'material'}],
["3g_mobiledata", require('@material-icons/svg/svg/3g_mobiledata/baseline.svg'), {className: 'material'}],
["3k", require('@material-icons/svg/svg/3k/baseline.svg'), {className: 'material'}],
["3k_plus", require('@material-icons/svg/svg/3k_plus/baseline.svg'), {className: 'material'}],
["3mp", require('@material-icons/svg/svg/3mp/baseline.svg'), {className: 'material'}],
["3p", require('@material-icons/svg/svg/3p/baseline.svg'), {className: 'material'}],
["4g_mobiledata", require('@material-icons/svg/svg/4g_mobiledata/baseline.svg'), {className: 'material'}],
["4g_plus_mobiledata", require('@material-icons/svg/svg/4g_plus_mobiledata/baseline.svg'), {className: 'material'}],
["4k", require('@material-icons/svg/svg/4k/baseline.svg'), {className: 'material'}],
["4k_plus", require('@material-icons/svg/svg/4k_plus/baseline.svg'), {className: 'material'}],
["4mp", require('@material-icons/svg/svg/4mp/baseline.svg'), {className: 'material'}],
["5g", require('@material-icons/svg/svg/5g/baseline.svg'), {className: 'material'}],
["5k", require('@material-icons/svg/svg/5k/baseline.svg'), {className: 'material'}],
["5k_plus", require('@material-icons/svg/svg/5k_plus/baseline.svg'), {className: 'material'}],
["5mp", require('@material-icons/svg/svg/5mp/baseline.svg'), {className: 'material'}],
["6_ft_apart", require('@material-icons/svg/svg/6_ft_apart/baseline.svg'), {className: 'material'}],
["60fps", require('@material-icons/svg/svg/60fps/baseline.svg'), {className: 'material'}],
["60fps_select", require('@material-icons/svg/svg/60fps_select/baseline.svg'), {className: 'material'}],
["6k", require('@material-icons/svg/svg/6k/baseline.svg'), {className: 'material'}],
["6k_plus", require('@material-icons/svg/svg/6k_plus/baseline.svg'), {className: 'material'}],
["6mp", require('@material-icons/svg/svg/6mp/baseline.svg'), {className: 'material'}],
["7k", require('@material-icons/svg/svg/7k/baseline.svg'), {className: 'material'}],
["7k_plus", require('@material-icons/svg/svg/7k_plus/baseline.svg'), {className: 'material'}],
["7mp", require('@material-icons/svg/svg/7mp/baseline.svg'), {className: 'material'}],
["8k", require('@material-icons/svg/svg/8k/baseline.svg'), {className: 'material'}],
["8k_plus", require('@material-icons/svg/svg/8k_plus/baseline.svg'), {className: 'material'}],
["8mp", require('@material-icons/svg/svg/8mp/baseline.svg'), {className: 'material'}],
["9k", require('@material-icons/svg/svg/9k/baseline.svg'), {className: 'material'}],
["9k_plus", require('@material-icons/svg/svg/9k_plus/baseline.svg'), {className: 'material'}],
["9mp", require('@material-icons/svg/svg/9mp/baseline.svg'), {className: 'material'}],
["abc", require('@material-icons/svg/svg/abc/baseline.svg'), {className: 'material'}],
["ac_unit", require('@material-icons/svg/svg/ac_unit/baseline.svg'), {className: 'material'}],
["access_alarm", require('@material-icons/svg/svg/access_alarm/baseline.svg'), {className: 'material'}],
["access_alarms", require('@material-icons/svg/svg/access_alarms/baseline.svg'), {className: 'material'}],
["access_time", require('@material-icons/svg/svg/access_time/baseline.svg'), {className: 'material'}],
["access_time_filled", require('@material-icons/svg/svg/access_time_filled/baseline.svg'), {className: 'material'}],
["accessibility", require('@material-icons/svg/svg/accessibility/baseline.svg'), {className: 'material'}],
["accessibility_new", require('@material-icons/svg/svg/accessibility_new/baseline.svg'), {className: 'material'}],
["accessible", require('@material-icons/svg/svg/accessible/baseline.svg'), {className: 'material'}],
["accessible_forward", require('@material-icons/svg/svg/accessible_forward/baseline.svg'), {className: 'material'}],
["account_balance", require('@material-icons/svg/svg/account_balance/baseline.svg'), {className: 'material'}],
["account_balance_wallet", require('@material-icons/svg/svg/account_balance_wallet/baseline.svg'), {className: 'material'}],
["account_box", require('@material-icons/svg/svg/account_box/baseline.svg'), {className: 'material'}],
["account_circle", require('@material-icons/svg/svg/account_circle/baseline.svg'), {className: 'material'}],
["account_tree", require('@material-icons/svg/svg/account_tree/baseline.svg'), {className: 'material'}],
["ad_units", require('@material-icons/svg/svg/ad_units/baseline.svg'), {className: 'material'}],
["adb", require('@material-icons/svg/svg/adb/baseline.svg'), {className: 'material'}],
["add", require('@material-icons/svg/svg/add/baseline.svg'), {className: 'material'}],
["add_a_photo", require('@material-icons/svg/svg/add_a_photo/baseline.svg'), {className: 'material'}],
["add_alarm", require('@material-icons/svg/svg/add_alarm/baseline.svg'), {className: 'material'}],
["add_alert", require('@material-icons/svg/svg/add_alert/baseline.svg'), {className: 'material'}],
["add_box", require('@material-icons/svg/svg/add_box/baseline.svg'), {className: 'material'}],
["add_business", require('@material-icons/svg/svg/add_business/baseline.svg'), {className: 'material'}],
["add_card", require('@material-icons/svg/svg/add_card/baseline.svg'), {className: 'material'}],
["add_chart", require('@material-icons/svg/svg/add_chart/baseline.svg'), {className: 'material'}],
["add_circle", require('@material-icons/svg/svg/add_circle/baseline.svg'), {className: 'material'}],
["add_circle_outline", require('@material-icons/svg/svg/add_circle_outline/baseline.svg'), {className: 'material'}],
["add_comment", require('@material-icons/svg/svg/add_comment/baseline.svg'), {className: 'material'}],
["add_ic_call", require('@material-icons/svg/svg/add_ic_call/baseline.svg'), {className: 'material'}],
["add_link", require('@material-icons/svg/svg/add_link/baseline.svg'), {className: 'material'}],
["add_location", require('@material-icons/svg/svg/add_location/baseline.svg'), {className: 'material'}],
["add_location_alt", require('@material-icons/svg/svg/add_location_alt/baseline.svg'), {className: 'material'}],
["add_moderator", require('@material-icons/svg/svg/add_moderator/baseline.svg'), {className: 'material'}],
["add_photo_alternate", require('@material-icons/svg/svg/add_photo_alternate/baseline.svg'), {className: 'material'}],
["add_reaction", require('@material-icons/svg/svg/add_reaction/baseline.svg'), {className: 'material'}],
["add_road", require('@material-icons/svg/svg/add_road/baseline.svg'), {className: 'material'}],
["add_shopping_cart", require('@material-icons/svg/svg/add_shopping_cart/baseline.svg'), {className: 'material'}],
["add_task", require('@material-icons/svg/svg/add_task/baseline.svg'), {className: 'material'}],
["add_to_drive", require('@material-icons/svg/svg/add_to_drive/baseline.svg'), {className: 'material'}],
["add_to_home_screen", require('@material-icons/svg/svg/add_to_home_screen/baseline.svg'), {className: 'material'}],
["add_to_photos", require('@material-icons/svg/svg/add_to_photos/baseline.svg'), {className: 'material'}],
["add_to_queue", require('@material-icons/svg/svg/add_to_queue/baseline.svg'), {className: 'material'}],
["addchart", require('@material-icons/svg/svg/addchart/baseline.svg'), {className: 'material'}],
["adf_scanner", require('@material-icons/svg/svg/adf_scanner/baseline.svg'), {className: 'material'}],
["adjust", require('@material-icons/svg/svg/adjust/baseline.svg'), {className: 'material'}],
["admin_panel_settings", require('@material-icons/svg/svg/admin_panel_settings/baseline.svg'), {className: 'material'}],
["adobe", require('@material-icons/svg/svg/adobe/baseline.svg'), {className: 'material'}],
["ads_click", require('@material-icons/svg/svg/ads_click/baseline.svg'), {className: 'material'}],
["agriculture", require('@material-icons/svg/svg/agriculture/baseline.svg'), {className: 'material'}],
["air", require('@material-icons/svg/svg/air/baseline.svg'), {className: 'material'}],
["airline_seat_flat", require('@material-icons/svg/svg/airline_seat_flat/baseline.svg'), {className: 'material'}],
["airline_seat_flat_angled", require('@material-icons/svg/svg/airline_seat_flat_angled/baseline.svg'), {className: 'material'}],
["airline_seat_individual_suite", require('@material-icons/svg/svg/airline_seat_individual_suite/baseline.svg'), {className: 'material'}],
["airline_seat_legroom_extra", require('@material-icons/svg/svg/airline_seat_legroom_extra/baseline.svg'), {className: 'material'}],
["airline_seat_legroom_normal", require('@material-icons/svg/svg/airline_seat_legroom_normal/baseline.svg'), {className: 'material'}],
["airline_seat_legroom_reduced", require('@material-icons/svg/svg/airline_seat_legroom_reduced/baseline.svg'), {className: 'material'}],
["airline_seat_recline_extra", require('@material-icons/svg/svg/airline_seat_recline_extra/baseline.svg'), {className: 'material'}],
["airline_seat_recline_normal", require('@material-icons/svg/svg/airline_seat_recline_normal/baseline.svg'), {className: 'material'}],
["airline_stops", require('@material-icons/svg/svg/airline_stops/baseline.svg'), {className: 'material'}],
["airlines", require('@material-icons/svg/svg/airlines/baseline.svg'), {className: 'material'}],
["airplane_ticket", require('@material-icons/svg/svg/airplane_ticket/baseline.svg'), {className: 'material'}],
["airplanemode_active", require('@material-icons/svg/svg/airplanemode_active/baseline.svg'), {className: 'material'}],
["airplanemode_inactive", require('@material-icons/svg/svg/airplanemode_inactive/baseline.svg'), {className: 'material'}],
["airplay", require('@material-icons/svg/svg/airplay/baseline.svg'), {className: 'material'}],
["airport_shuttle", require('@material-icons/svg/svg/airport_shuttle/baseline.svg'), {className: 'material'}],
["alarm", require('@material-icons/svg/svg/alarm/baseline.svg'), {className: 'material'}],
["alarm_add", require('@material-icons/svg/svg/alarm_add/baseline.svg'), {className: 'material'}],
["alarm_off", require('@material-icons/svg/svg/alarm_off/baseline.svg'), {className: 'material'}],
["alarm_on", require('@material-icons/svg/svg/alarm_on/baseline.svg'), {className: 'material'}],
["album", require('@material-icons/svg/svg/album/baseline.svg'), {className: 'material'}],
["align_horizontal_center", require('@material-icons/svg/svg/align_horizontal_center/baseline.svg'), {className: 'material'}],
["align_horizontal_left", require('@material-icons/svg/svg/align_horizontal_left/baseline.svg'), {className: 'material'}],
["align_horizontal_right", require('@material-icons/svg/svg/align_horizontal_right/baseline.svg'), {className: 'material'}],
["align_vertical_bottom", require('@material-icons/svg/svg/align_vertical_bottom/baseline.svg'), {className: 'material'}],
["align_vertical_center", require('@material-icons/svg/svg/align_vertical_center/baseline.svg'), {className: 'material'}],
["align_vertical_top", require('@material-icons/svg/svg/align_vertical_top/baseline.svg'), {className: 'material'}],
["all_inbox", require('@material-icons/svg/svg/all_inbox/baseline.svg'), {className: 'material'}],
["all_inclusive", require('@material-icons/svg/svg/all_inclusive/baseline.svg'), {className: 'material'}],
["all_out", require('@material-icons/svg/svg/all_out/baseline.svg'), {className: 'material'}],
["alt_route", require('@material-icons/svg/svg/alt_route/baseline.svg'), {className: 'material'}],
["alternate_email", require('@material-icons/svg/svg/alternate_email/baseline.svg'), {className: 'material'}],
["amp_stories", require('@material-icons/svg/svg/amp_stories/baseline.svg'), {className: 'material'}],
["analytics", require('@material-icons/svg/svg/analytics/baseline.svg'), {className: 'material'}],
["anchor", require('@material-icons/svg/svg/anchor/baseline.svg'), {className: 'material'}],
["android", require('@material-icons/svg/svg/android/baseline.svg'), {className: 'material'}],
["animation", require('@material-icons/svg/svg/animation/baseline.svg'), {className: 'material'}],
["announcement", require('@material-icons/svg/svg/announcement/baseline.svg'), {className: 'material'}],
["aod", require('@material-icons/svg/svg/aod/baseline.svg'), {className: 'material'}],
["apartment", require('@material-icons/svg/svg/apartment/baseline.svg'), {className: 'material'}],
["api", require('@material-icons/svg/svg/api/baseline.svg'), {className: 'material'}],
["app_blocking", require('@material-icons/svg/svg/app_blocking/baseline.svg'), {className: 'material'}],
["app_registration", require('@material-icons/svg/svg/app_registration/baseline.svg'), {className: 'material'}],
["app_settings_alt", require('@material-icons/svg/svg/app_settings_alt/baseline.svg'), {className: 'material'}],
["app_shortcut", require('@material-icons/svg/svg/app_shortcut/baseline.svg'), {className: 'material'}],
["apple", require('@material-icons/svg/svg/apple/baseline.svg'), {className: 'material'}],
["approval", require('@material-icons/svg/svg/approval/baseline.svg'), {className: 'material'}],
["apps", require('@material-icons/svg/svg/apps/baseline.svg'), {className: 'material'}],
["apps_outage", require('@material-icons/svg/svg/apps_outage/baseline.svg'), {className: 'material'}],
["architecture", require('@material-icons/svg/svg/architecture/baseline.svg'), {className: 'material'}],
["archive", require('@material-icons/svg/svg/archive/baseline.svg'), {className: 'material'}],
["area_chart", require('@material-icons/svg/svg/area_chart/baseline.svg'), {className: 'material'}],
["arrow_back", require('@material-icons/svg/svg/arrow_back/baseline.svg'), {className: 'material'}],
["arrow_back_ios", require('@material-icons/svg/svg/arrow_back_ios/baseline.svg'), {className: 'material'}],
["arrow_back_ios_new", require('@material-icons/svg/svg/arrow_back_ios_new/baseline.svg'), {className: 'material'}],
["arrow_circle_down", require('@material-icons/svg/svg/arrow_circle_down/baseline.svg'), {className: 'material'}],
["arrow_circle_left", require('@material-icons/svg/svg/arrow_circle_left/baseline.svg'), {className: 'material'}],
["arrow_circle_right", require('@material-icons/svg/svg/arrow_circle_right/baseline.svg'), {className: 'material'}],
["arrow_circle_up", require('@material-icons/svg/svg/arrow_circle_up/baseline.svg'), {className: 'material'}],
["arrow_downward", require('@material-icons/svg/svg/arrow_downward/baseline.svg'), {className: 'material'}],
["arrow_drop_down", require('@material-icons/svg/svg/arrow_drop_down/baseline.svg'), {className: 'material'}],
["arrow_drop_down_circle", require('@material-icons/svg/svg/arrow_drop_down_circle/baseline.svg'), {className: 'material'}],
["arrow_drop_up", require('@material-icons/svg/svg/arrow_drop_up/baseline.svg'), {className: 'material'}],
["arrow_forward", require('@material-icons/svg/svg/arrow_forward/baseline.svg'), {className: 'material'}],
["arrow_forward_ios", require('@material-icons/svg/svg/arrow_forward_ios/baseline.svg'), {className: 'material'}],
["arrow_left", require('@material-icons/svg/svg/arrow_left/baseline.svg'), {className: 'material'}],
["arrow_right", require('@material-icons/svg/svg/arrow_right/baseline.svg'), {className: 'material'}],
["arrow_right_alt", require('@material-icons/svg/svg/arrow_right_alt/baseline.svg'), {className: 'material'}],
["arrow_upward", require('@material-icons/svg/svg/arrow_upward/baseline.svg'), {className: 'material'}],
["art_track", require('@material-icons/svg/svg/art_track/baseline.svg'), {className: 'material'}],
["article", require('@material-icons/svg/svg/article/baseline.svg'), {className: 'material'}],
["aspect_ratio", require('@material-icons/svg/svg/aspect_ratio/baseline.svg'), {className: 'material'}],
["assessment", require('@material-icons/svg/svg/assessment/baseline.svg'), {className: 'material'}],
["assignment", require('@material-icons/svg/svg/assignment/baseline.svg'), {className: 'material'}],
["assignment_ind", require('@material-icons/svg/svg/assignment_ind/baseline.svg'), {className: 'material'}],
["assignment_late", require('@material-icons/svg/svg/assignment_late/baseline.svg'), {className: 'material'}],
["assignment_return", require('@material-icons/svg/svg/assignment_return/baseline.svg'), {className: 'material'}],
["assignment_returned", require('@material-icons/svg/svg/assignment_returned/baseline.svg'), {className: 'material'}],
["assignment_turned_in", require('@material-icons/svg/svg/assignment_turned_in/baseline.svg'), {className: 'material'}],
["assistant", require('@material-icons/svg/svg/assistant/baseline.svg'), {className: 'material'}],
["assistant_direction", require('@material-icons/svg/svg/assistant_direction/baseline.svg'), {className: 'material'}],
["assistant_photo", require('@material-icons/svg/svg/assistant_photo/baseline.svg'), {className: 'material'}],
["assured_workload", require('@material-icons/svg/svg/assured_workload/baseline.svg'), {className: 'material'}],
["atm", require('@material-icons/svg/svg/atm/baseline.svg'), {className: 'material'}],
["attach_email", require('@material-icons/svg/svg/attach_email/baseline.svg'), {className: 'material'}],
["attach_file", require('@material-icons/svg/svg/attach_file/baseline.svg'), {className: 'material'}],
["attach_money", require('@material-icons/svg/svg/attach_money/baseline.svg'), {className: 'material'}],
["attachment", require('@material-icons/svg/svg/attachment/baseline.svg'), {className: 'material'}],
["attractions", require('@material-icons/svg/svg/attractions/baseline.svg'), {className: 'material'}],
["attribution", require('@material-icons/svg/svg/attribution/baseline.svg'), {className: 'material'}],
["audio_file", require('@material-icons/svg/svg/audio_file/baseline.svg'), {className: 'material'}],
["audiotrack", require('@material-icons/svg/svg/audiotrack/baseline.svg'), {className: 'material'}],
["auto_awesome", require('@material-icons/svg/svg/auto_awesome/baseline.svg'), {className: 'material'}],
["auto_awesome_mosaic", require('@material-icons/svg/svg/auto_awesome_mosaic/baseline.svg'), {className: 'material'}],
["auto_awesome_motion", require('@material-icons/svg/svg/auto_awesome_motion/baseline.svg'), {className: 'material'}],
["auto_delete", require('@material-icons/svg/svg/auto_delete/baseline.svg'), {className: 'material'}],
["auto_fix_high", require('@material-icons/svg/svg/auto_fix_high/baseline.svg'), {className: 'material'}],
["auto_fix_normal", require('@material-icons/svg/svg/auto_fix_normal/baseline.svg'), {className: 'material'}],
["auto_fix_off", require('@material-icons/svg/svg/auto_fix_off/baseline.svg'), {className: 'material'}],
["auto_graph", require('@material-icons/svg/svg/auto_graph/baseline.svg'), {className: 'material'}],
["auto_stories", require('@material-icons/svg/svg/auto_stories/baseline.svg'), {className: 'material'}],
["autofps_select", require('@material-icons/svg/svg/autofps_select/baseline.svg'), {className: 'material'}],
["autorenew", require('@material-icons/svg/svg/autorenew/baseline.svg'), {className: 'material'}],
["av_timer", require('@material-icons/svg/svg/av_timer/baseline.svg'), {className: 'material'}],
["baby_changing_station", require('@material-icons/svg/svg/baby_changing_station/baseline.svg'), {className: 'material'}],
["back_hand", require('@material-icons/svg/svg/back_hand/baseline.svg'), {className: 'material'}],
["backpack", require('@material-icons/svg/svg/backpack/baseline.svg'), {className: 'material'}],
["backspace", require('@material-icons/svg/svg/backspace/baseline.svg'), {className: 'material'}],
["backup", require('@material-icons/svg/svg/backup/baseline.svg'), {className: 'material'}],
["backup_table", require('@material-icons/svg/svg/backup_table/baseline.svg'), {className: 'material'}],
["badge", require('@material-icons/svg/svg/badge/baseline.svg'), {className: 'material'}],
["bakery_dining", require('@material-icons/svg/svg/bakery_dining/baseline.svg'), {className: 'material'}],
["balance", require('@material-icons/svg/svg/balance/baseline.svg'), {className: 'material'}],
["balcony", require('@material-icons/svg/svg/balcony/baseline.svg'), {className: 'material'}],
["ballot", require('@material-icons/svg/svg/ballot/baseline.svg'), {className: 'material'}],
["bar_chart", require('@material-icons/svg/svg/bar_chart/baseline.svg'), {className: 'material'}],
["batch_prediction", require('@material-icons/svg/svg/batch_prediction/baseline.svg'), {className: 'material'}],
["bathroom", require('@material-icons/svg/svg/bathroom/baseline.svg'), {className: 'material'}],
["bathtub", require('@material-icons/svg/svg/bathtub/baseline.svg'), {className: 'material'}],
["battery_20", require('@material-icons/svg/svg/battery_20/baseline.svg'), {className: 'material'}],
["battery_30", require('@material-icons/svg/svg/battery_30/baseline.svg'), {className: 'material'}],
["battery_50", require('@material-icons/svg/svg/battery_50/baseline.svg'), {className: 'material'}],
["battery_60", require('@material-icons/svg/svg/battery_60/baseline.svg'), {className: 'material'}],
["battery_80", require('@material-icons/svg/svg/battery_80/baseline.svg'), {className: 'material'}],
["battery_90", require('@material-icons/svg/svg/battery_90/baseline.svg'), {className: 'material'}],
["battery_alert", require('@material-icons/svg/svg/battery_alert/baseline.svg'), {className: 'material'}],
["battery_charging_20", require('@material-icons/svg/svg/battery_charging_20/baseline.svg'), {className: 'material'}],
["battery_charging_30", require('@material-icons/svg/svg/battery_charging_30/baseline.svg'), {className: 'material'}],
["battery_charging_50", require('@material-icons/svg/svg/battery_charging_50/baseline.svg'), {className: 'material'}],
["battery_charging_60", require('@material-icons/svg/svg/battery_charging_60/baseline.svg'), {className: 'material'}],
["battery_charging_80", require('@material-icons/svg/svg/battery_charging_80/baseline.svg'), {className: 'material'}],
["battery_charging_90", require('@material-icons/svg/svg/battery_charging_90/baseline.svg'), {className: 'material'}],
["battery_charging_full", require('@material-icons/svg/svg/battery_charging_full/baseline.svg'), {className: 'material'}],
["battery_full", require('@material-icons/svg/svg/battery_full/baseline.svg'), {className: 'material'}],
["battery_saver", require('@material-icons/svg/svg/battery_saver/baseline.svg'), {className: 'material'}],
["battery_std", require('@material-icons/svg/svg/battery_std/baseline.svg'), {className: 'material'}],
["battery_unknown", require('@material-icons/svg/svg/battery_unknown/baseline.svg'), {className: 'material'}],
["beach_access", require('@material-icons/svg/svg/beach_access/baseline.svg'), {className: 'material'}],
["bed", require('@material-icons/svg/svg/bed/baseline.svg'), {className: 'material'}],
["bedroom_baby", require('@material-icons/svg/svg/bedroom_baby/baseline.svg'), {className: 'material'}],
["bedroom_child", require('@material-icons/svg/svg/bedroom_child/baseline.svg'), {className: 'material'}],
["bedroom_parent", require('@material-icons/svg/svg/bedroom_parent/baseline.svg'), {className: 'material'}],
["bedtime", require('@material-icons/svg/svg/bedtime/baseline.svg'), {className: 'material'}],
["bedtime_off", require('@material-icons/svg/svg/bedtime_off/baseline.svg'), {className: 'material'}],
["beenhere", require('@material-icons/svg/svg/beenhere/baseline.svg'), {className: 'material'}],
["bento", require('@material-icons/svg/svg/bento/baseline.svg'), {className: 'material'}],
["bike_scooter", require('@material-icons/svg/svg/bike_scooter/baseline.svg'), {className: 'material'}],
["biotech", require('@material-icons/svg/svg/biotech/baseline.svg'), {className: 'material'}],
["blender", require('@material-icons/svg/svg/blender/baseline.svg'), {className: 'material'}],
["block", require('@material-icons/svg/svg/block/baseline.svg'), {className: 'material'}],
["bloodtype", require('@material-icons/svg/svg/bloodtype/baseline.svg'), {className: 'material'}],
["bluetooth", require('@material-icons/svg/svg/bluetooth/baseline.svg'), {className: 'material'}],
["bluetooth_audio", require('@material-icons/svg/svg/bluetooth_audio/baseline.svg'), {className: 'material'}],
["bluetooth_connected", require('@material-icons/svg/svg/bluetooth_connected/baseline.svg'), {className: 'material'}],
["bluetooth_disabled", require('@material-icons/svg/svg/bluetooth_disabled/baseline.svg'), {className: 'material'}],
["bluetooth_drive", require('@material-icons/svg/svg/bluetooth_drive/baseline.svg'), {className: 'material'}],
["bluetooth_searching", require('@material-icons/svg/svg/bluetooth_searching/baseline.svg'), {className: 'material'}],
["blur_circular", require('@material-icons/svg/svg/blur_circular/baseline.svg'), {className: 'material'}],
["blur_linear", require('@material-icons/svg/svg/blur_linear/baseline.svg'), {className: 'material'}],
["blur_off", require('@material-icons/svg/svg/blur_off/baseline.svg'), {className: 'material'}],
["blur_on", require('@material-icons/svg/svg/blur_on/baseline.svg'), {className: 'material'}],
["bolt", require('@material-icons/svg/svg/bolt/baseline.svg'), {className: 'material'}],
["book", require('@material-icons/svg/svg/book/baseline.svg'), {className: 'material'}],
["book_online", require('@material-icons/svg/svg/book_online/baseline.svg'), {className: 'material'}],
["bookmark", require('@material-icons/svg/svg/bookmark/baseline.svg'), {className: 'material'}],
["bookmark_add", require('@material-icons/svg/svg/bookmark_add/baseline.svg'), {className: 'material'}],
["bookmark_added", require('@material-icons/svg/svg/bookmark_added/baseline.svg'), {className: 'material'}],
["bookmark_border", require('@material-icons/svg/svg/bookmark_border/baseline.svg'), {className: 'material'}],
["bookmark_remove", require('@material-icons/svg/svg/bookmark_remove/baseline.svg'), {className: 'material'}],
["bookmarks", require('@material-icons/svg/svg/bookmarks/baseline.svg'), {className: 'material'}],
["border_all", require('@material-icons/svg/svg/border_all/baseline.svg'), {className: 'material'}],
["border_bottom", require('@material-icons/svg/svg/border_bottom/baseline.svg'), {className: 'material'}],
["border_clear", require('@material-icons/svg/svg/border_clear/baseline.svg'), {className: 'material'}],
["border_color", require('@material-icons/svg/svg/border_color/baseline.svg'), {className: 'material'}],
["border_horizontal", require('@material-icons/svg/svg/border_horizontal/baseline.svg'), {className: 'material'}],
["border_inner", require('@material-icons/svg/svg/border_inner/baseline.svg'), {className: 'material'}],
["border_left", require('@material-icons/svg/svg/border_left/baseline.svg'), {className: 'material'}],
["border_outer", require('@material-icons/svg/svg/border_outer/baseline.svg'), {className: 'material'}],
["border_right", require('@material-icons/svg/svg/border_right/baseline.svg'), {className: 'material'}],
["border_style", require('@material-icons/svg/svg/border_style/baseline.svg'), {className: 'material'}],
["border_top", require('@material-icons/svg/svg/border_top/baseline.svg'), {className: 'material'}],
["border_vertical", require('@material-icons/svg/svg/border_vertical/baseline.svg'), {className: 'material'}],
["boy", require('@material-icons/svg/svg/boy/baseline.svg'), {className: 'material'}],
["branding_watermark", require('@material-icons/svg/svg/branding_watermark/baseline.svg'), {className: 'material'}],
["breakfast_dining", require('@material-icons/svg/svg/breakfast_dining/baseline.svg'), {className: 'material'}],
["brightness_1", require('@material-icons/svg/svg/brightness_1/baseline.svg'), {className: 'material'}],
["brightness_2", require('@material-icons/svg/svg/brightness_2/baseline.svg'), {className: 'material'}],
["brightness_3", require('@material-icons/svg/svg/brightness_3/baseline.svg'), {className: 'material'}],
["brightness_4", require('@material-icons/svg/svg/brightness_4/baseline.svg'), {className: 'material'}],
["brightness_5", require('@material-icons/svg/svg/brightness_5/baseline.svg'), {className: 'material'}],
["brightness_6", require('@material-icons/svg/svg/brightness_6/baseline.svg'), {className: 'material'}],
["brightness_7", require('@material-icons/svg/svg/brightness_7/baseline.svg'), {className: 'material'}],
["brightness_auto", require('@material-icons/svg/svg/brightness_auto/baseline.svg'), {className: 'material'}],
["brightness_high", require('@material-icons/svg/svg/brightness_high/baseline.svg'), {className: 'material'}],
["brightness_low", require('@material-icons/svg/svg/brightness_low/baseline.svg'), {className: 'material'}],
["brightness_medium", require('@material-icons/svg/svg/brightness_medium/baseline.svg'), {className: 'material'}],
["broken_image", require('@material-icons/svg/svg/broken_image/baseline.svg'), {className: 'material'}],
["browse_gallery", require('@material-icons/svg/svg/browse_gallery/baseline.svg'), {className: 'material'}],
["browser_not_supported", require('@material-icons/svg/svg/browser_not_supported/baseline.svg'), {className: 'material'}],
["browser_updated", require('@material-icons/svg/svg/browser_updated/baseline.svg'), {className: 'material'}],
["brunch_dining", require('@material-icons/svg/svg/brunch_dining/baseline.svg'), {className: 'material'}],
["brush", require('@material-icons/svg/svg/brush/baseline.svg'), {className: 'material'}],
["bubble_chart", require('@material-icons/svg/svg/bubble_chart/baseline.svg'), {className: 'material'}],
["bug_report", require('@material-icons/svg/svg/bug_report/baseline.svg'), {className: 'material'}],
["build", require('@material-icons/svg/svg/build/baseline.svg'), {className: 'material'}],
["build_circle", require('@material-icons/svg/svg/build_circle/baseline.svg'), {className: 'material'}],
["bungalow", require('@material-icons/svg/svg/bungalow/baseline.svg'), {className: 'material'}],
["burst_mode", require('@material-icons/svg/svg/burst_mode/baseline.svg'), {className: 'material'}],
["bus_alert", require('@material-icons/svg/svg/bus_alert/baseline.svg'), {className: 'material'}],
["business", require('@material-icons/svg/svg/business/baseline.svg'), {className: 'material'}],
["business_center", require('@material-icons/svg/svg/business_center/baseline.svg'), {className: 'material'}],
["cabin", require('@material-icons/svg/svg/cabin/baseline.svg'), {className: 'material'}],
["cable", require('@material-icons/svg/svg/cable/baseline.svg'), {className: 'material'}],
["cached", require('@material-icons/svg/svg/cached/baseline.svg'), {className: 'material'}],
["cake", require('@material-icons/svg/svg/cake/baseline.svg'), {className: 'material'}],
["calculate", require('@material-icons/svg/svg/calculate/baseline.svg'), {className: 'material'}],
["calendar_month", require('@material-icons/svg/svg/calendar_month/baseline.svg'), {className: 'material'}],
["calendar_today", require('@material-icons/svg/svg/calendar_today/baseline.svg'), {className: 'material'}],
["calendar_view_day", require('@material-icons/svg/svg/calendar_view_day/baseline.svg'), {className: 'material'}],
["calendar_view_month", require('@material-icons/svg/svg/calendar_view_month/baseline.svg'), {className: 'material'}],
["calendar_view_week", require('@material-icons/svg/svg/calendar_view_week/baseline.svg'), {className: 'material'}],
["call", require('@material-icons/svg/svg/call/baseline.svg'), {className: 'material'}],
["call_end", require('@material-icons/svg/svg/call_end/baseline.svg'), {className: 'material'}],
["call_made", require('@material-icons/svg/svg/call_made/baseline.svg'), {className: 'material'}],
["call_merge", require('@material-icons/svg/svg/call_merge/baseline.svg'), {className: 'material'}],
["call_missed", require('@material-icons/svg/svg/call_missed/baseline.svg'), {className: 'material'}],
["call_missed_outgoing", require('@material-icons/svg/svg/call_missed_outgoing/baseline.svg'), {className: 'material'}],
["call_received", require('@material-icons/svg/svg/call_received/baseline.svg'), {className: 'material'}],
["call_split", require('@material-icons/svg/svg/call_split/baseline.svg'), {className: 'material'}],
["call_to_action", require('@material-icons/svg/svg/call_to_action/baseline.svg'), {className: 'material'}],
["camera", require('@material-icons/svg/svg/camera/baseline.svg'), {className: 'material'}],
["camera_alt", require('@material-icons/svg/svg/camera_alt/baseline.svg'), {className: 'material'}],
["camera_enhance", require('@material-icons/svg/svg/camera_enhance/baseline.svg'), {className: 'material'}],
["camera_front", require('@material-icons/svg/svg/camera_front/baseline.svg'), {className: 'material'}],
["camera_indoor", require('@material-icons/svg/svg/camera_indoor/baseline.svg'), {className: 'material'}],
["camera_outdoor", require('@material-icons/svg/svg/camera_outdoor/baseline.svg'), {className: 'material'}],
["camera_rear", require('@material-icons/svg/svg/camera_rear/baseline.svg'), {className: 'material'}],
["camera_roll", require('@material-icons/svg/svg/camera_roll/baseline.svg'), {className: 'material'}],
["cameraswitch", require('@material-icons/svg/svg/cameraswitch/baseline.svg'), {className: 'material'}],
["campaign", require('@material-icons/svg/svg/campaign/baseline.svg'), {className: 'material'}],
["cancel", require('@material-icons/svg/svg/cancel/baseline.svg'), {className: 'material'}],
["cancel_presentation", require('@material-icons/svg/svg/cancel_presentation/baseline.svg'), {className: 'material'}],
["cancel_schedule_send", require('@material-icons/svg/svg/cancel_schedule_send/baseline.svg'), {className: 'material'}],
["candlestick_chart", require('@material-icons/svg/svg/candlestick_chart/baseline.svg'), {className: 'material'}],
["car_rental", require('@material-icons/svg/svg/car_rental/baseline.svg'), {className: 'material'}],
["car_repair", require('@material-icons/svg/svg/car_repair/baseline.svg'), {className: 'material'}],
["card_giftcard", require('@material-icons/svg/svg/card_giftcard/baseline.svg'), {className: 'material'}],
["card_membership", require('@material-icons/svg/svg/card_membership/baseline.svg'), {className: 'material'}],
["card_travel", require('@material-icons/svg/svg/card_travel/baseline.svg'), {className: 'material'}],
["carpenter", require('@material-icons/svg/svg/carpenter/baseline.svg'), {className: 'material'}],
["cases", require('@material-icons/svg/svg/cases/baseline.svg'), {className: 'material'}],
["casino", require('@material-icons/svg/svg/casino/baseline.svg'), {className: 'material'}],
["cast", require('@material-icons/svg/svg/cast/baseline.svg'), {className: 'material'}],
["cast_connected", require('@material-icons/svg/svg/cast_connected/baseline.svg'), {className: 'material'}],
["cast_for_education", require('@material-icons/svg/svg/cast_for_education/baseline.svg'), {className: 'material'}],
["castle", require('@material-icons/svg/svg/castle/baseline.svg'), {className: 'material'}],
["catching_pokemon", require('@material-icons/svg/svg/catching_pokemon/baseline.svg'), {className: 'material'}],
["category", require('@material-icons/svg/svg/category/baseline.svg'), {className: 'material'}],
["celebration", require('@material-icons/svg/svg/celebration/baseline.svg'), {className: 'material'}],
["cell_tower", require('@material-icons/svg/svg/cell_tower/baseline.svg'), {className: 'material'}],
["cell_wifi", require('@material-icons/svg/svg/cell_wifi/baseline.svg'), {className: 'material'}],
["center_focus_strong", require('@material-icons/svg/svg/center_focus_strong/baseline.svg'), {className: 'material'}],
["center_focus_weak", require('@material-icons/svg/svg/center_focus_weak/baseline.svg'), {className: 'material'}],
["chair", require('@material-icons/svg/svg/chair/baseline.svg'), {className: 'material'}],
["chair_alt", require('@material-icons/svg/svg/chair_alt/baseline.svg'), {className: 'material'}],
["chalet", require('@material-icons/svg/svg/chalet/baseline.svg'), {className: 'material'}],
["change_circle", require('@material-icons/svg/svg/change_circle/baseline.svg'), {className: 'material'}],
["change_history", require('@material-icons/svg/svg/change_history/baseline.svg'), {className: 'material'}],
["charging_station", require('@material-icons/svg/svg/charging_station/baseline.svg'), {className: 'material'}],
["chat", require('@material-icons/svg/svg/chat/baseline.svg'), {className: 'material'}],
["chat_bubble", require('@material-icons/svg/svg/chat_bubble/baseline.svg'), {className: 'material'}],
["chat_bubble_outline", require('@material-icons/svg/svg/chat_bubble_outline/baseline.svg'), {className: 'material'}],
["check", require('@material-icons/svg/svg/check/baseline.svg'), {className: 'material'}],
["check_box", require('@material-icons/svg/svg/check_box/baseline.svg'), {className: 'material'}],
["check_box_outline_blank", require('@material-icons/svg/svg/check_box_outline_blank/baseline.svg'), {className: 'material'}],
["check_circle", require('@material-icons/svg/svg/check_circle/baseline.svg'), {className: 'material'}],
["check_circle_outline", require('@material-icons/svg/svg/check_circle_outline/baseline.svg'), {className: 'material'}],
["checklist", require('@material-icons/svg/svg/checklist/baseline.svg'), {className: 'material'}],
["checklist_rtl", require('@material-icons/svg/svg/checklist_rtl/baseline.svg'), {className: 'material'}],
["checkroom", require('@material-icons/svg/svg/checkroom/baseline.svg'), {className: 'material'}],
["chevron_left", require('@material-icons/svg/svg/chevron_left/baseline.svg'), {className: 'material'}],
["chevron_right", require('@material-icons/svg/svg/chevron_right/baseline.svg'), {className: 'material'}],
["child_care", require('@material-icons/svg/svg/child_care/baseline.svg'), {className: 'material'}],
["child_friendly", require('@material-icons/svg/svg/child_friendly/baseline.svg'), {className: 'material'}],
["chrome_reader_mode", require('@material-icons/svg/svg/chrome_reader_mode/baseline.svg'), {className: 'material'}],
["church", require('@material-icons/svg/svg/church/baseline.svg'), {className: 'material'}],
["circle", require('@material-icons/svg/svg/circle/baseline.svg'), {className: 'material'}],
["circle_notifications", require('@material-icons/svg/svg/circle_notifications/baseline.svg'), {className: 'material'}],
["class", require('@material-icons/svg/svg/class/baseline.svg'), {className: 'material'}],
["clean_hands", require('@material-icons/svg/svg/clean_hands/baseline.svg'), {className: 'material'}],
["cleaning_services", require('@material-icons/svg/svg/cleaning_services/baseline.svg'), {className: 'material'}],
["clear", require('@material-icons/svg/svg/clear/baseline.svg'), {className: 'material'}],
["clear_all", require('@material-icons/svg/svg/clear_all/baseline.svg'), {className: 'material'}],
["close", require('@material-icons/svg/svg/close/baseline.svg'), {className: 'material'}],
["close_fullscreen", require('@material-icons/svg/svg/close_fullscreen/baseline.svg'), {className: 'material'}],
["closed_caption", require('@material-icons/svg/svg/closed_caption/baseline.svg'), {className: 'material'}],
["closed_caption_disabled", require('@material-icons/svg/svg/closed_caption_disabled/baseline.svg'), {className: 'material'}],
["closed_caption_off", require('@material-icons/svg/svg/closed_caption_off/baseline.svg'), {className: 'material'}],
["cloud", require('@material-icons/svg/svg/cloud/baseline.svg'), {className: 'material'}],
["cloud_circle", require('@material-icons/svg/svg/cloud_circle/baseline.svg'), {className: 'material'}],
["cloud_done", require('@material-icons/svg/svg/cloud_done/baseline.svg'), {className: 'material'}],
["cloud_download", require('@material-icons/svg/svg/cloud_download/baseline.svg'), {className: 'material'}],
["cloud_off", require('@material-icons/svg/svg/cloud_off/baseline.svg'), {className: 'material'}],
["cloud_queue", require('@material-icons/svg/svg/cloud_queue/baseline.svg'), {className: 'material'}],
["cloud_sync", require('@material-icons/svg/svg/cloud_sync/baseline.svg'), {className: 'material'}],
["cloud_upload", require('@material-icons/svg/svg/cloud_upload/baseline.svg'), {className: 'material'}],
["co_present", require('@material-icons/svg/svg/co_present/baseline.svg'), {className: 'material'}],
["co2", require('@material-icons/svg/svg/co2/baseline.svg'), {className: 'material'}],
["code", require('@material-icons/svg/svg/code/baseline.svg'), {className: 'material'}],
["code_off", require('@material-icons/svg/svg/code_off/baseline.svg'), {className: 'material'}],
["coffee", require('@material-icons/svg/svg/coffee/baseline.svg'), {className: 'material'}],
["coffee_maker", require('@material-icons/svg/svg/coffee_maker/baseline.svg'), {className: 'material'}],
["collections", require('@material-icons/svg/svg/collections/baseline.svg'), {className: 'material'}],
["collections_bookmark", require('@material-icons/svg/svg/collections_bookmark/baseline.svg'), {className: 'material'}],
["color_lens", require('@material-icons/svg/svg/color_lens/baseline.svg'), {className: 'material'}],
["colorize", require('@material-icons/svg/svg/colorize/baseline.svg'), {className: 'material'}],
["comment", require('@material-icons/svg/svg/comment/baseline.svg'), {className: 'material'}],
["comment_bank", require('@material-icons/svg/svg/comment_bank/baseline.svg'), {className: 'material'}],
["comments_disabled", require('@material-icons/svg/svg/comments_disabled/baseline.svg'), {className: 'material'}],
["commit", require('@material-icons/svg/svg/commit/baseline.svg'), {className: 'material'}],
["commute", require('@material-icons/svg/svg/commute/baseline.svg'), {className: 'material'}],
["compare", require('@material-icons/svg/svg/compare/baseline.svg'), {className: 'material'}],
["compare_arrows", require('@material-icons/svg/svg/compare_arrows/baseline.svg'), {className: 'material'}],
["compass_calibration", require('@material-icons/svg/svg/compass_calibration/baseline.svg'), {className: 'material'}],
["compost", require('@material-icons/svg/svg/compost/baseline.svg'), {className: 'material'}],
["compress", require('@material-icons/svg/svg/compress/baseline.svg'), {className: 'material'}],
["computer", require('@material-icons/svg/svg/computer/baseline.svg'), {className: 'material'}],
["confirmation_number", require('@material-icons/svg/svg/confirmation_number/baseline.svg'), {className: 'material'}],
["connect_without_contact", require('@material-icons/svg/svg/connect_without_contact/baseline.svg'), {className: 'material'}],
["connected_tv", require('@material-icons/svg/svg/connected_tv/baseline.svg'), {className: 'material'}],
["connecting_airports", require('@material-icons/svg/svg/connecting_airports/baseline.svg'), {className: 'material'}],
["construction", require('@material-icons/svg/svg/construction/baseline.svg'), {className: 'material'}],
["contact_mail", require('@material-icons/svg/svg/contact_mail/baseline.svg'), {className: 'material'}],
["contact_page", require('@material-icons/svg/svg/contact_page/baseline.svg'), {className: 'material'}],
["contact_phone", require('@material-icons/svg/svg/contact_phone/baseline.svg'), {className: 'material'}],
["contact_support", require('@material-icons/svg/svg/contact_support/baseline.svg'), {className: 'material'}],
["contactless", require('@material-icons/svg/svg/contactless/baseline.svg'), {className: 'material'}],
["contacts", require('@material-icons/svg/svg/contacts/baseline.svg'), {className: 'material'}],
["content_copy", require('@material-icons/svg/svg/content_copy/baseline.svg'), {className: 'material'}],
["content_cut", require('@material-icons/svg/svg/content_cut/baseline.svg'), {className: 'material'}],
["content_paste", require('@material-icons/svg/svg/content_paste/baseline.svg'), {className: 'material'}],
["content_paste_go", require('@material-icons/svg/svg/content_paste_go/baseline.svg'), {className: 'material'}],
["content_paste_off", require('@material-icons/svg/svg/content_paste_off/baseline.svg'), {className: 'material'}],
["content_paste_search", require('@material-icons/svg/svg/content_paste_search/baseline.svg'), {className: 'material'}],
["contrast", require('@material-icons/svg/svg/contrast/baseline.svg'), {className: 'material'}],
["control_camera", require('@material-icons/svg/svg/control_camera/baseline.svg'), {className: 'material'}],
["control_point", require('@material-icons/svg/svg/control_point/baseline.svg'), {className: 'material'}],
["control_point_duplicate", require('@material-icons/svg/svg/control_point_duplicate/baseline.svg'), {className: 'material'}],
["cookie", require('@material-icons/svg/svg/cookie/baseline.svg'), {className: 'material'}],
["copy_all", require('@material-icons/svg/svg/copy_all/baseline.svg'), {className: 'material'}],
["copyright", require('@material-icons/svg/svg/copyright/baseline.svg'), {className: 'material'}],
["coronavirus", require('@material-icons/svg/svg/coronavirus/baseline.svg'), {className: 'material'}],
["corporate_fare", require('@material-icons/svg/svg/corporate_fare/baseline.svg'), {className: 'material'}],
["cottage", require('@material-icons/svg/svg/cottage/baseline.svg'), {className: 'material'}],
["countertops", require('@material-icons/svg/svg/countertops/baseline.svg'), {className: 'material'}],
["create", require('@material-icons/svg/svg/create/baseline.svg'), {className: 'material'}],
["create_new_folder", require('@material-icons/svg/svg/create_new_folder/baseline.svg'), {className: 'material'}],
["credit_card", require('@material-icons/svg/svg/credit_card/baseline.svg'), {className: 'material'}],
["credit_card_off", require('@material-icons/svg/svg/credit_card_off/baseline.svg'), {className: 'material'}],
["credit_score", require('@material-icons/svg/svg/credit_score/baseline.svg'), {className: 'material'}],
["crib", require('@material-icons/svg/svg/crib/baseline.svg'), {className: 'material'}],
["crop", require('@material-icons/svg/svg/crop/baseline.svg'), {className: 'material'}],
["crop_16_9", require('@material-icons/svg/svg/crop_16_9/baseline.svg'), {className: 'material'}],
["crop_3_2", require('@material-icons/svg/svg/crop_3_2/baseline.svg'), {className: 'material'}],
["crop_5_4", require('@material-icons/svg/svg/crop_5_4/baseline.svg'), {className: 'material'}],
["crop_7_5", require('@material-icons/svg/svg/crop_7_5/baseline.svg'), {className: 'material'}],
["crop_din", require('@material-icons/svg/svg/crop_din/baseline.svg'), {className: 'material'}],
["crop_free", require('@material-icons/svg/svg/crop_free/baseline.svg'), {className: 'material'}],
["crop_landscape", require('@material-icons/svg/svg/crop_landscape/baseline.svg'), {className: 'material'}],
["crop_original", require('@material-icons/svg/svg/crop_original/baseline.svg'), {className: 'material'}],
["crop_portrait", require('@material-icons/svg/svg/crop_portrait/baseline.svg'), {className: 'material'}],
["crop_rotate", require('@material-icons/svg/svg/crop_rotate/baseline.svg'), {className: 'material'}],
["crop_square", require('@material-icons/svg/svg/crop_square/baseline.svg'), {className: 'material'}],
["cruelty_free", require('@material-icons/svg/svg/cruelty_free/baseline.svg'), {className: 'material'}],
["css", require('@material-icons/svg/svg/css/baseline.svg'), {className: 'material'}],
["currency_bitcoin", require('@material-icons/svg/svg/currency_bitcoin/baseline.svg'), {className: 'material'}],
["currency_exchange", require('@material-icons/svg/svg/currency_exchange/baseline.svg'), {className: 'material'}],
["currency_franc", require('@material-icons/svg/svg/currency_franc/baseline.svg'), {className: 'material'}],
["currency_lira", require('@material-icons/svg/svg/currency_lira/baseline.svg'), {className: 'material'}],
["currency_pound", require('@material-icons/svg/svg/currency_pound/baseline.svg'), {className: 'material'}],
["currency_ruble", require('@material-icons/svg/svg/currency_ruble/baseline.svg'), {className: 'material'}],
["currency_rupee", require('@material-icons/svg/svg/currency_rupee/baseline.svg'), {className: 'material'}],
["currency_yen", require('@material-icons/svg/svg/currency_yen/baseline.svg'), {className: 'material'}],
["currency_yuan", require('@material-icons/svg/svg/currency_yuan/baseline.svg'), {className: 'material'}],
["dangerous", require('@material-icons/svg/svg/dangerous/baseline.svg'), {className: 'material'}],
["dark_mode", require('@material-icons/svg/svg/dark_mode/baseline.svg'), {className: 'material'}],
["dashboard", require('@material-icons/svg/svg/dashboard/baseline.svg'), {className: 'material'}],
["dashboard_customize", require('@material-icons/svg/svg/dashboard_customize/baseline.svg'), {className: 'material'}],
["data_array", require('@material-icons/svg/svg/data_array/baseline.svg'), {className: 'material'}],
["data_exploration", require('@material-icons/svg/svg/data_exploration/baseline.svg'), {className: 'material'}],
["data_object", require('@material-icons/svg/svg/data_object/baseline.svg'), {className: 'material'}],
["data_saver_off", require('@material-icons/svg/svg/data_saver_off/baseline.svg'), {className: 'material'}],
["data_saver_on", require('@material-icons/svg/svg/data_saver_on/baseline.svg'), {className: 'material'}],
["data_thresholding", require('@material-icons/svg/svg/data_thresholding/baseline.svg'), {className: 'material'}],
["data_usage", require('@material-icons/svg/svg/data_usage/baseline.svg'), {className: 'material'}],
["date_range", require('@material-icons/svg/svg/date_range/baseline.svg'), {className: 'material'}],
["deblur", require('@material-icons/svg/svg/deblur/baseline.svg'), {className: 'material'}],
["deck", require('@material-icons/svg/svg/deck/baseline.svg'), {className: 'material'}],
["dehaze", require('@material-icons/svg/svg/dehaze/baseline.svg'), {className: 'material'}],
["delete", require('@material-icons/svg/svg/delete/baseline.svg'), {className: 'material'}],
["delete_forever", require('@material-icons/svg/svg/delete_forever/baseline.svg'), {className: 'material'}],
["delete_outline", require('@material-icons/svg/svg/delete_outline/baseline.svg'), {className: 'material'}],
["delete_sweep", require('@material-icons/svg/svg/delete_sweep/baseline.svg'), {className: 'material'}],
["delivery_dining", require('@material-icons/svg/svg/delivery_dining/baseline.svg'), {className: 'material'}],
["density_large", require('@material-icons/svg/svg/density_large/baseline.svg'), {className: 'material'}],
["density_medium", require('@material-icons/svg/svg/density_medium/baseline.svg'), {className: 'material'}],
["density_small", require('@material-icons/svg/svg/density_small/baseline.svg'), {className: 'material'}],
["departure_board", require('@material-icons/svg/svg/departure_board/baseline.svg'), {className: 'material'}],
["description", require('@material-icons/svg/svg/description/baseline.svg'), {className: 'material'}],
["deselect", require('@material-icons/svg/svg/deselect/baseline.svg'), {className: 'material'}],
["design_services", require('@material-icons/svg/svg/design_services/baseline.svg'), {className: 'material'}],
["desktop_access_disabled", require('@material-icons/svg/svg/desktop_access_disabled/baseline.svg'), {className: 'material'}],
["desktop_mac", require('@material-icons/svg/svg/desktop_mac/baseline.svg'), {className: 'material'}],
["desktop_windows", require('@material-icons/svg/svg/desktop_windows/baseline.svg'), {className: 'material'}],
["details", require('@material-icons/svg/svg/details/baseline.svg'), {className: 'material'}],
["developer_board", require('@material-icons/svg/svg/developer_board/baseline.svg'), {className: 'material'}],
["developer_board_off", require('@material-icons/svg/svg/developer_board_off/baseline.svg'), {className: 'material'}],
["developer_mode", require('@material-icons/svg/svg/developer_mode/baseline.svg'), {className: 'material'}],
["device_hub", require('@material-icons/svg/svg/device_hub/baseline.svg'), {className: 'material'}],
["device_thermostat", require('@material-icons/svg/svg/device_thermostat/baseline.svg'), {className: 'material'}],
["device_unknown", require('@material-icons/svg/svg/device_unknown/baseline.svg'), {className: 'material'}],
["devices", require('@material-icons/svg/svg/devices/baseline.svg'), {className: 'material'}],
["devices_other", require('@material-icons/svg/svg/devices_other/baseline.svg'), {className: 'material'}],
["dialer_sip", require('@material-icons/svg/svg/dialer_sip/baseline.svg'), {className: 'material'}],
["dialpad", require('@material-icons/svg/svg/dialpad/baseline.svg'), {className: 'material'}],
["diamond", require('@material-icons/svg/svg/diamond/baseline.svg'), {className: 'material'}],
["difference", require('@material-icons/svg/svg/difference/baseline.svg'), {className: 'material'}],
["dining", require('@material-icons/svg/svg/dining/baseline.svg'), {className: 'material'}],
["dinner_dining", require('@material-icons/svg/svg/dinner_dining/baseline.svg'), {className: 'material'}],
["directions", require('@material-icons/svg/svg/directions/baseline.svg'), {className: 'material'}],
["directions_bike", require('@material-icons/svg/svg/directions_bike/baseline.svg'), {className: 'material'}],
["directions_boat", require('@material-icons/svg/svg/directions_boat/baseline.svg'), {className: 'material'}],
["directions_boat_filled", require('@material-icons/svg/svg/directions_boat_filled/baseline.svg'), {className: 'material'}],
["directions_bus", require('@material-icons/svg/svg/directions_bus/baseline.svg'), {className: 'material'}],
["directions_bus_filled", require('@material-icons/svg/svg/directions_bus_filled/baseline.svg'), {className: 'material'}],
["directions_car", require('@material-icons/svg/svg/directions_car/baseline.svg'), {className: 'material'}],
["directions_car_filled", require('@material-icons/svg/svg/directions_car_filled/baseline.svg'), {className: 'material'}],
["directions_off", require('@material-icons/svg/svg/directions_off/baseline.svg'), {className: 'material'}],
["directions_railway", require('@material-icons/svg/svg/directions_railway/baseline.svg'), {className: 'material'}],
["directions_railway_filled", require('@material-icons/svg/svg/directions_railway_filled/baseline.svg'), {className: 'material'}],
["directions_run", require('@material-icons/svg/svg/directions_run/baseline.svg'), {className: 'material'}],
["directions_subway", require('@material-icons/svg/svg/directions_subway/baseline.svg'), {className: 'material'}],
["directions_subway_filled", require('@material-icons/svg/svg/directions_subway_filled/baseline.svg'), {className: 'material'}],
["directions_transit", require('@material-icons/svg/svg/directions_transit/baseline.svg'), {className: 'material'}],
["directions_transit_filled", require('@material-icons/svg/svg/directions_transit_filled/baseline.svg'), {className: 'material'}],
["directions_walk", require('@material-icons/svg/svg/directions_walk/baseline.svg'), {className: 'material'}],
["dirty_lens", require('@material-icons/svg/svg/dirty_lens/baseline.svg'), {className: 'material'}],
["disabled_by_default", require('@material-icons/svg/svg/disabled_by_default/baseline.svg'), {className: 'material'}],
["disabled_visible", require('@material-icons/svg/svg/disabled_visible/baseline.svg'), {className: 'material'}],
["disc_full", require('@material-icons/svg/svg/disc_full/baseline.svg'), {className: 'material'}],
["discord", require('@material-icons/svg/svg/discord/baseline.svg'), {className: 'material'}],
["discount", require('@material-icons/svg/svg/discount/baseline.svg'), {className: 'material'}],
["display_settings", require('@material-icons/svg/svg/display_settings/baseline.svg'), {className: 'material'}],
["dns", require('@material-icons/svg/svg/dns/baseline.svg'), {className: 'material'}],
["do_disturb", require('@material-icons/svg/svg/do_disturb/baseline.svg'), {className: 'material'}],
["do_disturb_alt", require('@material-icons/svg/svg/do_disturb_alt/baseline.svg'), {className: 'material'}],
["do_disturb_off", require('@material-icons/svg/svg/do_disturb_off/baseline.svg'), {className: 'material'}],
["do_disturb_on", require('@material-icons/svg/svg/do_disturb_on/baseline.svg'), {className: 'material'}],
["do_not_disturb", require('@material-icons/svg/svg/do_not_disturb/baseline.svg'), {className: 'material'}],
["do_not_disturb_alt", require('@material-icons/svg/svg/do_not_disturb_alt/baseline.svg'), {className: 'material'}],
["do_not_disturb_off", require('@material-icons/svg/svg/do_not_disturb_off/baseline.svg'), {className: 'material'}],
["do_not_disturb_on", require('@material-icons/svg/svg/do_not_disturb_on/baseline.svg'), {className: 'material'}],
["do_not_disturb_on_total_silence", require('@material-icons/svg/svg/do_not_disturb_on_total_silence/baseline.svg'), {className: 'material'}],
["do_not_step", require('@material-icons/svg/svg/do_not_step/baseline.svg'), {className: 'material'}],
["do_not_touch", require('@material-icons/svg/svg/do_not_touch/baseline.svg'), {className: 'material'}],
["dock", require('@material-icons/svg/svg/dock/baseline.svg'), {className: 'material'}],
["document_scanner", require('@material-icons/svg/svg/document_scanner/baseline.svg'), {className: 'material'}],
["domain", require('@material-icons/svg/svg/domain/baseline.svg'), {className: 'material'}],
["domain_add", require('@material-icons/svg/svg/domain_add/baseline.svg'), {className: 'material'}],
["domain_disabled", require('@material-icons/svg/svg/domain_disabled/baseline.svg'), {className: 'material'}],
["domain_verification", require('@material-icons/svg/svg/domain_verification/baseline.svg'), {className: 'material'}],
["done", require('@material-icons/svg/svg/done/baseline.svg'), {className: 'material'}],
["done_all", require('@material-icons/svg/svg/done_all/baseline.svg'), {className: 'material'}],
["done_outline", require('@material-icons/svg/svg/done_outline/baseline.svg'), {className: 'material'}],
["donut_large", require('@material-icons/svg/svg/donut_large/baseline.svg'), {className: 'material'}],
["donut_small", require('@material-icons/svg/svg/donut_small/baseline.svg'), {className: 'material'}],
["door_back", require('@material-icons/svg/svg/door_back/baseline.svg'), {className: 'material'}],
["door_front", require('@material-icons/svg/svg/door_front/baseline.svg'), {className: 'material'}],
["door_sliding", require('@material-icons/svg/svg/door_sliding/baseline.svg'), {className: 'material'}],
["doorbell", require('@material-icons/svg/svg/doorbell/baseline.svg'), {className: 'material'}],
["double_arrow", require('@material-icons/svg/svg/double_arrow/baseline.svg'), {className: 'material'}],
["downhill_skiing", require('@material-icons/svg/svg/downhill_skiing/baseline.svg'), {className: 'material'}],
["download", require('@material-icons/svg/svg/download/baseline.svg'), {className: 'material'}],
["download_done", require('@material-icons/svg/svg/download_done/baseline.svg'), {className: 'material'}],
["download_for_offline", require('@material-icons/svg/svg/download_for_offline/baseline.svg'), {className: 'material'}],
["downloading", require('@material-icons/svg/svg/downloading/baseline.svg'), {className: 'material'}],
["drafts", require('@material-icons/svg/svg/drafts/baseline.svg'), {className: 'material'}],
["drag_handle", require('@material-icons/svg/svg/drag_handle/baseline.svg'), {className: 'material'}],
["drag_indicator", require('@material-icons/svg/svg/drag_indicator/baseline.svg'), {className: 'material'}],
["draw", require('@material-icons/svg/svg/draw/baseline.svg'), {className: 'material'}],
["drive_eta", require('@material-icons/svg/svg/drive_eta/baseline.svg'), {className: 'material'}],
["drive_file_move", require('@material-icons/svg/svg/drive_file_move/baseline.svg'), {className: 'material'}],
["drive_file_move_rtl", require('@material-icons/svg/svg/drive_file_move_rtl/baseline.svg'), {className: 'material'}],
["drive_file_rename_outline", require('@material-icons/svg/svg/drive_file_rename_outline/baseline.svg'), {className: 'material'}],
["drive_folder_upload", require('@material-icons/svg/svg/drive_folder_upload/baseline.svg'), {className: 'material'}],
["dry", require('@material-icons/svg/svg/dry/baseline.svg'), {className: 'material'}],
["dry_cleaning", require('@material-icons/svg/svg/dry_cleaning/baseline.svg'), {className: 'material'}],
["duo", require('@material-icons/svg/svg/duo/baseline.svg'), {className: 'material'}],
["dvr", require('@material-icons/svg/svg/dvr/baseline.svg'), {className: 'material'}],
["dynamic_feed", require('@material-icons/svg/svg/dynamic_feed/baseline.svg'), {className: 'material'}],
["dynamic_form", require('@material-icons/svg/svg/dynamic_form/baseline.svg'), {className: 'material'}],
["e_mobiledata", require('@material-icons/svg/svg/e_mobiledata/baseline.svg'), {className: 'material'}],
["earbuds", require('@material-icons/svg/svg/earbuds/baseline.svg'), {className: 'material'}],
["earbuds_battery", require('@material-icons/svg/svg/earbuds_battery/baseline.svg'), {className: 'material'}],
["east", require('@material-icons/svg/svg/east/baseline.svg'), {className: 'material'}],
["eco", require('@material-icons/svg/svg/eco/baseline.svg'), {className: 'material'}],
["edgesensor_high", require('@material-icons/svg/svg/edgesensor_high/baseline.svg'), {className: 'material'}],
["edgesensor_low", require('@material-icons/svg/svg/edgesensor_low/baseline.svg'), {className: 'material'}],
["edit", require('@material-icons/svg/svg/edit/baseline.svg'), {className: 'material'}],
["edit_attributes", require('@material-icons/svg/svg/edit_attributes/baseline.svg'), {className: 'material'}],
["edit_calendar", require('@material-icons/svg/svg/edit_calendar/baseline.svg'), {className: 'material'}],
["edit_location", require('@material-icons/svg/svg/edit_location/baseline.svg'), {className: 'material'}],
["edit_location_alt", require('@material-icons/svg/svg/edit_location_alt/baseline.svg'), {className: 'material'}],
["edit_note", require('@material-icons/svg/svg/edit_note/baseline.svg'), {className: 'material'}],
["edit_notifications", require('@material-icons/svg/svg/edit_notifications/baseline.svg'), {className: 'material'}],
["edit_off", require('@material-icons/svg/svg/edit_off/baseline.svg'), {className: 'material'}],
["edit_road", require('@material-icons/svg/svg/edit_road/baseline.svg'), {className: 'material'}],
["egg", require('@material-icons/svg/svg/egg/baseline.svg'), {className: 'material'}],
["egg_alt", require('@material-icons/svg/svg/egg_alt/baseline.svg'), {className: 'material'}],
["eject", require('@material-icons/svg/svg/eject/baseline.svg'), {className: 'material'}],
["elderly", require('@material-icons/svg/svg/elderly/baseline.svg'), {className: 'material'}],
["elderly_woman", require('@material-icons/svg/svg/elderly_woman/baseline.svg'), {className: 'material'}],
["electric_bike", require('@material-icons/svg/svg/electric_bike/baseline.svg'), {className: 'material'}],
["electric_car", require('@material-icons/svg/svg/electric_car/baseline.svg'), {className: 'material'}],
["electric_moped", require('@material-icons/svg/svg/electric_moped/baseline.svg'), {className: 'material'}],
["electric_rickshaw", require('@material-icons/svg/svg/electric_rickshaw/baseline.svg'), {className: 'material'}],
["electric_scooter", require('@material-icons/svg/svg/electric_scooter/baseline.svg'), {className: 'material'}],
["electrical_services", require('@material-icons/svg/svg/electrical_services/baseline.svg'), {className: 'material'}],
["elevator", require('@material-icons/svg/svg/elevator/baseline.svg'), {className: 'material'}],
["email", require('@material-icons/svg/svg/email/baseline.svg'), {className: 'material'}],
["emergency", require('@material-icons/svg/svg/emergency/baseline.svg'), {className: 'material'}],
["emoji_emotions", require('@material-icons/svg/svg/emoji_emotions/baseline.svg'), {className: 'material'}],
["emoji_events", require('@material-icons/svg/svg/emoji_events/baseline.svg'), {className: 'material'}],
["emoji_flags", require('@material-icons/svg/svg/emoji_flags/baseline.svg'), {className: 'material'}],
["emoji_food_beverage", require('@material-icons/svg/svg/emoji_food_beverage/baseline.svg'), {className: 'material'}],
["emoji_nature", require('@material-icons/svg/svg/emoji_nature/baseline.svg'), {className: 'material'}],
["emoji_objects", require('@material-icons/svg/svg/emoji_objects/baseline.svg'), {className: 'material'}],
["emoji_people", require('@material-icons/svg/svg/emoji_people/baseline.svg'), {className: 'material'}],
["emoji_symbols", require('@material-icons/svg/svg/emoji_symbols/baseline.svg'), {className: 'material'}],
["emoji_transportation", require('@material-icons/svg/svg/emoji_transportation/baseline.svg'), {className: 'material'}],
["engineering", require('@material-icons/svg/svg/engineering/baseline.svg'), {className: 'material'}],
["enhanced_encryption", require('@material-icons/svg/svg/enhanced_encryption/baseline.svg'), {className: 'material'}],
["equalizer", require('@material-icons/svg/svg/equalizer/baseline.svg'), {className: 'material'}],
["error", require('@material-icons/svg/svg/error/baseline.svg'), {className: 'material'}],
["error_outline", require('@material-icons/svg/svg/error_outline/baseline.svg'), {className: 'material'}],
["escalator", require('@material-icons/svg/svg/escalator/baseline.svg'), {className: 'material'}],
["escalator_warning", require('@material-icons/svg/svg/escalator_warning/baseline.svg'), {className: 'material'}],
["euro", require('@material-icons/svg/svg/euro/baseline.svg'), {className: 'material'}],
["euro_symbol", require('@material-icons/svg/svg/euro_symbol/baseline.svg'), {className: 'material'}],
["ev_station", require('@material-icons/svg/svg/ev_station/baseline.svg'), {className: 'material'}],
["event", require('@material-icons/svg/svg/event/baseline.svg'), {className: 'material'}],
["event_available", require('@material-icons/svg/svg/event_available/baseline.svg'), {className: 'material'}],
["event_busy", require('@material-icons/svg/svg/event_busy/baseline.svg'), {className: 'material'}],
["event_note", require('@material-icons/svg/svg/event_note/baseline.svg'), {className: 'material'}],
["event_repeat", require('@material-icons/svg/svg/event_repeat/baseline.svg'), {className: 'material'}],
["event_seat", require('@material-icons/svg/svg/event_seat/baseline.svg'), {className: 'material'}],
["exit_to_app", require('@material-icons/svg/svg/exit_to_app/baseline.svg'), {className: 'material'}],
["expand", require('@material-icons/svg/svg/expand/baseline.svg'), {className: 'material'}],
["expand_circle_down", require('@material-icons/svg/svg/expand_circle_down/baseline.svg'), {className: 'material'}],
["expand_less", require('@material-icons/svg/svg/expand_less/baseline.svg'), {className: 'material'}],
["expand_more", require('@material-icons/svg/svg/expand_more/baseline.svg'), {className: 'material'}],
["explicit", require('@material-icons/svg/svg/explicit/baseline.svg'), {className: 'material'}],
["explore", require('@material-icons/svg/svg/explore/baseline.svg'), {className: 'material'}],
["explore_off", require('@material-icons/svg/svg/explore_off/baseline.svg'), {className: 'material'}],
["exposure", require('@material-icons/svg/svg/exposure/baseline.svg'), {className: 'material'}],
["exposure_neg_1", require('@material-icons/svg/svg/exposure_neg_1/baseline.svg'), {className: 'material'}],
["exposure_neg_2", require('@material-icons/svg/svg/exposure_neg_2/baseline.svg'), {className: 'material'}],
["exposure_plus_1", require('@material-icons/svg/svg/exposure_plus_1/baseline.svg'), {className: 'material'}],
["exposure_plus_2", require('@material-icons/svg/svg/exposure_plus_2/baseline.svg'), {className: 'material'}],
["exposure_zero", require('@material-icons/svg/svg/exposure_zero/baseline.svg'), {className: 'material'}],
["extension", require('@material-icons/svg/svg/extension/baseline.svg'), {className: 'material'}],
["extension_off", require('@material-icons/svg/svg/extension_off/baseline.svg'), {className: 'material'}],
["face", require('@material-icons/svg/svg/face/baseline.svg'), {className: 'material'}],
["face_retouching_natural", require('@material-icons/svg/svg/face_retouching_natural/baseline.svg'), {className: 'material'}],
["face_retouching_off", require('@material-icons/svg/svg/face_retouching_off/baseline.svg'), {className: 'material'}],
["facebook", require('@material-icons/svg/svg/facebook/baseline.svg'), {className: 'material'}],
["fact_check", require('@material-icons/svg/svg/fact_check/baseline.svg'), {className: 'material'}],
["factory", require('@material-icons/svg/svg/factory/baseline.svg'), {className: 'material'}],
["family_restroom", require('@material-icons/svg/svg/family_restroom/baseline.svg'), {className: 'material'}],
["fast_forward", require('@material-icons/svg/svg/fast_forward/baseline.svg'), {className: 'material'}],
["fast_rewind", require('@material-icons/svg/svg/fast_rewind/baseline.svg'), {className: 'material'}],
["fastfood", require('@material-icons/svg/svg/fastfood/baseline.svg'), {className: 'material'}],
["favorite", require('@material-icons/svg/svg/favorite/baseline.svg'), {className: 'material'}],
["favorite_border", require('@material-icons/svg/svg/favorite_border/baseline.svg'), {className: 'material'}],
["fax", require('@material-icons/svg/svg/fax/baseline.svg'), {className: 'material'}],
["featured_play_list", require('@material-icons/svg/svg/featured_play_list/baseline.svg'), {className: 'material'}],
["featured_video", require('@material-icons/svg/svg/featured_video/baseline.svg'), {className: 'material'}],
["feed", require('@material-icons/svg/svg/feed/baseline.svg'), {className: 'material'}],
["feedback", require('@material-icons/svg/svg/feedback/baseline.svg'), {className: 'material'}],
["female", require('@material-icons/svg/svg/female/baseline.svg'), {className: 'material'}],
["fence", require('@material-icons/svg/svg/fence/baseline.svg'), {className: 'material'}],
["festival", require('@material-icons/svg/svg/festival/baseline.svg'), {className: 'material'}],
["fiber_dvr", require('@material-icons/svg/svg/fiber_dvr/baseline.svg'), {className: 'material'}],
["fiber_manual_record", require('@material-icons/svg/svg/fiber_manual_record/baseline.svg'), {className: 'material'}],
["fiber_new", require('@material-icons/svg/svg/fiber_new/baseline.svg'), {className: 'material'}],
["fiber_pin", require('@material-icons/svg/svg/fiber_pin/baseline.svg'), {className: 'material'}],
["fiber_smart_record", require('@material-icons/svg/svg/fiber_smart_record/baseline.svg'), {className: 'material'}],
["file_copy", require('@material-icons/svg/svg/file_copy/baseline.svg'), {className: 'material'}],
["file_download", require('@material-icons/svg/svg/file_download/baseline.svg'), {className: 'material'}],
["file_download_done", require('@material-icons/svg/svg/file_download_done/baseline.svg'), {className: 'material'}],
["file_download_off", require('@material-icons/svg/svg/file_download_off/baseline.svg'), {className: 'material'}],
["file_open", require('@material-icons/svg/svg/file_open/baseline.svg'), {className: 'material'}],
["file_present", require('@material-icons/svg/svg/file_present/baseline.svg'), {className: 'material'}],
["file_upload", require('@material-icons/svg/svg/file_upload/baseline.svg'), {className: 'material'}],
["filter", require('@material-icons/svg/svg/filter/baseline.svg'), {className: 'material'}],
["filter_1", require('@material-icons/svg/svg/filter_1/baseline.svg'), {className: 'material'}],
["filter_2", require('@material-icons/svg/svg/filter_2/baseline.svg'), {className: 'material'}],
["filter_3", require('@material-icons/svg/svg/filter_3/baseline.svg'), {className: 'material'}],
["filter_4", require('@material-icons/svg/svg/filter_4/baseline.svg'), {className: 'material'}],
["filter_5", require('@material-icons/svg/svg/filter_5/baseline.svg'), {className: 'material'}],
["filter_6", require('@material-icons/svg/svg/filter_6/baseline.svg'), {className: 'material'}],
["filter_7", require('@material-icons/svg/svg/filter_7/baseline.svg'), {className: 'material'}],
["filter_8", require('@material-icons/svg/svg/filter_8/baseline.svg'), {className: 'material'}],
["filter_9", require('@material-icons/svg/svg/filter_9/baseline.svg'), {className: 'material'}],
["filter_9_plus", require('@material-icons/svg/svg/filter_9_plus/baseline.svg'), {className: 'material'}],
["filter_alt", require('@material-icons/svg/svg/filter_alt/baseline.svg'), {className: 'material'}],
["filter_alt_off", require('@material-icons/svg/svg/filter_alt_off/baseline.svg'), {className: 'material'}],
["filter_b_and_w", require('@material-icons/svg/svg/filter_b_and_w/baseline.svg'), {className: 'material'}],
["filter_center_focus", require('@material-icons/svg/svg/filter_center_focus/baseline.svg'), {className: 'material'}],
["filter_drama", require('@material-icons/svg/svg/filter_drama/baseline.svg'), {className: 'material'}],
["filter_frames", require('@material-icons/svg/svg/filter_frames/baseline.svg'), {className: 'material'}],
["filter_hdr", require('@material-icons/svg/svg/filter_hdr/baseline.svg'), {className: 'material'}],
["filter_list", require('@material-icons/svg/svg/filter_list/baseline.svg'), {className: 'material'}],
["filter_list_off", require('@material-icons/svg/svg/filter_list_off/baseline.svg'), {className: 'material'}],
["filter_none", require('@material-icons/svg/svg/filter_none/baseline.svg'), {className: 'material'}],
["filter_tilt_shift", require('@material-icons/svg/svg/filter_tilt_shift/baseline.svg'), {className: 'material'}],
["filter_vintage", require('@material-icons/svg/svg/filter_vintage/baseline.svg'), {className: 'material'}],
["find_in_page", require('@material-icons/svg/svg/find_in_page/baseline.svg'), {className: 'material'}],
["find_replace", require('@material-icons/svg/svg/find_replace/baseline.svg'), {className: 'material'}],
["fingerprint", require('@material-icons/svg/svg/fingerprint/baseline.svg'), {className: 'material'}],
["fire_extinguisher", require('@material-icons/svg/svg/fire_extinguisher/baseline.svg'), {className: 'material'}],
["fireplace", require('@material-icons/svg/svg/fireplace/baseline.svg'), {className: 'material'}],
["first_page", require('@material-icons/svg/svg/first_page/baseline.svg'), {className: 'material'}],
["fit_screen", require('@material-icons/svg/svg/fit_screen/baseline.svg'), {className: 'material'}],
["fitbit", require('@material-icons/svg/svg/fitbit/baseline.svg'), {className: 'material'}],
["fitness_center", require('@material-icons/svg/svg/fitness_center/baseline.svg'), {className: 'material'}],
["flag", require('@material-icons/svg/svg/flag/baseline.svg'), {className: 'material'}],
["flag_circle", require('@material-icons/svg/svg/flag_circle/baseline.svg'), {className: 'material'}],
["flaky", require('@material-icons/svg/svg/flaky/baseline.svg'), {className: 'material'}],
["flare", require('@material-icons/svg/svg/flare/baseline.svg'), {className: 'material'}],
["flash_auto", require('@material-icons/svg/svg/flash_auto/baseline.svg'), {className: 'material'}],
["flash_off", require('@material-icons/svg/svg/flash_off/baseline.svg'), {className: 'material'}],
["flash_on", require('@material-icons/svg/svg/flash_on/baseline.svg'), {className: 'material'}],
["flashlight_off", require('@material-icons/svg/svg/flashlight_off/baseline.svg'), {className: 'material'}],
["flashlight_on", require('@material-icons/svg/svg/flashlight_on/baseline.svg'), {className: 'material'}],
["flatware", require('@material-icons/svg/svg/flatware/baseline.svg'), {className: 'material'}],
["flight", require('@material-icons/svg/svg/flight/baseline.svg'), {className: 'material'}],
["flight_class", require('@material-icons/svg/svg/flight_class/baseline.svg'), {className: 'material'}],
["flight_land", require('@material-icons/svg/svg/flight_land/baseline.svg'), {className: 'material'}],
["flight_takeoff", require('@material-icons/svg/svg/flight_takeoff/baseline.svg'), {className: 'material'}],
["flip", require('@material-icons/svg/svg/flip/baseline.svg'), {className: 'material'}],
["flip_camera_android", require('@material-icons/svg/svg/flip_camera_android/baseline.svg'), {className: 'material'}],
["flip_camera_ios", require('@material-icons/svg/svg/flip_camera_ios/baseline.svg'), {className: 'material'}],
["flip_to_back", require('@material-icons/svg/svg/flip_to_back/baseline.svg'), {className: 'material'}],
["flip_to_front", require('@material-icons/svg/svg/flip_to_front/baseline.svg'), {className: 'material'}],
["flourescent", require('@material-icons/svg/svg/flourescent/baseline.svg'), {className: 'material'}],
["flutter_dash", require('@material-icons/svg/svg/flutter_dash/baseline.svg'), {className: 'material'}],
["fmd_bad", require('@material-icons/svg/svg/fmd_bad/baseline.svg'), {className: 'material'}],
["fmd_good", require('@material-icons/svg/svg/fmd_good/baseline.svg'), {className: 'material'}],
["folder", require('@material-icons/svg/svg/folder/baseline.svg'), {className: 'material'}],
["folder_copy", require('@material-icons/svg/svg/folder_copy/baseline.svg'), {className: 'material'}],
["folder_delete", require('@material-icons/svg/svg/folder_delete/baseline.svg'), {className: 'material'}],
["folder_off", require('@material-icons/svg/svg/folder_off/baseline.svg'), {className: 'material'}],
["folder_open", require('@material-icons/svg/svg/folder_open/baseline.svg'), {className: 'material'}],
["folder_shared", require('@material-icons/svg/svg/folder_shared/baseline.svg'), {className: 'material'}],
["folder_special", require('@material-icons/svg/svg/folder_special/baseline.svg'), {className: 'material'}],
["folder_zip", require('@material-icons/svg/svg/folder_zip/baseline.svg'), {className: 'material'}],
["follow_the_signs", require('@material-icons/svg/svg/follow_the_signs/baseline.svg'), {className: 'material'}],
["font_download", require('@material-icons/svg/svg/font_download/baseline.svg'), {className: 'material'}],
["font_download_off", require('@material-icons/svg/svg/font_download_off/baseline.svg'), {className: 'material'}],
["food_bank", require('@material-icons/svg/svg/food_bank/baseline.svg'), {className: 'material'}],
["forest", require('@material-icons/svg/svg/forest/baseline.svg'), {className: 'material'}],
["fork_left", require('@material-icons/svg/svg/fork_left/baseline.svg'), {className: 'material'}],
["fork_right", require('@material-icons/svg/svg/fork_right/baseline.svg'), {className: 'material'}],
["format_align_center", require('@material-icons/svg/svg/format_align_center/baseline.svg'), {className: 'material'}],
["format_align_justify", require('@material-icons/svg/svg/format_align_justify/baseline.svg'), {className: 'material'}],
["format_align_left", require('@material-icons/svg/svg/format_align_left/baseline.svg'), {className: 'material'}],
["format_align_right", require('@material-icons/svg/svg/format_align_right/baseline.svg'), {className: 'material'}],
["format_bold", require('@material-icons/svg/svg/format_bold/baseline.svg'), {className: 'material'}],
["format_clear", require('@material-icons/svg/svg/format_clear/baseline.svg'), {className: 'material'}],
["format_color_fill", require('@material-icons/svg/svg/format_color_fill/baseline.svg'), {className: 'material'}],
["format_color_reset", require('@material-icons/svg/svg/format_color_reset/baseline.svg'), {className: 'material'}],
["format_color_text", require('@material-icons/svg/svg/format_color_text/baseline.svg'), {className: 'material'}],
["format_indent_decrease", require('@material-icons/svg/svg/format_indent_decrease/baseline.svg'), {className: 'material'}],
["format_indent_increase", require('@material-icons/svg/svg/format_indent_increase/baseline.svg'), {className: 'material'}],
["format_italic", require('@material-icons/svg/svg/format_italic/baseline.svg'), {className: 'material'}],
["format_line_spacing", require('@material-icons/svg/svg/format_line_spacing/baseline.svg'), {className: 'material'}],
["format_list_bulleted", require('@material-icons/svg/svg/format_list_bulleted/baseline.svg'), {className: 'material'}],
["format_list_numbered", require('@material-icons/svg/svg/format_list_numbered/baseline.svg'), {className: 'material'}],
["format_list_numbered_rtl", require('@material-icons/svg/svg/format_list_numbered_rtl/baseline.svg'), {className: 'material'}],
["format_overline", require('@material-icons/svg/svg/format_overline/baseline.svg'), {className: 'material'}],
["format_paint", require('@material-icons/svg/svg/format_paint/baseline.svg'), {className: 'material'}],
["format_quote", require('@material-icons/svg/svg/format_quote/baseline.svg'), {className: 'material'}],
["format_shapes", require('@material-icons/svg/svg/format_shapes/baseline.svg'), {className: 'material'}],
["format_size", require('@material-icons/svg/svg/format_size/baseline.svg'), {className: 'material'}],
["format_strikethrough", require('@material-icons/svg/svg/format_strikethrough/baseline.svg'), {className: 'material'}],
["format_textdirection_l_to_r", require('@material-icons/svg/svg/format_textdirection_l_to_r/baseline.svg'), {className: 'material'}],
["format_textdirection_r_to_l", require('@material-icons/svg/svg/format_textdirection_r_to_l/baseline.svg'), {className: 'material'}],
["format_underlined", require('@material-icons/svg/svg/format_underlined/baseline.svg'), {className: 'material'}],
["fort", require('@material-icons/svg/svg/fort/baseline.svg'), {className: 'material'}],
["forum", require('@material-icons/svg/svg/forum/baseline.svg'), {className: 'material'}],
["forward", require('@material-icons/svg/svg/forward/baseline.svg'), {className: 'material'}],
["forward_10", require('@material-icons/svg/svg/forward_10/baseline.svg'), {className: 'material'}],
["forward_30", require('@material-icons/svg/svg/forward_30/baseline.svg'), {className: 'material'}],
["forward_5", require('@material-icons/svg/svg/forward_5/baseline.svg'), {className: 'material'}],
["forward_to_inbox", require('@material-icons/svg/svg/forward_to_inbox/baseline.svg'), {className: 'material'}],
["foundation", require('@material-icons/svg/svg/foundation/baseline.svg'), {className: 'material'}],
["free_breakfast", require('@material-icons/svg/svg/free_breakfast/baseline.svg'), {className: 'material'}],
["free_cancellation", require('@material-icons/svg/svg/free_cancellation/baseline.svg'), {className: 'material'}],
["front_hand", require('@material-icons/svg/svg/front_hand/baseline.svg'), {className: 'material'}],
["fullscreen", require('@material-icons/svg/svg/fullscreen/baseline.svg'), {className: 'material'}],
["fullscreen_exit", require('@material-icons/svg/svg/fullscreen_exit/baseline.svg'), {className: 'material'}],
["functions", require('@material-icons/svg/svg/functions/baseline.svg'), {className: 'material'}],
["g_mobiledata", require('@material-icons/svg/svg/g_mobiledata/baseline.svg'), {className: 'material'}],
["g_translate", require('@material-icons/svg/svg/g_translate/baseline.svg'), {className: 'material'}],
["gamepad", require('@material-icons/svg/svg/gamepad/baseline.svg'), {className: 'material'}],
["games", require('@material-icons/svg/svg/games/baseline.svg'), {className: 'material'}],
["garage", require('@material-icons/svg/svg/garage/baseline.svg'), {className: 'material'}],
["gavel", require('@material-icons/svg/svg/gavel/baseline.svg'), {className: 'material'}],
["generating_tokens", require('@material-icons/svg/svg/generating_tokens/baseline.svg'), {className: 'material'}],
["gesture", require('@material-icons/svg/svg/gesture/baseline.svg'), {className: 'material'}],
["get_app", require('@material-icons/svg/svg/get_app/baseline.svg'), {className: 'material'}],
["gif", require('@material-icons/svg/svg/gif/baseline.svg'), {className: 'material'}],
["gif_box", require('@material-icons/svg/svg/gif_box/baseline.svg'), {className: 'material'}],
["girl", require('@material-icons/svg/svg/girl/baseline.svg'), {className: 'material'}],
["gite", require('@material-icons/svg/svg/gite/baseline.svg'), {className: 'material'}],
["golf_course", require('@material-icons/svg/svg/golf_course/baseline.svg'), {className: 'material'}],
["gpp_bad", require('@material-icons/svg/svg/gpp_bad/baseline.svg'), {className: 'material'}],
["gpp_good", require('@material-icons/svg/svg/gpp_good/baseline.svg'), {className: 'material'}],
["gpp_maybe", require('@material-icons/svg/svg/gpp_maybe/baseline.svg'), {className: 'material'}],
["gps_fixed", require('@material-icons/svg/svg/gps_fixed/baseline.svg'), {className: 'material'}],
["gps_not_fixed", require('@material-icons/svg/svg/gps_not_fixed/baseline.svg'), {className: 'material'}],
["gps_off", require('@material-icons/svg/svg/gps_off/baseline.svg'), {className: 'material'}],
["grade", require('@material-icons/svg/svg/grade/baseline.svg'), {className: 'material'}],
["gradient", require('@material-icons/svg/svg/gradient/baseline.svg'), {className: 'material'}],
["grading", require('@material-icons/svg/svg/grading/baseline.svg'), {className: 'material'}],
["grain", require('@material-icons/svg/svg/grain/baseline.svg'), {className: 'material'}],
["graphic_eq", require('@material-icons/svg/svg/graphic_eq/baseline.svg'), {className: 'material'}],
["grass", require('@material-icons/svg/svg/grass/baseline.svg'), {className: 'material'}],
["grid_3x3", require('@material-icons/svg/svg/grid_3x3/baseline.svg'), {className: 'material'}],
["grid_4x4", require('@material-icons/svg/svg/grid_4x4/baseline.svg'), {className: 'material'}],
["grid_goldenratio", require('@material-icons/svg/svg/grid_goldenratio/baseline.svg'), {className: 'material'}],
["grid_off", require('@material-icons/svg/svg/grid_off/baseline.svg'), {className: 'material'}],
["grid_on", require('@material-icons/svg/svg/grid_on/baseline.svg'), {className: 'material'}],
["grid_view", require('@material-icons/svg/svg/grid_view/baseline.svg'), {className: 'material'}],
["group", require('@material-icons/svg/svg/group/baseline.svg'), {className: 'material'}],
["group_add", require('@material-icons/svg/svg/group_add/baseline.svg'), {className: 'material'}],
["group_off", require('@material-icons/svg/svg/group_off/baseline.svg'), {className: 'material'}],
["group_remove", require('@material-icons/svg/svg/group_remove/baseline.svg'), {className: 'material'}],
["group_work", require('@material-icons/svg/svg/group_work/baseline.svg'), {className: 'material'}],
["groups", require('@material-icons/svg/svg/groups/baseline.svg'), {className: 'material'}],
["h_mobiledata", require('@material-icons/svg/svg/h_mobiledata/baseline.svg'), {className: 'material'}],
["h_plus_mobiledata", require('@material-icons/svg/svg/h_plus_mobiledata/baseline.svg'), {className: 'material'}],
["hail", require('@material-icons/svg/svg/hail/baseline.svg'), {className: 'material'}],
["handshake", require('@material-icons/svg/svg/handshake/baseline.svg'), {className: 'material'}],
["handyman", require('@material-icons/svg/svg/handyman/baseline.svg'), {className: 'material'}],
["hardware", require('@material-icons/svg/svg/hardware/baseline.svg'), {className: 'material'}],
["hd", require('@material-icons/svg/svg/hd/baseline.svg'), {className: 'material'}],
["hdr_auto", require('@material-icons/svg/svg/hdr_auto/baseline.svg'), {className: 'material'}],
["hdr_auto_select", require('@material-icons/svg/svg/hdr_auto_select/baseline.svg'), {className: 'material'}],
["hdr_enhanced_select", require('@material-icons/svg/svg/hdr_enhanced_select/baseline.svg'), {className: 'material'}],
["hdr_off", require('@material-icons/svg/svg/hdr_off/baseline.svg'), {className: 'material'}],
["hdr_off_select", require('@material-icons/svg/svg/hdr_off_select/baseline.svg'), {className: 'material'}],
["hdr_on", require('@material-icons/svg/svg/hdr_on/baseline.svg'), {className: 'material'}],
["hdr_on_select", require('@material-icons/svg/svg/hdr_on_select/baseline.svg'), {className: 'material'}],
["hdr_plus", require('@material-icons/svg/svg/hdr_plus/baseline.svg'), {className: 'material'}],
["hdr_strong", require('@material-icons/svg/svg/hdr_strong/baseline.svg'), {className: 'material'}],
["hdr_weak", require('@material-icons/svg/svg/hdr_weak/baseline.svg'), {className: 'material'}],
["headphones", require('@material-icons/svg/svg/headphones/baseline.svg'), {className: 'material'}],
["headphones_battery", require('@material-icons/svg/svg/headphones_battery/baseline.svg'), {className: 'material'}],
["headset", require('@material-icons/svg/svg/headset/baseline.svg'), {className: 'material'}],
["headset_mic", require('@material-icons/svg/svg/headset_mic/baseline.svg'), {className: 'material'}],
["headset_off", require('@material-icons/svg/svg/headset_off/baseline.svg'), {className: 'material'}],
["healing", require('@material-icons/svg/svg/healing/baseline.svg'), {className: 'material'}],
["health_and_safety", require('@material-icons/svg/svg/health_and_safety/baseline.svg'), {className: 'material'}],
["hearing", require('@material-icons/svg/svg/hearing/baseline.svg'), {className: 'material'}],
["hearing_disabled", require('@material-icons/svg/svg/hearing_disabled/baseline.svg'), {className: 'material'}],
["heart_broken", require('@material-icons/svg/svg/heart_broken/baseline.svg'), {className: 'material'}],
["height", require('@material-icons/svg/svg/height/baseline.svg'), {className: 'material'}],
["help", require('@material-icons/svg/svg/help/baseline.svg'), {className: 'material'}],
["help_center", require('@material-icons/svg/svg/help_center/baseline.svg'), {className: 'material'}],
["help_outline", require('@material-icons/svg/svg/help_outline/baseline.svg'), {className: 'material'}],
["hevc", require('@material-icons/svg/svg/hevc/baseline.svg'), {className: 'material'}],
["hexagon", require('@material-icons/svg/svg/hexagon/baseline.svg'), {className: 'material'}],
["hide_image", require('@material-icons/svg/svg/hide_image/baseline.svg'), {className: 'material'}],
["hide_source", require('@material-icons/svg/svg/hide_source/baseline.svg'), {className: 'material'}],
["high_quality", require('@material-icons/svg/svg/high_quality/baseline.svg'), {className: 'material'}],
["highlight", require('@material-icons/svg/svg/highlight/baseline.svg'), {className: 'material'}],
["highlight_alt", require('@material-icons/svg/svg/highlight_alt/baseline.svg'), {className: 'material'}],
["highlight_off", require('@material-icons/svg/svg/highlight_off/baseline.svg'), {className: 'material'}],
["hiking", require('@material-icons/svg/svg/hiking/baseline.svg'), {className: 'material'}],
["history", require('@material-icons/svg/svg/history/baseline.svg'), {className: 'material'}],
["history_edu", require('@material-icons/svg/svg/history_edu/baseline.svg'), {className: 'material'}],
["history_toggle_off", require('@material-icons/svg/svg/history_toggle_off/baseline.svg'), {className: 'material'}],
["hive", require('@material-icons/svg/svg/hive/baseline.svg'), {className: 'material'}],
["hls", require('@material-icons/svg/svg/hls/baseline.svg'), {className: 'material'}],
["hls_off", require('@material-icons/svg/svg/hls_off/baseline.svg'), {className: 'material'}],
["holiday_village", require('@material-icons/svg/svg/holiday_village/baseline.svg'), {className: 'material'}],
["home", require('@material-icons/svg/svg/home/baseline.svg'), {className: 'material'}],
["home_max", require('@material-icons/svg/svg/home_max/baseline.svg'), {className: 'material'}],
["home_mini", require('@material-icons/svg/svg/home_mini/baseline.svg'), {className: 'material'}],
["home_repair_service", require('@material-icons/svg/svg/home_repair_service/baseline.svg'), {className: 'material'}],
["home_work", require('@material-icons/svg/svg/home_work/baseline.svg'), {className: 'material'}],
["horizontal_distribute", require('@material-icons/svg/svg/horizontal_distribute/baseline.svg'), {className: 'material'}],
["horizontal_rule", require('@material-icons/svg/svg/horizontal_rule/baseline.svg'), {className: 'material'}],
["horizontal_split", require('@material-icons/svg/svg/horizontal_split/baseline.svg'), {className: 'material'}],
["hot_tub", require('@material-icons/svg/svg/hot_tub/baseline.svg'), {className: 'material'}],
["hotel", require('@material-icons/svg/svg/hotel/baseline.svg'), {className: 'material'}],
["hotel_class", require('@material-icons/svg/svg/hotel_class/baseline.svg'), {className: 'material'}],
["hourglass_bottom", require('@material-icons/svg/svg/hourglass_bottom/baseline.svg'), {className: 'material'}],
["hourglass_disabled", require('@material-icons/svg/svg/hourglass_disabled/baseline.svg'), {className: 'material'}],
["hourglass_empty", require('@material-icons/svg/svg/hourglass_empty/baseline.svg'), {className: 'material'}],
["hourglass_full", require('@material-icons/svg/svg/hourglass_full/baseline.svg'), {className: 'material'}],
["hourglass_top", require('@material-icons/svg/svg/hourglass_top/baseline.svg'), {className: 'material'}],
["house", require('@material-icons/svg/svg/house/baseline.svg'), {className: 'material'}],
["house_siding", require('@material-icons/svg/svg/house_siding/baseline.svg'), {className: 'material'}],
["houseboat", require('@material-icons/svg/svg/houseboat/baseline.svg'), {className: 'material'}],
["how_to_reg", require('@material-icons/svg/svg/how_to_reg/baseline.svg'), {className: 'material'}],
["how_to_vote", require('@material-icons/svg/svg/how_to_vote/baseline.svg'), {className: 'material'}],
["html", require('@material-icons/svg/svg/html/baseline.svg'), {className: 'material'}],
["http", require('@material-icons/svg/svg/http/baseline.svg'), {className: 'material'}],
["https", require('@material-icons/svg/svg/https/baseline.svg'), {className: 'material'}],
["hub", require('@material-icons/svg/svg/hub/baseline.svg'), {className: 'material'}],
["hvac", require('@material-icons/svg/svg/hvac/baseline.svg'), {className: 'material'}],
["ice_skating", require('@material-icons/svg/svg/ice_skating/baseline.svg'), {className: 'material'}],
["icecream", require('@material-icons/svg/svg/icecream/baseline.svg'), {className: 'material'}],
["image", require('@material-icons/svg/svg/image/baseline.svg'), {className: 'material'}],
["image_aspect_ratio", require('@material-icons/svg/svg/image_aspect_ratio/baseline.svg'), {className: 'material'}],
["image_not_supported", require('@material-icons/svg/svg/image_not_supported/baseline.svg'), {className: 'material'}],
["image_search", require('@material-icons/svg/svg/image_search/baseline.svg'), {className: 'material'}],
["imagesearch_roller", require('@material-icons/svg/svg/imagesearch_roller/baseline.svg'), {className: 'material'}],
["import_contacts", require('@material-icons/svg/svg/import_contacts/baseline.svg'), {className: 'material'}],
["import_export", require('@material-icons/svg/svg/import_export/baseline.svg'), {className: 'material'}],
["important_devices", require('@material-icons/svg/svg/important_devices/baseline.svg'), {className: 'material'}],
["inbox", require('@material-icons/svg/svg/inbox/baseline.svg'), {className: 'material'}],
["incomplete_circle", require('@material-icons/svg/svg/incomplete_circle/baseline.svg'), {className: 'material'}],
["indeterminate_check_box", require('@material-icons/svg/svg/indeterminate_check_box/baseline.svg'), {className: 'material'}],
["info", require('@material-icons/svg/svg/info/baseline.svg'), {className: 'material'}],
["input", require('@material-icons/svg/svg/input/baseline.svg'), {className: 'material'}],
["insert_chart", require('@material-icons/svg/svg/insert_chart/baseline.svg'), {className: 'material'}],
["insert_chart_outlined", require('@material-icons/svg/svg/insert_chart_outlined/baseline.svg'), {className: 'material'}],
["insert_comment", require('@material-icons/svg/svg/insert_comment/baseline.svg'), {className: 'material'}],
["insert_drive_file", require('@material-icons/svg/svg/insert_drive_file/baseline.svg'), {className: 'material'}],
["insert_emoticon", require('@material-icons/svg/svg/insert_emoticon/baseline.svg'), {className: 'material'}],
["insert_invitation", require('@material-icons/svg/svg/insert_invitation/baseline.svg'), {className: 'material'}],
["insert_link", require('@material-icons/svg/svg/insert_link/baseline.svg'), {className: 'material'}],
["insert_page_break", require('@material-icons/svg/svg/insert_page_break/baseline.svg'), {className: 'material'}],
["insert_photo", require('@material-icons/svg/svg/insert_photo/baseline.svg'), {className: 'material'}],
["insights", require('@material-icons/svg/svg/insights/baseline.svg'), {className: 'material'}],
["install_desktop", require('@material-icons/svg/svg/install_desktop/baseline.svg'), {className: 'material'}],
["install_mobile", require('@material-icons/svg/svg/install_mobile/baseline.svg'), {className: 'material'}],
["integration_instructions", require('@material-icons/svg/svg/integration_instructions/baseline.svg'), {className: 'material'}],
["interests", require('@material-icons/svg/svg/interests/baseline.svg'), {className: 'material'}],
["interpreter_mode", require('@material-icons/svg/svg/interpreter_mode/baseline.svg'), {className: 'material'}],
["inventory", require('@material-icons/svg/svg/inventory/baseline.svg'), {className: 'material'}],
["inventory_2", require('@material-icons/svg/svg/inventory_2/baseline.svg'), {className: 'material'}],
["invert_colors", require('@material-icons/svg/svg/invert_colors/baseline.svg'), {className: 'material'}],
["invert_colors_off", require('@material-icons/svg/svg/invert_colors_off/baseline.svg'), {className: 'material'}],
["ios_share", require('@material-icons/svg/svg/ios_share/baseline.svg'), {className: 'material'}],
["iron", require('@material-icons/svg/svg/iron/baseline.svg'), {className: 'material'}],
["iso", require('@material-icons/svg/svg/iso/baseline.svg'), {className: 'material'}],
["javascript", require('@material-icons/svg/svg/javascript/baseline.svg'), {className: 'material'}],
["join_full", require('@material-icons/svg/svg/join_full/baseline.svg'), {className: 'material'}],
["join_inner", require('@material-icons/svg/svg/join_inner/baseline.svg'), {className: 'material'}],
["join_left", require('@material-icons/svg/svg/join_left/baseline.svg'), {className: 'material'}],
["join_right", require('@material-icons/svg/svg/join_right/baseline.svg'), {className: 'material'}],
["kayaking", require('@material-icons/svg/svg/kayaking/baseline.svg'), {className: 'material'}],
["kebab_dining", require('@material-icons/svg/svg/kebab_dining/baseline.svg'), {className: 'material'}],
["key", require('@material-icons/svg/svg/key/baseline.svg'), {className: 'material'}],
["key_off", require('@material-icons/svg/svg/key_off/baseline.svg'), {className: 'material'}],
["keyboard", require('@material-icons/svg/svg/keyboard/baseline.svg'), {className: 'material'}],
["keyboard_alt", require('@material-icons/svg/svg/keyboard_alt/baseline.svg'), {className: 'material'}],
["keyboard_arrow_down", require('@material-icons/svg/svg/keyboard_arrow_down/baseline.svg'), {className: 'material'}],
["keyboard_arrow_left", require('@material-icons/svg/svg/keyboard_arrow_left/baseline.svg'), {className: 'material'}],
["keyboard_arrow_right", require('@material-icons/svg/svg/keyboard_arrow_right/baseline.svg'), {className: 'material'}],
["keyboard_arrow_up", require('@material-icons/svg/svg/keyboard_arrow_up/baseline.svg'), {className: 'material'}],
["keyboard_backspace", require('@material-icons/svg/svg/keyboard_backspace/baseline.svg'), {className: 'material'}],
["keyboard_capslock", require('@material-icons/svg/svg/keyboard_capslock/baseline.svg'), {className: 'material'}],
["keyboard_command_key", require('@material-icons/svg/svg/keyboard_command_key/baseline.svg'), {className: 'material'}],
["keyboard_control_key", require('@material-icons/svg/svg/keyboard_control_key/baseline.svg'), {className: 'material'}],
["keyboard_double_arrow_down", require('@material-icons/svg/svg/keyboard_double_arrow_down/baseline.svg'), {className: 'material'}],
["keyboard_double_arrow_left", require('@material-icons/svg/svg/keyboard_double_arrow_left/baseline.svg'), {className: 'material'}],
["keyboard_double_arrow_right", require('@material-icons/svg/svg/keyboard_double_arrow_right/baseline.svg'), {className: 'material'}],
["keyboard_double_arrow_up", require('@material-icons/svg/svg/keyboard_double_arrow_up/baseline.svg'), {className: 'material'}],
["keyboard_hide", require('@material-icons/svg/svg/keyboard_hide/baseline.svg'), {className: 'material'}],
["keyboard_option_key", require('@material-icons/svg/svg/keyboard_option_key/baseline.svg'), {className: 'material'}],
["keyboard_return", require('@material-icons/svg/svg/keyboard_return/baseline.svg'), {className: 'material'}],
["keyboard_tab", require('@material-icons/svg/svg/keyboard_tab/baseline.svg'), {className: 'material'}],
["keyboard_voice", require('@material-icons/svg/svg/keyboard_voice/baseline.svg'), {className: 'material'}],
["king_bed", require('@material-icons/svg/svg/king_bed/baseline.svg'), {className: 'material'}],
["kitchen", require('@material-icons/svg/svg/kitchen/baseline.svg'), {className: 'material'}],
["kitesurfing", require('@material-icons/svg/svg/kitesurfing/baseline.svg'), {className: 'material'}],
["label", require('@material-icons/svg/svg/label/baseline.svg'), {className: 'material'}],
["label_important", require('@material-icons/svg/svg/label_important/baseline.svg'), {className: 'material'}],
["label_off", require('@material-icons/svg/svg/label_off/baseline.svg'), {className: 'material'}],
["lan", require('@material-icons/svg/svg/lan/baseline.svg'), {className: 'material'}],
["landscape", require('@material-icons/svg/svg/landscape/baseline.svg'), {className: 'material'}],
["language", require('@material-icons/svg/svg/language/baseline.svg'), {className: 'material'}],
["laptop", require('@material-icons/svg/svg/laptop/baseline.svg'), {className: 'material'}],
["laptop_chromebook", require('@material-icons/svg/svg/laptop_chromebook/baseline.svg'), {className: 'material'}],
["laptop_mac", require('@material-icons/svg/svg/laptop_mac/baseline.svg'), {className: 'material'}],
["laptop_windows", require('@material-icons/svg/svg/laptop_windows/baseline.svg'), {className: 'material'}],
["last_page", require('@material-icons/svg/svg/last_page/baseline.svg'), {className: 'material'}],
["launch", require('@material-icons/svg/svg/launch/baseline.svg'), {className: 'material'}],
["layers", require('@material-icons/svg/svg/layers/baseline.svg'), {className: 'material'}],
["layers_clear", require('@material-icons/svg/svg/layers_clear/baseline.svg'), {className: 'material'}],
["leaderboard", require('@material-icons/svg/svg/leaderboard/baseline.svg'), {className: 'material'}],
["leak_add", require('@material-icons/svg/svg/leak_add/baseline.svg'), {className: 'material'}],
["leak_remove", require('@material-icons/svg/svg/leak_remove/baseline.svg'), {className: 'material'}],
["leave_bags_at_home", require('@material-icons/svg/svg/leave_bags_at_home/baseline.svg'), {className: 'material'}],
["legend_toggle", require('@material-icons/svg/svg/legend_toggle/baseline.svg'), {className: 'material'}],
["lens", require('@material-icons/svg/svg/lens/baseline.svg'), {className: 'material'}],
["lens_blur", require('@material-icons/svg/svg/lens_blur/baseline.svg'), {className: 'material'}],
["library_add", require('@material-icons/svg/svg/library_add/baseline.svg'), {className: 'material'}],
["library_add_check", require('@material-icons/svg/svg/library_add_check/baseline.svg'), {className: 'material'}],
["library_books", require('@material-icons/svg/svg/library_books/baseline.svg'), {className: 'material'}],
["library_music", require('@material-icons/svg/svg/library_music/baseline.svg'), {className: 'material'}],
["light", require('@material-icons/svg/svg/light/baseline.svg'), {className: 'material'}],
["light_mode", require('@material-icons/svg/svg/light_mode/baseline.svg'), {className: 'material'}],
["lightbulb", require('@material-icons/svg/svg/lightbulb/baseline.svg'), {className: 'material'}],
["line_axis", require('@material-icons/svg/svg/line_axis/baseline.svg'), {className: 'material'}],
["line_style", require('@material-icons/svg/svg/line_style/baseline.svg'), {className: 'material'}],
["line_weight", require('@material-icons/svg/svg/line_weight/baseline.svg'), {className: 'material'}],
["linear_scale", require('@material-icons/svg/svg/linear_scale/baseline.svg'), {className: 'material'}],
["link", require('@material-icons/svg/svg/link/baseline.svg'), {className: 'material'}],
["link_off", require('@material-icons/svg/svg/link_off/baseline.svg'), {className: 'material'}],
["linked_camera", require('@material-icons/svg/svg/linked_camera/baseline.svg'), {className: 'material'}],
["liquor", require('@material-icons/svg/svg/liquor/baseline.svg'), {className: 'material'}],
["list", require('@material-icons/svg/svg/list/baseline.svg'), {className: 'material'}],
["list_alt", require('@material-icons/svg/svg/list_alt/baseline.svg'), {className: 'material'}],
["live_help", require('@material-icons/svg/svg/live_help/baseline.svg'), {className: 'material'}],
["live_tv", require('@material-icons/svg/svg/live_tv/baseline.svg'), {className: 'material'}],
["living", require('@material-icons/svg/svg/living/baseline.svg'), {className: 'material'}],
["local_activity", require('@material-icons/svg/svg/local_activity/baseline.svg'), {className: 'material'}],
["local_airport", require('@material-icons/svg/svg/local_airport/baseline.svg'), {className: 'material'}],
["local_atm", require('@material-icons/svg/svg/local_atm/baseline.svg'), {className: 'material'}],
["local_bar", require('@material-icons/svg/svg/local_bar/baseline.svg'), {className: 'material'}],
["local_cafe", require('@material-icons/svg/svg/local_cafe/baseline.svg'), {className: 'material'}],
["local_car_wash", require('@material-icons/svg/svg/local_car_wash/baseline.svg'), {className: 'material'}],
["local_convenience_store", require('@material-icons/svg/svg/local_convenience_store/baseline.svg'), {className: 'material'}],
["local_dining", require('@material-icons/svg/svg/local_dining/baseline.svg'), {className: 'material'}],
["local_drink", require('@material-icons/svg/svg/local_drink/baseline.svg'), {className: 'material'}],
["local_fire_department", require('@material-icons/svg/svg/local_fire_department/baseline.svg'), {className: 'material'}],
["local_florist", require('@material-icons/svg/svg/local_florist/baseline.svg'), {className: 'material'}],
["local_gas_station", require('@material-icons/svg/svg/local_gas_station/baseline.svg'), {className: 'material'}],
["local_grocery_store", require('@material-icons/svg/svg/local_grocery_store/baseline.svg'), {className: 'material'}],
["local_hospital", require('@material-icons/svg/svg/local_hospital/baseline.svg'), {className: 'material'}],
["local_hotel", require('@material-icons/svg/svg/local_hotel/baseline.svg'), {className: 'material'}],
["local_laundry_service", require('@material-icons/svg/svg/local_laundry_service/baseline.svg'), {className: 'material'}],
["local_library", require('@material-icons/svg/svg/local_library/baseline.svg'), {className: 'material'}],
["local_mall", require('@material-icons/svg/svg/local_mall/baseline.svg'), {className: 'material'}],
["local_movies", require('@material-icons/svg/svg/local_movies/baseline.svg'), {className: 'material'}],
["local_offer", require('@material-icons/svg/svg/local_offer/baseline.svg'), {className: 'material'}],
["local_parking", require('@material-icons/svg/svg/local_parking/baseline.svg'), {className: 'material'}],
["local_pharmacy", require('@material-icons/svg/svg/local_pharmacy/baseline.svg'), {className: 'material'}],
["local_phone", require('@material-icons/svg/svg/local_phone/baseline.svg'), {className: 'material'}],
["local_pizza", require('@material-icons/svg/svg/local_pizza/baseline.svg'), {className: 'material'}],
["local_play", require('@material-icons/svg/svg/local_play/baseline.svg'), {className: 'material'}],
["local_police", require('@material-icons/svg/svg/local_police/baseline.svg'), {className: 'material'}],
["local_post_office", require('@material-icons/svg/svg/local_post_office/baseline.svg'), {className: 'material'}],
["local_printshop", require('@material-icons/svg/svg/local_printshop/baseline.svg'), {className: 'material'}],
["local_see", require('@material-icons/svg/svg/local_see/baseline.svg'), {className: 'material'}],
["local_shipping", require('@material-icons/svg/svg/local_shipping/baseline.svg'), {className: 'material'}],
["local_taxi", require('@material-icons/svg/svg/local_taxi/baseline.svg'), {className: 'material'}],
["location_city", require('@material-icons/svg/svg/location_city/baseline.svg'), {className: 'material'}],
["location_disabled", require('@material-icons/svg/svg/location_disabled/baseline.svg'), {className: 'material'}],
["location_off", require('@material-icons/svg/svg/location_off/baseline.svg'), {className: 'material'}],
["location_on", require('@material-icons/svg/svg/location_on/baseline.svg'), {className: 'material'}],
["location_searching", require('@material-icons/svg/svg/location_searching/baseline.svg'), {className: 'material'}],
["lock", require('@material-icons/svg/svg/lock/baseline.svg'), {className: 'material'}],
["lock_clock", require('@material-icons/svg/svg/lock_clock/baseline.svg'), {className: 'material'}],
["lock_open", require('@material-icons/svg/svg/lock_open/baseline.svg'), {className: 'material'}],
["lock_reset", require('@material-icons/svg/svg/lock_reset/baseline.svg'), {className: 'material'}],
["login", require('@material-icons/svg/svg/login/baseline.svg'), {className: 'material'}],
["logo_dev", require('@material-icons/svg/svg/logo_dev/baseline.svg'), {className: 'material'}],
["logout", require('@material-icons/svg/svg/logout/baseline.svg'), {className: 'material'}],
["looks", require('@material-icons/svg/svg/looks/baseline.svg'), {className: 'material'}],
["looks_3", require('@material-icons/svg/svg/looks_3/baseline.svg'), {className: 'material'}],
["looks_4", require('@material-icons/svg/svg/looks_4/baseline.svg'), {className: 'material'}],
["looks_5", require('@material-icons/svg/svg/looks_5/baseline.svg'), {className: 'material'}],
["looks_6", require('@material-icons/svg/svg/looks_6/baseline.svg'), {className: 'material'}],
["looks_one", require('@material-icons/svg/svg/looks_one/baseline.svg'), {className: 'material'}],
["looks_two", require('@material-icons/svg/svg/looks_two/baseline.svg'), {className: 'material'}],
["loop", require('@material-icons/svg/svg/loop/baseline.svg'), {className: 'material'}],
["loupe", require('@material-icons/svg/svg/loupe/baseline.svg'), {className: 'material'}],
["low_priority", require('@material-icons/svg/svg/low_priority/baseline.svg'), {className: 'material'}],
["loyalty", require('@material-icons/svg/svg/loyalty/baseline.svg'), {className: 'material'}],
["lte_mobiledata", require('@material-icons/svg/svg/lte_mobiledata/baseline.svg'), {className: 'material'}],
["lte_plus_mobiledata", require('@material-icons/svg/svg/lte_plus_mobiledata/baseline.svg'), {className: 'material'}],
["luggage", require('@material-icons/svg/svg/luggage/baseline.svg'), {className: 'material'}],
["lunch_dining", require('@material-icons/svg/svg/lunch_dining/baseline.svg'), {className: 'material'}],
["mail", require('@material-icons/svg/svg/mail/baseline.svg'), {className: 'material'}],
["mail_outline", require('@material-icons/svg/svg/mail_outline/baseline.svg'), {className: 'material'}],
["male", require('@material-icons/svg/svg/male/baseline.svg'), {className: 'material'}],
["man", require('@material-icons/svg/svg/man/baseline.svg'), {className: 'material'}],
["manage_accounts", require('@material-icons/svg/svg/manage_accounts/baseline.svg'), {className: 'material'}],
["manage_search", require('@material-icons/svg/svg/manage_search/baseline.svg'), {className: 'material'}],
["map", require('@material-icons/svg/svg/map/baseline.svg'), {className: 'material'}],
["maps_home_work", require('@material-icons/svg/svg/maps_home_work/baseline.svg'), {className: 'material'}],
["maps_ugc", require('@material-icons/svg/svg/maps_ugc/baseline.svg'), {className: 'material'}],
["margin", require('@material-icons/svg/svg/margin/baseline.svg'), {className: 'material'}],
["mark_as_unread", require('@material-icons/svg/svg/mark_as_unread/baseline.svg'), {className: 'material'}],
["mark_chat_read", require('@material-icons/svg/svg/mark_chat_read/baseline.svg'), {className: 'material'}],
["mark_chat_unread", require('@material-icons/svg/svg/mark_chat_unread/baseline.svg'), {className: 'material'}],
["mark_email_read", require('@material-icons/svg/svg/mark_email_read/baseline.svg'), {className: 'material'}],
["mark_email_unread", require('@material-icons/svg/svg/mark_email_unread/baseline.svg'), {className: 'material'}],
["mark_unread_chat_alt", require('@material-icons/svg/svg/mark_unread_chat_alt/baseline.svg'), {className: 'material'}],
["markunread", require('@material-icons/svg/svg/markunread/baseline.svg'), {className: 'material'}],
["markunread_mailbox", require('@material-icons/svg/svg/markunread_mailbox/baseline.svg'), {className: 'material'}],
["masks", require('@material-icons/svg/svg/masks/baseline.svg'), {className: 'material'}],
["maximize", require('@material-icons/svg/svg/maximize/baseline.svg'), {className: 'material'}],
["media_bluetooth_off", require('@material-icons/svg/svg/media_bluetooth_off/baseline.svg'), {className: 'material'}],
["media_bluetooth_on", require('@material-icons/svg/svg/media_bluetooth_on/baseline.svg'), {className: 'material'}],
["mediation", require('@material-icons/svg/svg/mediation/baseline.svg'), {className: 'material'}],
["medical_services", require('@material-icons/svg/svg/medical_services/baseline.svg'), {className: 'material'}],
["medication", require('@material-icons/svg/svg/medication/baseline.svg'), {className: 'material'}],
["medication_liquid", require('@material-icons/svg/svg/medication_liquid/baseline.svg'), {className: 'material'}],
["meeting_room", require('@material-icons/svg/svg/meeting_room/baseline.svg'), {className: 'material'}],
["memory", require('@material-icons/svg/svg/memory/baseline.svg'), {className: 'material'}],
["menu", require('@material-icons/svg/svg/menu/baseline.svg'), {className: 'material'}],
["menu_book", require('@material-icons/svg/svg/menu_book/baseline.svg'), {className: 'material'}],
["menu_open", require('@material-icons/svg/svg/menu_open/baseline.svg'), {className: 'material'}],
["merge", require('@material-icons/svg/svg/merge/baseline.svg'), {className: 'material'}],
["merge_type", require('@material-icons/svg/svg/merge_type/baseline.svg'), {className: 'material'}],
["message", require('@material-icons/svg/svg/message/baseline.svg'), {className: 'material'}],
["mic", require('@material-icons/svg/svg/mic/baseline.svg'), {className: 'material'}],
["mic_external_off", require('@material-icons/svg/svg/mic_external_off/baseline.svg'), {className: 'material'}],
["mic_external_on", require('@material-icons/svg/svg/mic_external_on/baseline.svg'), {className: 'material'}],
["mic_none", require('@material-icons/svg/svg/mic_none/baseline.svg'), {className: 'material'}],
["mic_off", require('@material-icons/svg/svg/mic_off/baseline.svg'), {className: 'material'}],
["microwave", require('@material-icons/svg/svg/microwave/baseline.svg'), {className: 'material'}],
["military_tech", require('@material-icons/svg/svg/military_tech/baseline.svg'), {className: 'material'}],
["minimize", require('@material-icons/svg/svg/minimize/baseline.svg'), {className: 'material'}],
["miscellaneous_services", require('@material-icons/svg/svg/miscellaneous_services/baseline.svg'), {className: 'material'}],
["missed_video_call", require('@material-icons/svg/svg/missed_video_call/baseline.svg'), {className: 'material'}],
["mms", require('@material-icons/svg/svg/mms/baseline.svg'), {className: 'material'}],
["mobile_friendly", require('@material-icons/svg/svg/mobile_friendly/baseline.svg'), {className: 'material'}],
["mobile_off", require('@material-icons/svg/svg/mobile_off/baseline.svg'), {className: 'material'}],
["mobile_screen_share", require('@material-icons/svg/svg/mobile_screen_share/baseline.svg'), {className: 'material'}],
["mobiledata_off", require('@material-icons/svg/svg/mobiledata_off/baseline.svg'), {className: 'material'}],
["mode", require('@material-icons/svg/svg/mode/baseline.svg'), {className: 'material'}],
["mode_comment", require('@material-icons/svg/svg/mode_comment/baseline.svg'), {className: 'material'}],
["mode_edit", require('@material-icons/svg/svg/mode_edit/baseline.svg'), {className: 'material'}],
["mode_edit_outline", require('@material-icons/svg/svg/mode_edit_outline/baseline.svg'), {className: 'material'}],
["mode_night", require('@material-icons/svg/svg/mode_night/baseline.svg'), {className: 'material'}],
["mode_of_travel", require('@material-icons/svg/svg/mode_of_travel/baseline.svg'), {className: 'material'}],
["mode_standby", require('@material-icons/svg/svg/mode_standby/baseline.svg'), {className: 'material'}],
["model_training", require('@material-icons/svg/svg/model_training/baseline.svg'), {className: 'material'}],
["monetization_on", require('@material-icons/svg/svg/monetization_on/baseline.svg'), {className: 'material'}],
["money", require('@material-icons/svg/svg/money/baseline.svg'), {className: 'material'}],
["money_off", require('@material-icons/svg/svg/money_off/baseline.svg'), {className: 'material'}],
["money_off_csred", require('@material-icons/svg/svg/money_off_csred/baseline.svg'), {className: 'material'}],
["monitor", require('@material-icons/svg/svg/monitor/baseline.svg'), {className: 'material'}],
["monitor_heart", require('@material-icons/svg/svg/monitor_heart/baseline.svg'), {className: 'material'}],
["monitor_weight", require('@material-icons/svg/svg/monitor_weight/baseline.svg'), {className: 'material'}],
["monochrome_photos", require('@material-icons/svg/svg/monochrome_photos/baseline.svg'), {className: 'material'}],
["mood", require('@material-icons/svg/svg/mood/baseline.svg'), {className: 'material'}],
["mood_bad", require('@material-icons/svg/svg/mood_bad/baseline.svg'), {className: 'material'}],
["moped", require('@material-icons/svg/svg/moped/baseline.svg'), {className: 'material'}],
["more", require('@material-icons/svg/svg/more/baseline.svg'), {className: 'material'}],
["more_horiz", require('@material-icons/svg/svg/more_horiz/baseline.svg'), {className: 'material'}],
["more_time", require('@material-icons/svg/svg/more_time/baseline.svg'), {className: 'material'}],
["more_vert", require('@material-icons/svg/svg/more_vert/baseline.svg'), {className: 'material'}],
["mosque", require('@material-icons/svg/svg/mosque/baseline.svg'), {className: 'material'}],
["motion_photos_auto", require('@material-icons/svg/svg/motion_photos_auto/baseline.svg'), {className: 'material'}],
["motion_photos_off", require('@material-icons/svg/svg/motion_photos_off/baseline.svg'), {className: 'material'}],
["motion_photos_on", require('@material-icons/svg/svg/motion_photos_on/baseline.svg'), {className: 'material'}],
["motion_photos_pause", require('@material-icons/svg/svg/motion_photos_pause/baseline.svg'), {className: 'material'}],
["motion_photos_paused", require('@material-icons/svg/svg/motion_photos_paused/baseline.svg'), {className: 'material'}],
["motorcycle", require('@material-icons/svg/svg/motorcycle/baseline.svg'), {className: 'material'}],
["mouse", require('@material-icons/svg/svg/mouse/baseline.svg'), {className: 'material'}],
["move_down", require('@material-icons/svg/svg/move_down/baseline.svg'), {className: 'material'}],
["move_to_inbox", require('@material-icons/svg/svg/move_to_inbox/baseline.svg'), {className: 'material'}],
["move_up", require('@material-icons/svg/svg/move_up/baseline.svg'), {className: 'material'}],
["movie", require('@material-icons/svg/svg/movie/baseline.svg'), {className: 'material'}],
["movie_creation", require('@material-icons/svg/svg/movie_creation/baseline.svg'), {className: 'material'}],
["movie_filter", require('@material-icons/svg/svg/movie_filter/baseline.svg'), {className: 'material'}],
["moving", require('@material-icons/svg/svg/moving/baseline.svg'), {className: 'material'}],
["mp", require('@material-icons/svg/svg/mp/baseline.svg'), {className: 'material'}],
["multiline_chart", require('@material-icons/svg/svg/multiline_chart/baseline.svg'), {className: 'material'}],
["multiple_stop", require('@material-icons/svg/svg/multiple_stop/baseline.svg'), {className: 'material'}],
["museum", require('@material-icons/svg/svg/museum/baseline.svg'), {className: 'material'}],
["music_note", require('@material-icons/svg/svg/music_note/baseline.svg'), {className: 'material'}],
["music_off", require('@material-icons/svg/svg/music_off/baseline.svg'), {className: 'material'}],
["music_video", require('@material-icons/svg/svg/music_video/baseline.svg'), {className: 'material'}],
["my_location", require('@material-icons/svg/svg/my_location/baseline.svg'), {className: 'material'}],
["nat", require('@material-icons/svg/svg/nat/baseline.svg'), {className: 'material'}],
["nature", require('@material-icons/svg/svg/nature/baseline.svg'), {className: 'material'}],
["nature_people", require('@material-icons/svg/svg/nature_people/baseline.svg'), {className: 'material'}],
["navigate_before", require('@material-icons/svg/svg/navigate_before/baseline.svg'), {className: 'material'}],
["navigate_next", require('@material-icons/svg/svg/navigate_next/baseline.svg'), {className: 'material'}],
["navigation", require('@material-icons/svg/svg/navigation/baseline.svg'), {className: 'material'}],
["near_me", require('@material-icons/svg/svg/near_me/baseline.svg'), {className: 'material'}],
["near_me_disabled", require('@material-icons/svg/svg/near_me_disabled/baseline.svg'), {className: 'material'}],
["nearby_error", require('@material-icons/svg/svg/nearby_error/baseline.svg'), {className: 'material'}],
["nearby_off", require('@material-icons/svg/svg/nearby_off/baseline.svg'), {className: 'material'}],
["network_cell", require('@material-icons/svg/svg/network_cell/baseline.svg'), {className: 'material'}],
["network_check", require('@material-icons/svg/svg/network_check/baseline.svg'), {className: 'material'}],
["network_locked", require('@material-icons/svg/svg/network_locked/baseline.svg'), {className: 'material'}],
["network_ping", require('@material-icons/svg/svg/network_ping/baseline.svg'), {className: 'material'}],
["network_wifi", require('@material-icons/svg/svg/network_wifi/baseline.svg'), {className: 'material'}],
["new_label", require('@material-icons/svg/svg/new_label/baseline.svg'), {className: 'material'}],
["new_releases", require('@material-icons/svg/svg/new_releases/baseline.svg'), {className: 'material'}],
["newspaper", require('@material-icons/svg/svg/newspaper/baseline.svg'), {className: 'material'}],
["next_plan", require('@material-icons/svg/svg/next_plan/baseline.svg'), {className: 'material'}],
["next_week", require('@material-icons/svg/svg/next_week/baseline.svg'), {className: 'material'}],
["nfc", require('@material-icons/svg/svg/nfc/baseline.svg'), {className: 'material'}],
["night_shelter", require('@material-icons/svg/svg/night_shelter/baseline.svg'), {className: 'material'}],
["nightlife", require('@material-icons/svg/svg/nightlife/baseline.svg'), {className: 'material'}],
["nightlight", require('@material-icons/svg/svg/nightlight/baseline.svg'), {className: 'material'}],
["nightlight_round", require('@material-icons/svg/svg/nightlight_round/baseline.svg'), {className: 'material'}],
["nights_stay", require('@material-icons/svg/svg/nights_stay/baseline.svg'), {className: 'material'}],
["no_accounts", require('@material-icons/svg/svg/no_accounts/baseline.svg'), {className: 'material'}],
["no_backpack", require('@material-icons/svg/svg/no_backpack/baseline.svg'), {className: 'material'}],
["no_cell", require('@material-icons/svg/svg/no_cell/baseline.svg'), {className: 'material'}],
["no_drinks", require('@material-icons/svg/svg/no_drinks/baseline.svg'), {className: 'material'}],
["no_encryption", require('@material-icons/svg/svg/no_encryption/baseline.svg'), {className: 'material'}],
["no_encryption_gmailerrorred", require('@material-icons/svg/svg/no_encryption_gmailerrorred/baseline.svg'), {className: 'material'}],
["no_flash", require('@material-icons/svg/svg/no_flash/baseline.svg'), {className: 'material'}],
["no_food", require('@material-icons/svg/svg/no_food/baseline.svg'), {className: 'material'}],
["no_luggage", require('@material-icons/svg/svg/no_luggage/baseline.svg'), {className: 'material'}],
["no_meals", require('@material-icons/svg/svg/no_meals/baseline.svg'), {className: 'material'}],
["no_meeting_room", require('@material-icons/svg/svg/no_meeting_room/baseline.svg'), {className: 'material'}],
["no_photography", require('@material-icons/svg/svg/no_photography/baseline.svg'), {className: 'material'}],
["no_sim", require('@material-icons/svg/svg/no_sim/baseline.svg'), {className: 'material'}],
["no_stroller", require('@material-icons/svg/svg/no_stroller/baseline.svg'), {className: 'material'}],
["no_transfer", require('@material-icons/svg/svg/no_transfer/baseline.svg'), {className: 'material'}],
["nordic_walking", require('@material-icons/svg/svg/nordic_walking/baseline.svg'), {className: 'material'}],
["north", require('@material-icons/svg/svg/north/baseline.svg'), {className: 'material'}],
["north_east", require('@material-icons/svg/svg/north_east/baseline.svg'), {className: 'material'}],
["north_west", require('@material-icons/svg/svg/north_west/baseline.svg'), {className: 'material'}],
["not_accessible", require('@material-icons/svg/svg/not_accessible/baseline.svg'), {className: 'material'}],
["not_interested", require('@material-icons/svg/svg/not_interested/baseline.svg'), {className: 'material'}],
["not_listed_location", require('@material-icons/svg/svg/not_listed_location/baseline.svg'), {className: 'material'}],
["not_started", require('@material-icons/svg/svg/not_started/baseline.svg'), {className: 'material'}],
["note", require('@material-icons/svg/svg/note/baseline.svg'), {className: 'material'}],
["note_add", require('@material-icons/svg/svg/note_add/baseline.svg'), {className: 'material'}],
["note_alt", require('@material-icons/svg/svg/note_alt/baseline.svg'), {className: 'material'}],
["notes", require('@material-icons/svg/svg/notes/baseline.svg'), {className: 'material'}],
["notification_add", require('@material-icons/svg/svg/notification_add/baseline.svg'), {className: 'material'}],
["notification_important", require('@material-icons/svg/svg/notification_important/baseline.svg'), {className: 'material'}],
["notifications", require('@material-icons/svg/svg/notifications/baseline.svg'), {className: 'material'}],
["notifications_active", require('@material-icons/svg/svg/notifications_active/baseline.svg'), {className: 'material'}],
["notifications_none", require('@material-icons/svg/svg/notifications_none/baseline.svg'), {className: 'material'}],
["notifications_off", require('@material-icons/svg/svg/notifications_off/baseline.svg'), {className: 'material'}],
["notifications_paused", require('@material-icons/svg/svg/notifications_paused/baseline.svg'), {className: 'material'}],
["numbers", require('@material-icons/svg/svg/numbers/baseline.svg'), {className: 'material'}],
["offline_bolt", require('@material-icons/svg/svg/offline_bolt/baseline.svg'), {className: 'material'}],
["offline_pin", require('@material-icons/svg/svg/offline_pin/baseline.svg'), {className: 'material'}],
["offline_share", require('@material-icons/svg/svg/offline_share/baseline.svg'), {className: 'material'}],
["ondemand_video", require('@material-icons/svg/svg/ondemand_video/baseline.svg'), {className: 'material'}],
["online_prediction", require('@material-icons/svg/svg/online_prediction/baseline.svg'), {className: 'material'}],
["opacity", require('@material-icons/svg/svg/opacity/baseline.svg'), {className: 'material'}],
["open_in_browser", require('@material-icons/svg/svg/open_in_browser/baseline.svg'), {className: 'material'}],
["open_in_full", require('@material-icons/svg/svg/open_in_full/baseline.svg'), {className: 'material'}],
["open_in_new", require('@material-icons/svg/svg/open_in_new/baseline.svg'), {className: 'material'}],
["open_in_new_off", require('@material-icons/svg/svg/open_in_new_off/baseline.svg'), {className: 'material'}],
["open_with", require('@material-icons/svg/svg/open_with/baseline.svg'), {className: 'material'}],
["other_houses", require('@material-icons/svg/svg/other_houses/baseline.svg'), {className: 'material'}],
["outbond", require('@material-icons/svg/svg/outbond/baseline.svg'), {className: 'material'}],
["outbound", require('@material-icons/svg/svg/outbound/baseline.svg'), {className: 'material'}],
["outbox", require('@material-icons/svg/svg/outbox/baseline.svg'), {className: 'material'}],
["outdoor_grill", require('@material-icons/svg/svg/outdoor_grill/baseline.svg'), {className: 'material'}],
["outlet", require('@material-icons/svg/svg/outlet/baseline.svg'), {className: 'material'}],
["outlined_flag", require('@material-icons/svg/svg/outlined_flag/baseline.svg'), {className: 'material'}],
["output", require('@material-icons/svg/svg/output/baseline.svg'), {className: 'material'}],
["padding", require('@material-icons/svg/svg/padding/baseline.svg'), {className: 'material'}],
["pages", require('@material-icons/svg/svg/pages/baseline.svg'), {className: 'material'}],
["pageview", require('@material-icons/svg/svg/pageview/baseline.svg'), {className: 'material'}],
["paid", require('@material-icons/svg/svg/paid/baseline.svg'), {className: 'material'}],
["palette", require('@material-icons/svg/svg/palette/baseline.svg'), {className: 'material'}],
["pan_tool", require('@material-icons/svg/svg/pan_tool/baseline.svg'), {className: 'material'}],
["pan_tool_alt", require('@material-icons/svg/svg/pan_tool_alt/baseline.svg'), {className: 'material'}],
["panorama", require('@material-icons/svg/svg/panorama/baseline.svg'), {className: 'material'}],
["panorama_fish_eye", require('@material-icons/svg/svg/panorama_fish_eye/baseline.svg'), {className: 'material'}],
["panorama_horizontal", require('@material-icons/svg/svg/panorama_horizontal/baseline.svg'), {className: 'material'}],
["panorama_horizontal_select", require('@material-icons/svg/svg/panorama_horizontal_select/baseline.svg'), {className: 'material'}],
["panorama_photosphere", require('@material-icons/svg/svg/panorama_photosphere/baseline.svg'), {className: 'material'}],
["panorama_photosphere_select", require('@material-icons/svg/svg/panorama_photosphere_select/baseline.svg'), {className: 'material'}],
["panorama_vertical", require('@material-icons/svg/svg/panorama_vertical/baseline.svg'), {className: 'material'}],
["panorama_vertical_select", require('@material-icons/svg/svg/panorama_vertical_select/baseline.svg'), {className: 'material'}],
["panorama_wide_angle", require('@material-icons/svg/svg/panorama_wide_angle/baseline.svg'), {className: 'material'}],
["panorama_wide_angle_select", require('@material-icons/svg/svg/panorama_wide_angle_select/baseline.svg'), {className: 'material'}],
["paragliding", require('@material-icons/svg/svg/paragliding/baseline.svg'), {className: 'material'}],
["park", require('@material-icons/svg/svg/park/baseline.svg'), {className: 'material'}],
["party_mode", require('@material-icons/svg/svg/party_mode/baseline.svg'), {className: 'material'}],
["password", require('@material-icons/svg/svg/password/baseline.svg'), {className: 'material'}],
["pattern", require('@material-icons/svg/svg/pattern/baseline.svg'), {className: 'material'}],
["pause", require('@material-icons/svg/svg/pause/baseline.svg'), {className: 'material'}],
["pause_circle", require('@material-icons/svg/svg/pause_circle/baseline.svg'), {className: 'material'}],
["pause_circle_filled", require('@material-icons/svg/svg/pause_circle_filled/baseline.svg'), {className: 'material'}],
["pause_circle_outline", require('@material-icons/svg/svg/pause_circle_outline/baseline.svg'), {className: 'material'}],
["pause_presentation", require('@material-icons/svg/svg/pause_presentation/baseline.svg'), {className: 'material'}],
["payment", require('@material-icons/svg/svg/payment/baseline.svg'), {className: 'material'}],
["payments", require('@material-icons/svg/svg/payments/baseline.svg'), {className: 'material'}],
["paypal", require('@material-icons/svg/svg/paypal/baseline.svg'), {className: 'material'}],
["pedal_bike", require('@material-icons/svg/svg/pedal_bike/baseline.svg'), {className: 'material'}],
["pending", require('@material-icons/svg/svg/pending/baseline.svg'), {className: 'material'}],
["pending_actions", require('@material-icons/svg/svg/pending_actions/baseline.svg'), {className: 'material'}],
["pentagon", require('@material-icons/svg/svg/pentagon/baseline.svg'), {className: 'material'}],
["people", require('@material-icons/svg/svg/people/baseline.svg'), {className: 'material'}],
["people_alt", require('@material-icons/svg/svg/people_alt/baseline.svg'), {className: 'material'}],
["people_outline", require('@material-icons/svg/svg/people_outline/baseline.svg'), {className: 'material'}],
["percent", require('@material-icons/svg/svg/percent/baseline.svg'), {className: 'material'}],
["perm_camera_mic", require('@material-icons/svg/svg/perm_camera_mic/baseline.svg'), {className: 'material'}],
["perm_contact_calendar", require('@material-icons/svg/svg/perm_contact_calendar/baseline.svg'), {className: 'material'}],
["perm_data_setting", require('@material-icons/svg/svg/perm_data_setting/baseline.svg'), {className: 'material'}],
["perm_device_information", require('@material-icons/svg/svg/perm_device_information/baseline.svg'), {className: 'material'}],
["perm_identity", require('@material-icons/svg/svg/perm_identity/baseline.svg'), {className: 'material'}],
["perm_media", require('@material-icons/svg/svg/perm_media/baseline.svg'), {className: 'material'}],
["perm_phone_msg", require('@material-icons/svg/svg/perm_phone_msg/baseline.svg'), {className: 'material'}],
["perm_scan_wifi", require('@material-icons/svg/svg/perm_scan_wifi/baseline.svg'), {className: 'material'}],
["person", require('@material-icons/svg/svg/person/baseline.svg'), {className: 'material'}],
["person_add", require('@material-icons/svg/svg/person_add/baseline.svg'), {className: 'material'}],
["person_add_alt", require('@material-icons/svg/svg/person_add_alt/baseline.svg'), {className: 'material'}],
["person_add_alt_1", require('@material-icons/svg/svg/person_add_alt_1/baseline.svg'), {className: 'material'}],
["person_add_disabled", require('@material-icons/svg/svg/person_add_disabled/baseline.svg'), {className: 'material'}],
["person_off", require('@material-icons/svg/svg/person_off/baseline.svg'), {className: 'material'}],
["person_outline", require('@material-icons/svg/svg/person_outline/baseline.svg'), {className: 'material'}],
["person_pin", require('@material-icons/svg/svg/person_pin/baseline.svg'), {className: 'material'}],
["person_pin_circle", require('@material-icons/svg/svg/person_pin_circle/baseline.svg'), {className: 'material'}],
["person_remove", require('@material-icons/svg/svg/person_remove/baseline.svg'), {className: 'material'}],
["person_remove_alt_1", require('@material-icons/svg/svg/person_remove_alt_1/baseline.svg'), {className: 'material'}],
["person_search", require('@material-icons/svg/svg/person_search/baseline.svg'), {className: 'material'}],
["personal_injury", require('@material-icons/svg/svg/personal_injury/baseline.svg'), {className: 'material'}],
["personal_video", require('@material-icons/svg/svg/personal_video/baseline.svg'), {className: 'material'}],
["pest_control", require('@material-icons/svg/svg/pest_control/baseline.svg'), {className: 'material'}],
["pest_control_rodent", require('@material-icons/svg/svg/pest_control_rodent/baseline.svg'), {className: 'material'}],
["pets", require('@material-icons/svg/svg/pets/baseline.svg'), {className: 'material'}],
["phishing", require('@material-icons/svg/svg/phishing/baseline.svg'), {className: 'material'}],
["phone", require('@material-icons/svg/svg/phone/baseline.svg'), {className: 'material'}],
["phone_android", require('@material-icons/svg/svg/phone_android/baseline.svg'), {className: 'material'}],
["phone_bluetooth_speaker", require('@material-icons/svg/svg/phone_bluetooth_speaker/baseline.svg'), {className: 'material'}],
["phone_callback", require('@material-icons/svg/svg/phone_callback/baseline.svg'), {className: 'material'}],
["phone_disabled", require('@material-icons/svg/svg/phone_disabled/baseline.svg'), {className: 'material'}],
["phone_enabled", require('@material-icons/svg/svg/phone_enabled/baseline.svg'), {className: 'material'}],
["phone_forwarded", require('@material-icons/svg/svg/phone_forwarded/baseline.svg'), {className: 'material'}],
["phone_in_talk", require('@material-icons/svg/svg/phone_in_talk/baseline.svg'), {className: 'material'}],
["phone_iphone", require('@material-icons/svg/svg/phone_iphone/baseline.svg'), {className: 'material'}],
["phone_locked", require('@material-icons/svg/svg/phone_locked/baseline.svg'), {className: 'material'}],
["phone_missed", require('@material-icons/svg/svg/phone_missed/baseline.svg'), {className: 'material'}],
["phone_paused", require('@material-icons/svg/svg/phone_paused/baseline.svg'), {className: 'material'}],
["phonelink", require('@material-icons/svg/svg/phonelink/baseline.svg'), {className: 'material'}],
["phonelink_erase", require('@material-icons/svg/svg/phonelink_erase/baseline.svg'), {className: 'material'}],
["phonelink_lock", require('@material-icons/svg/svg/phonelink_lock/baseline.svg'), {className: 'material'}],
["phonelink_off", require('@material-icons/svg/svg/phonelink_off/baseline.svg'), {className: 'material'}],
["phonelink_ring", require('@material-icons/svg/svg/phonelink_ring/baseline.svg'), {className: 'material'}],
["phonelink_setup", require('@material-icons/svg/svg/phonelink_setup/baseline.svg'), {className: 'material'}],
["photo", require('@material-icons/svg/svg/photo/baseline.svg'), {className: 'material'}],
["photo_album", require('@material-icons/svg/svg/photo_album/baseline.svg'), {className: 'material'}],
["photo_camera", require('@material-icons/svg/svg/photo_camera/baseline.svg'), {className: 'material'}],
["photo_camera_back", require('@material-icons/svg/svg/photo_camera_back/baseline.svg'), {className: 'material'}],
["photo_camera_front", require('@material-icons/svg/svg/photo_camera_front/baseline.svg'), {className: 'material'}],
["photo_filter", require('@material-icons/svg/svg/photo_filter/baseline.svg'), {className: 'material'}],
["photo_library", require('@material-icons/svg/svg/photo_library/baseline.svg'), {className: 'material'}],
["photo_size_select_actual", require('@material-icons/svg/svg/photo_size_select_actual/baseline.svg'), {className: 'material'}],
["photo_size_select_large", require('@material-icons/svg/svg/photo_size_select_large/baseline.svg'), {className: 'material'}],
["photo_size_select_small", require('@material-icons/svg/svg/photo_size_select_small/baseline.svg'), {className: 'material'}],
["php", require('@material-icons/svg/svg/php/baseline.svg'), {className: 'material'}],
["piano", require('@material-icons/svg/svg/piano/baseline.svg'), {className: 'material'}],
["piano_off", require('@material-icons/svg/svg/piano_off/baseline.svg'), {className: 'material'}],
["picture_as_pdf", require('@material-icons/svg/svg/picture_as_pdf/baseline.svg'), {className: 'material'}],
["picture_in_picture", require('@material-icons/svg/svg/picture_in_picture/baseline.svg'), {className: 'material'}],
["picture_in_picture_alt", require('@material-icons/svg/svg/picture_in_picture_alt/baseline.svg'), {className: 'material'}],
["pie_chart", require('@material-icons/svg/svg/pie_chart/baseline.svg'), {className: 'material'}],
["pie_chart_outline", require('@material-icons/svg/svg/pie_chart_outline/baseline.svg'), {className: 'material'}],
["pin", require('@material-icons/svg/svg/pin/baseline.svg'), {className: 'material'}],
["pin_drop", require('@material-icons/svg/svg/pin_drop/baseline.svg'), {className: 'material'}],
["pin_end", require('@material-icons/svg/svg/pin_end/baseline.svg'), {className: 'material'}],
["pin_invoke", require('@material-icons/svg/svg/pin_invoke/baseline.svg'), {className: 'material'}],
["pinch", require('@material-icons/svg/svg/pinch/baseline.svg'), {className: 'material'}],
["pivot_table_chart", require('@material-icons/svg/svg/pivot_table_chart/baseline.svg'), {className: 'material'}],
["pix", require('@material-icons/svg/svg/pix/baseline.svg'), {className: 'material'}],
["place", require('@material-icons/svg/svg/place/baseline.svg'), {className: 'material'}],
["plagiarism", require('@material-icons/svg/svg/plagiarism/baseline.svg'), {className: 'material'}],
["play_arrow", require('@material-icons/svg/svg/play_arrow/baseline.svg'), {className: 'material'}],
["play_circle", require('@material-icons/svg/svg/play_circle/baseline.svg'), {className: 'material'}],
["play_circle_filled", require('@material-icons/svg/svg/play_circle_filled/baseline.svg'), {className: 'material'}],
["play_circle_filled_white", require('@material-icons/svg/svg/play_circle_filled_white/baseline.svg'), {className: 'material'}],
["play_circle_outline", require('@material-icons/svg/svg/play_circle_outline/baseline.svg'), {className: 'material'}],
["play_disabled", require('@material-icons/svg/svg/play_disabled/baseline.svg'), {className: 'material'}],
["play_for_work", require('@material-icons/svg/svg/play_for_work/baseline.svg'), {className: 'material'}],
["play_lesson", require('@material-icons/svg/svg/play_lesson/baseline.svg'), {className: 'material'}],
["playlist_add", require('@material-icons/svg/svg/playlist_add/baseline.svg'), {className: 'material'}],
["playlist_add_check", require('@material-icons/svg/svg/playlist_add_check/baseline.svg'), {className: 'material'}],
["playlist_add_check_circle", require('@material-icons/svg/svg/playlist_add_check_circle/baseline.svg'), {className: 'material'}],
["playlist_add_circle", require('@material-icons/svg/svg/playlist_add_circle/baseline.svg'), {className: 'material'}],
["playlist_play", require('@material-icons/svg/svg/playlist_play/baseline.svg'), {className: 'material'}],
["playlist_remove", require('@material-icons/svg/svg/playlist_remove/baseline.svg'), {className: 'material'}],
["plumbing", require('@material-icons/svg/svg/plumbing/baseline.svg'), {className: 'material'}],
["plus_one", require('@material-icons/svg/svg/plus_one/baseline.svg'), {className: 'material'}],
["podcasts", require('@material-icons/svg/svg/podcasts/baseline.svg'), {className: 'material'}],
["point_of_sale", require('@material-icons/svg/svg/point_of_sale/baseline.svg'), {className: 'material'}],
["policy", require('@material-icons/svg/svg/policy/baseline.svg'), {className: 'material'}],
["poll", require('@material-icons/svg/svg/poll/baseline.svg'), {className: 'material'}],
["polyline", require('@material-icons/svg/svg/polyline/baseline.svg'), {className: 'material'}],
["polymer", require('@material-icons/svg/svg/polymer/baseline.svg'), {className: 'material'}],
["pool", require('@material-icons/svg/svg/pool/baseline.svg'), {className: 'material'}],
["portable_wifi_off", require('@material-icons/svg/svg/portable_wifi_off/baseline.svg'), {className: 'material'}],
["portrait", require('@material-icons/svg/svg/portrait/baseline.svg'), {className: 'material'}],
["post_add", require('@material-icons/svg/svg/post_add/baseline.svg'), {className: 'material'}],
["power", require('@material-icons/svg/svg/power/baseline.svg'), {className: 'material'}],
["power_input", require('@material-icons/svg/svg/power_input/baseline.svg'), {className: 'material'}],
["power_off", require('@material-icons/svg/svg/power_off/baseline.svg'), {className: 'material'}],
["power_settings_new", require('@material-icons/svg/svg/power_settings_new/baseline.svg'), {className: 'material'}],
["precision_manufacturing", require('@material-icons/svg/svg/precision_manufacturing/baseline.svg'), {className: 'material'}],
["pregnant_woman", require('@material-icons/svg/svg/pregnant_woman/baseline.svg'), {className: 'material'}],
["present_to_all", require('@material-icons/svg/svg/present_to_all/baseline.svg'), {className: 'material'}],
["preview", require('@material-icons/svg/svg/preview/baseline.svg'), {className: 'material'}],
["price_change", require('@material-icons/svg/svg/price_change/baseline.svg'), {className: 'material'}],
["price_check", require('@material-icons/svg/svg/price_check/baseline.svg'), {className: 'material'}],
["print", require('@material-icons/svg/svg/print/baseline.svg'), {className: 'material'}],
["print_disabled", require('@material-icons/svg/svg/print_disabled/baseline.svg'), {className: 'material'}],
["priority_high", require('@material-icons/svg/svg/priority_high/baseline.svg'), {className: 'material'}],
["privacy_tip", require('@material-icons/svg/svg/privacy_tip/baseline.svg'), {className: 'material'}],
["private_connectivity", require('@material-icons/svg/svg/private_connectivity/baseline.svg'), {className: 'material'}],
["production_quantity_limits", require('@material-icons/svg/svg/production_quantity_limits/baseline.svg'), {className: 'material'}],
["psychology", require('@material-icons/svg/svg/psychology/baseline.svg'), {className: 'material'}],
["public", require('@material-icons/svg/svg/public/baseline.svg'), {className: 'material'}],
["public_off", require('@material-icons/svg/svg/public_off/baseline.svg'), {className: 'material'}],
["publish", require('@material-icons/svg/svg/publish/baseline.svg'), {className: 'material'}],
["published_with_changes", require('@material-icons/svg/svg/published_with_changes/baseline.svg'), {className: 'material'}],
["punch_clock", require('@material-icons/svg/svg/punch_clock/baseline.svg'), {className: 'material'}],
["push_pin", require('@material-icons/svg/svg/push_pin/baseline.svg'), {className: 'material'}],
["qr_code", require('@material-icons/svg/svg/qr_code/baseline.svg'), {className: 'material'}],
["qr_code_2", require('@material-icons/svg/svg/qr_code_2/baseline.svg'), {className: 'material'}],
["qr_code_scanner", require('@material-icons/svg/svg/qr_code_scanner/baseline.svg'), {className: 'material'}],
["query_builder", require('@material-icons/svg/svg/query_builder/baseline.svg'), {className: 'material'}],
["query_stats", require('@material-icons/svg/svg/query_stats/baseline.svg'), {className: 'material'}],
["question_answer", require('@material-icons/svg/svg/question_answer/baseline.svg'), {className: 'material'}],
["question_mark", require('@material-icons/svg/svg/question_mark/baseline.svg'), {className: 'material'}],
["queue", require('@material-icons/svg/svg/queue/baseline.svg'), {className: 'material'}],
["queue_music", require('@material-icons/svg/svg/queue_music/baseline.svg'), {className: 'material'}],
["queue_play_next", require('@material-icons/svg/svg/queue_play_next/baseline.svg'), {className: 'material'}],
["quickreply", require('@material-icons/svg/svg/quickreply/baseline.svg'), {className: 'material'}],
["quiz", require('@material-icons/svg/svg/quiz/baseline.svg'), {className: 'material'}],
["quora", require('@material-icons/svg/svg/quora/baseline.svg'), {className: 'material'}],
["r_mobiledata", require('@material-icons/svg/svg/r_mobiledata/baseline.svg'), {className: 'material'}],
["radar", require('@material-icons/svg/svg/radar/baseline.svg'), {className: 'material'}],
["radio", require('@material-icons/svg/svg/radio/baseline.svg'), {className: 'material'}],
["radio_button_checked", require('@material-icons/svg/svg/radio_button_checked/baseline.svg'), {className: 'material'}],
["radio_button_unchecked", require('@material-icons/svg/svg/radio_button_unchecked/baseline.svg'), {className: 'material'}],
["railway_alert", require('@material-icons/svg/svg/railway_alert/baseline.svg'), {className: 'material'}],
["ramen_dining", require('@material-icons/svg/svg/ramen_dining/baseline.svg'), {className: 'material'}],
["ramp_left", require('@material-icons/svg/svg/ramp_left/baseline.svg'), {className: 'material'}],
["ramp_right", require('@material-icons/svg/svg/ramp_right/baseline.svg'), {className: 'material'}],
["rate_review", require('@material-icons/svg/svg/rate_review/baseline.svg'), {className: 'material'}],
["raw_off", require('@material-icons/svg/svg/raw_off/baseline.svg'), {className: 'material'}],
["raw_on", require('@material-icons/svg/svg/raw_on/baseline.svg'), {className: 'material'}],
["read_more", require('@material-icons/svg/svg/read_more/baseline.svg'), {className: 'material'}],
["real_estate_agent", require('@material-icons/svg/svg/real_estate_agent/baseline.svg'), {className: 'material'}],
["receipt", require('@material-icons/svg/svg/receipt/baseline.svg'), {className: 'material'}],
["receipt_long", require('@material-icons/svg/svg/receipt_long/baseline.svg'), {className: 'material'}],
["recent_actors", require('@material-icons/svg/svg/recent_actors/baseline.svg'), {className: 'material'}],
["recommend", require('@material-icons/svg/svg/recommend/baseline.svg'), {className: 'material'}],
["record_voice_over", require('@material-icons/svg/svg/record_voice_over/baseline.svg'), {className: 'material'}],
["rectangle", require('@material-icons/svg/svg/rectangle/baseline.svg'), {className: 'material'}],
["recycling", require('@material-icons/svg/svg/recycling/baseline.svg'), {className: 'material'}],
["reddit", require('@material-icons/svg/svg/reddit/baseline.svg'), {className: 'material'}],
["redeem", require('@material-icons/svg/svg/redeem/baseline.svg'), {className: 'material'}],
["redo", require('@material-icons/svg/svg/redo/baseline.svg'), {className: 'material'}],
["reduce_capacity", require('@material-icons/svg/svg/reduce_capacity/baseline.svg'), {className: 'material'}],
["refresh", require('@material-icons/svg/svg/refresh/baseline.svg'), {className: 'material'}],
["remember_me", require('@material-icons/svg/svg/remember_me/baseline.svg'), {className: 'material'}],
["remove", require('@material-icons/svg/svg/remove/baseline.svg'), {className: 'material'}],
["remove_circle", require('@material-icons/svg/svg/remove_circle/baseline.svg'), {className: 'material'}],
["remove_circle_outline", require('@material-icons/svg/svg/remove_circle_outline/baseline.svg'), {className: 'material'}],
["remove_done", require('@material-icons/svg/svg/remove_done/baseline.svg'), {className: 'material'}],
["remove_from_queue", require('@material-icons/svg/svg/remove_from_queue/baseline.svg'), {className: 'material'}],
["remove_moderator", require('@material-icons/svg/svg/remove_moderator/baseline.svg'), {className: 'material'}],
["remove_red_eye", require('@material-icons/svg/svg/remove_red_eye/baseline.svg'), {className: 'material'}],
["remove_shopping_cart", require('@material-icons/svg/svg/remove_shopping_cart/baseline.svg'), {className: 'material'}],
["reorder", require('@material-icons/svg/svg/reorder/baseline.svg'), {className: 'material'}],
["repeat", require('@material-icons/svg/svg/repeat/baseline.svg'), {className: 'material'}],
["repeat_on", require('@material-icons/svg/svg/repeat_on/baseline.svg'), {className: 'material'}],
["repeat_one", require('@material-icons/svg/svg/repeat_one/baseline.svg'), {className: 'material'}],
["repeat_one_on", require('@material-icons/svg/svg/repeat_one_on/baseline.svg'), {className: 'material'}],
["replay", require('@material-icons/svg/svg/replay/baseline.svg'), {className: 'material'}],
["replay_10", require('@material-icons/svg/svg/replay_10/baseline.svg'), {className: 'material'}],
["replay_30", require('@material-icons/svg/svg/replay_30/baseline.svg'), {className: 'material'}],
["replay_5", require('@material-icons/svg/svg/replay_5/baseline.svg'), {className: 'material'}],
["replay_circle_filled", require('@material-icons/svg/svg/replay_circle_filled/baseline.svg'), {className: 'material'}],
["reply", require('@material-icons/svg/svg/reply/baseline.svg'), {className: 'material'}],
["reply_all", require('@material-icons/svg/svg/reply_all/baseline.svg'), {className: 'material'}],
["report", require('@material-icons/svg/svg/report/baseline.svg'), {className: 'material'}],
["report_gmailerrorred", require('@material-icons/svg/svg/report_gmailerrorred/baseline.svg'), {className: 'material'}],
["report_off", require('@material-icons/svg/svg/report_off/baseline.svg'), {className: 'material'}],
["report_problem", require('@material-icons/svg/svg/report_problem/baseline.svg'), {className: 'material'}],
["request_page", require('@material-icons/svg/svg/request_page/baseline.svg'), {className: 'material'}],
["request_quote", require('@material-icons/svg/svg/request_quote/baseline.svg'), {className: 'material'}],
["reset_tv", require('@material-icons/svg/svg/reset_tv/baseline.svg'), {className: 'material'}],
["restart_alt", require('@material-icons/svg/svg/restart_alt/baseline.svg'), {className: 'material'}],
["restaurant", require('@material-icons/svg/svg/restaurant/baseline.svg'), {className: 'material'}],
["restaurant_menu", require('@material-icons/svg/svg/restaurant_menu/baseline.svg'), {className: 'material'}],
["restore", require('@material-icons/svg/svg/restore/baseline.svg'), {className: 'material'}],
["restore_from_trash", require('@material-icons/svg/svg/restore_from_trash/baseline.svg'), {className: 'material'}],
["restore_page", require('@material-icons/svg/svg/restore_page/baseline.svg'), {className: 'material'}],
["reviews", require('@material-icons/svg/svg/reviews/baseline.svg'), {className: 'material'}],
["rice_bowl", require('@material-icons/svg/svg/rice_bowl/baseline.svg'), {className: 'material'}],
["ring_volume", require('@material-icons/svg/svg/ring_volume/baseline.svg'), {className: 'material'}],
["rocket", require('@material-icons/svg/svg/rocket/baseline.svg'), {className: 'material'}],
["rocket_launch", require('@material-icons/svg/svg/rocket_launch/baseline.svg'), {className: 'material'}],
["roller_skating", require('@material-icons/svg/svg/roller_skating/baseline.svg'), {className: 'material'}],
["roofing", require('@material-icons/svg/svg/roofing/baseline.svg'), {className: 'material'}],
["room", require('@material-icons/svg/svg/room/baseline.svg'), {className: 'material'}],
["room_preferences", require('@material-icons/svg/svg/room_preferences/baseline.svg'), {className: 'material'}],
["room_service", require('@material-icons/svg/svg/room_service/baseline.svg'), {className: 'material'}],
["rotate_90_degrees_ccw", require('@material-icons/svg/svg/rotate_90_degrees_ccw/baseline.svg'), {className: 'material'}],
["rotate_90_degrees_cw", require('@material-icons/svg/svg/rotate_90_degrees_cw/baseline.svg'), {className: 'material'}],
["rotate_left", require('@material-icons/svg/svg/rotate_left/baseline.svg'), {className: 'material'}],
["rotate_right", require('@material-icons/svg/svg/rotate_right/baseline.svg'), {className: 'material'}],
["roundabout_left", require('@material-icons/svg/svg/roundabout_left/baseline.svg'), {className: 'material'}],
["roundabout_right", require('@material-icons/svg/svg/roundabout_right/baseline.svg'), {className: 'material'}],
["rounded_corner", require('@material-icons/svg/svg/rounded_corner/baseline.svg'), {className: 'material'}],
["route", require('@material-icons/svg/svg/route/baseline.svg'), {className: 'material'}],
["router", require('@material-icons/svg/svg/router/baseline.svg'), {className: 'material'}],
["rowing", require('@material-icons/svg/svg/rowing/baseline.svg'), {className: 'material'}],
["rss_feed", require('@material-icons/svg/svg/rss_feed/baseline.svg'), {className: 'material'}],
["rsvp", require('@material-icons/svg/svg/rsvp/baseline.svg'), {className: 'material'}],
["rtt", require('@material-icons/svg/svg/rtt/baseline.svg'), {className: 'material'}],
["rule", require('@material-icons/svg/svg/rule/baseline.svg'), {className: 'material'}],
["rule_folder", require('@material-icons/svg/svg/rule_folder/baseline.svg'), {className: 'material'}],
["run_circle", require('@material-icons/svg/svg/run_circle/baseline.svg'), {className: 'material'}],
["running_with_errors", require('@material-icons/svg/svg/running_with_errors/baseline.svg'), {className: 'material'}],
["rv_hookup", require('@material-icons/svg/svg/rv_hookup/baseline.svg'), {className: 'material'}],
["safety_divider", require('@material-icons/svg/svg/safety_divider/baseline.svg'), {className: 'material'}],
["sailing", require('@material-icons/svg/svg/sailing/baseline.svg'), {className: 'material'}],
["sanitizer", require('@material-icons/svg/svg/sanitizer/baseline.svg'), {className: 'material'}],
["satellite", require('@material-icons/svg/svg/satellite/baseline.svg'), {className: 'material'}],
["satellite_alt", require('@material-icons/svg/svg/satellite_alt/baseline.svg'), {className: 'material'}],
["save", require('@material-icons/svg/svg/save/baseline.svg'), {className: 'material'}],
["save_alt", require('@material-icons/svg/svg/save_alt/baseline.svg'), {className: 'material'}],
["save_as", require('@material-icons/svg/svg/save_as/baseline.svg'), {className: 'material'}],
["saved_search", require('@material-icons/svg/svg/saved_search/baseline.svg'), {className: 'material'}],
["savings", require('@material-icons/svg/svg/savings/baseline.svg'), {className: 'material'}],
["scale", require('@material-icons/svg/svg/scale/baseline.svg'), {className: 'material'}],
["scanner", require('@material-icons/svg/svg/scanner/baseline.svg'), {className: 'material'}],
["scatter_plot", require('@material-icons/svg/svg/scatter_plot/baseline.svg'), {className: 'material'}],
["schedule", require('@material-icons/svg/svg/schedule/baseline.svg'), {className: 'material'}],
["schedule_send", require('@material-icons/svg/svg/schedule_send/baseline.svg'), {className: 'material'}],
["schema", require('@material-icons/svg/svg/schema/baseline.svg'), {className: 'material'}],
["school", require('@material-icons/svg/svg/school/baseline.svg'), {className: 'material'}],
["science", require('@material-icons/svg/svg/science/baseline.svg'), {className: 'material'}],
["score", require('@material-icons/svg/svg/score/baseline.svg'), {className: 'material'}],
["scoreboard", require('@material-icons/svg/svg/scoreboard/baseline.svg'), {className: 'material'}],
["screen_lock_landscape", require('@material-icons/svg/svg/screen_lock_landscape/baseline.svg'), {className: 'material'}],
["screen_lock_portrait", require('@material-icons/svg/svg/screen_lock_portrait/baseline.svg'), {className: 'material'}],
["screen_lock_rotation", require('@material-icons/svg/svg/screen_lock_rotation/baseline.svg'), {className: 'material'}],
["screen_rotation", require('@material-icons/svg/svg/screen_rotation/baseline.svg'), {className: 'material'}],
["screen_search_desktop", require('@material-icons/svg/svg/screen_search_desktop/baseline.svg'), {className: 'material'}],
["screen_share", require('@material-icons/svg/svg/screen_share/baseline.svg'), {className: 'material'}],
["screenshot", require('@material-icons/svg/svg/screenshot/baseline.svg'), {className: 'material'}],
["scuba_diving", require('@material-icons/svg/svg/scuba_diving/baseline.svg'), {className: 'material'}],
["sd", require('@material-icons/svg/svg/sd/baseline.svg'), {className: 'material'}],
["sd_card", require('@material-icons/svg/svg/sd_card/baseline.svg'), {className: 'material'}],
["sd_card_alert", require('@material-icons/svg/svg/sd_card_alert/baseline.svg'), {className: 'material'}],
["sd_storage", require('@material-icons/svg/svg/sd_storage/baseline.svg'), {className: 'material'}],
["search", require('@material-icons/svg/svg/search/baseline.svg'), {className: 'material'}],
["search_off", require('@material-icons/svg/svg/search_off/baseline.svg'), {className: 'material'}],
["security", require('@material-icons/svg/svg/security/baseline.svg'), {className: 'material'}],
["security_update", require('@material-icons/svg/svg/security_update/baseline.svg'), {className: 'material'}],
["security_update_good", require('@material-icons/svg/svg/security_update_good/baseline.svg'), {className: 'material'}],
["security_update_warning", require('@material-icons/svg/svg/security_update_warning/baseline.svg'), {className: 'material'}],
["segment", require('@material-icons/svg/svg/segment/baseline.svg'), {className: 'material'}],
["select_all", require('@material-icons/svg/svg/select_all/baseline.svg'), {className: 'material'}],
["self_improvement", require('@material-icons/svg/svg/self_improvement/baseline.svg'), {className: 'material'}],
["sell", require('@material-icons/svg/svg/sell/baseline.svg'), {className: 'material'}],
["send", require('@material-icons/svg/svg/send/baseline.svg'), {className: 'material'}],
["send_and_archive", require('@material-icons/svg/svg/send_and_archive/baseline.svg'), {className: 'material'}],
["send_time_extension", require('@material-icons/svg/svg/send_time_extension/baseline.svg'), {className: 'material'}],
["send_to_mobile", require('@material-icons/svg/svg/send_to_mobile/baseline.svg'), {className: 'material'}],
["sensor_door", require('@material-icons/svg/svg/sensor_door/baseline.svg'), {className: 'material'}],
["sensor_window", require('@material-icons/svg/svg/sensor_window/baseline.svg'), {className: 'material'}],
["sensors", require('@material-icons/svg/svg/sensors/baseline.svg'), {className: 'material'}],
["sensors_off", require('@material-icons/svg/svg/sensors_off/baseline.svg'), {className: 'material'}],
["sentiment_dissatisfied", require('@material-icons/svg/svg/sentiment_dissatisfied/baseline.svg'), {className: 'material'}],
["sentiment_neutral", require('@material-icons/svg/svg/sentiment_neutral/baseline.svg'), {className: 'material'}],
["sentiment_satisfied", require('@material-icons/svg/svg/sentiment_satisfied/baseline.svg'), {className: 'material'}],
["sentiment_satisfied_alt", require('@material-icons/svg/svg/sentiment_satisfied_alt/baseline.svg'), {className: 'material'}],
["sentiment_very_dissatisfied", require('@material-icons/svg/svg/sentiment_very_dissatisfied/baseline.svg'), {className: 'material'}],
["sentiment_very_satisfied", require('@material-icons/svg/svg/sentiment_very_satisfied/baseline.svg'), {className: 'material'}],
["set_meal", require('@material-icons/svg/svg/set_meal/baseline.svg'), {className: 'material'}],
["settings", require('@material-icons/svg/svg/settings/baseline.svg'), {className: 'material'}],
["settings_accessibility", require('@material-icons/svg/svg/settings_accessibility/baseline.svg'), {className: 'material'}],
["settings_applications", require('@material-icons/svg/svg/settings_applications/baseline.svg'), {className: 'material'}],
["settings_backup_restore", require('@material-icons/svg/svg/settings_backup_restore/baseline.svg'), {className: 'material'}],
["settings_bluetooth", require('@material-icons/svg/svg/settings_bluetooth/baseline.svg'), {className: 'material'}],
["settings_brightness", require('@material-icons/svg/svg/settings_brightness/baseline.svg'), {className: 'material'}],
["settings_cell", require('@material-icons/svg/svg/settings_cell/baseline.svg'), {className: 'material'}],
["settings_ethernet", require('@material-icons/svg/svg/settings_ethernet/baseline.svg'), {className: 'material'}],
["settings_input_antenna", require('@material-icons/svg/svg/settings_input_antenna/baseline.svg'), {className: 'material'}],
["settings_input_component", require('@material-icons/svg/svg/settings_input_component/baseline.svg'), {className: 'material'}],
["settings_input_composite", require('@material-icons/svg/svg/settings_input_composite/baseline.svg'), {className: 'material'}],
["settings_input_hdmi", require('@material-icons/svg/svg/settings_input_hdmi/baseline.svg'), {className: 'material'}],
["settings_input_svideo", require('@material-icons/svg/svg/settings_input_svideo/baseline.svg'), {className: 'material'}],
["settings_overscan", require('@material-icons/svg/svg/settings_overscan/baseline.svg'), {className: 'material'}],
["settings_phone", require('@material-icons/svg/svg/settings_phone/baseline.svg'), {className: 'material'}],
["settings_power", require('@material-icons/svg/svg/settings_power/baseline.svg'), {className: 'material'}],
["settings_remote", require('@material-icons/svg/svg/settings_remote/baseline.svg'), {className: 'material'}],
["settings_suggest", require('@material-icons/svg/svg/settings_suggest/baseline.svg'), {className: 'material'}],
["settings_system_daydream", require('@material-icons/svg/svg/settings_system_daydream/baseline.svg'), {className: 'material'}],
["settings_voice", require('@material-icons/svg/svg/settings_voice/baseline.svg'), {className: 'material'}],
["share", require('@material-icons/svg/svg/share/baseline.svg'), {className: 'material'}],
["share_arrival_time", require('@material-icons/svg/svg/share_arrival_time/baseline.svg'), {className: 'material'}],
["share_location", require('@material-icons/svg/svg/share_location/baseline.svg'), {className: 'material'}],
["shield", require('@material-icons/svg/svg/shield/baseline.svg'), {className: 'material'}],
["shield_moon", require('@material-icons/svg/svg/shield_moon/baseline.svg'), {className: 'material'}],
["shop", require('@material-icons/svg/svg/shop/baseline.svg'), {className: 'material'}],
["shop_2", require('@material-icons/svg/svg/shop_2/baseline.svg'), {className: 'material'}],
["shop_two", require('@material-icons/svg/svg/shop_two/baseline.svg'), {className: 'material'}],
["shopify", require('@material-icons/svg/svg/shopify/baseline.svg'), {className: 'material'}],
["shopping_bag", require('@material-icons/svg/svg/shopping_bag/baseline.svg'), {className: 'material'}],
["shopping_basket", require('@material-icons/svg/svg/shopping_basket/baseline.svg'), {className: 'material'}],
["shopping_cart", require('@material-icons/svg/svg/shopping_cart/baseline.svg'), {className: 'material'}],
["shopping_cart_checkout", require('@material-icons/svg/svg/shopping_cart_checkout/baseline.svg'), {className: 'material'}],
["short_text", require('@material-icons/svg/svg/short_text/baseline.svg'), {className: 'material'}],
["shortcut", require('@material-icons/svg/svg/shortcut/baseline.svg'), {className: 'material'}],
["show_chart", require('@material-icons/svg/svg/show_chart/baseline.svg'), {className: 'material'}],
["shower", require('@material-icons/svg/svg/shower/baseline.svg'), {className: 'material'}],
["shuffle", require('@material-icons/svg/svg/shuffle/baseline.svg'), {className: 'material'}],
["shuffle_on", require('@material-icons/svg/svg/shuffle_on/baseline.svg'), {className: 'material'}],
["shutter_speed", require('@material-icons/svg/svg/shutter_speed/baseline.svg'), {className: 'material'}],
["sick", require('@material-icons/svg/svg/sick/baseline.svg'), {className: 'material'}],
["signal_cellular_0_bar", require('@material-icons/svg/svg/signal_cellular_0_bar/baseline.svg'), {className: 'material'}],
["signal_cellular_1_bar", require('@material-icons/svg/svg/signal_cellular_1_bar/baseline.svg'), {className: 'material'}],
["signal_cellular_2_bar", require('@material-icons/svg/svg/signal_cellular_2_bar/baseline.svg'), {className: 'material'}],
["signal_cellular_3_bar", require('@material-icons/svg/svg/signal_cellular_3_bar/baseline.svg'), {className: 'material'}],
["signal_cellular_4_bar", require('@material-icons/svg/svg/signal_cellular_4_bar/baseline.svg'), {className: 'material'}],
["signal_cellular_alt", require('@material-icons/svg/svg/signal_cellular_alt/baseline.svg'), {className: 'material'}],
["signal_cellular_connected_no_internet_0_bar", require('@material-icons/svg/svg/signal_cellular_connected_no_internet_0_bar/baseline.svg'), {className: 'material'}],
["signal_cellular_connected_no_internet_1_bar", require('@material-icons/svg/svg/signal_cellular_connected_no_internet_1_bar/baseline.svg'), {className: 'material'}],
["signal_cellular_connected_no_internet_2_bar", require('@material-icons/svg/svg/signal_cellular_connected_no_internet_2_bar/baseline.svg'), {className: 'material'}],
["signal_cellular_connected_no_internet_3_bar", require('@material-icons/svg/svg/signal_cellular_connected_no_internet_3_bar/baseline.svg'), {className: 'material'}],
["signal_cellular_connected_no_internet_4_bar", require('@material-icons/svg/svg/signal_cellular_connected_no_internet_4_bar/baseline.svg'), {className: 'material'}],
["signal_cellular_no_sim", require('@material-icons/svg/svg/signal_cellular_no_sim/baseline.svg'), {className: 'material'}],
["signal_cellular_nodata", require('@material-icons/svg/svg/signal_cellular_nodata/baseline.svg'), {className: 'material'}],
["signal_cellular_null", require('@material-icons/svg/svg/signal_cellular_null/baseline.svg'), {className: 'material'}],
["signal_cellular_off", require('@material-icons/svg/svg/signal_cellular_off/baseline.svg'), {className: 'material'}],
["signal_wifi_0_bar", require('@material-icons/svg/svg/signal_wifi_0_bar/baseline.svg'), {className: 'material'}],
["signal_wifi_1_bar", require('@material-icons/svg/svg/signal_wifi_1_bar/baseline.svg'), {className: 'material'}],
["signal_wifi_1_bar_lock", require('@material-icons/svg/svg/signal_wifi_1_bar_lock/baseline.svg'), {className: 'material'}],
["signal_wifi_2_bar", require('@material-icons/svg/svg/signal_wifi_2_bar/baseline.svg'), {className: 'material'}],
["signal_wifi_2_bar_lock", require('@material-icons/svg/svg/signal_wifi_2_bar_lock/baseline.svg'), {className: 'material'}],
["signal_wifi_3_bar", require('@material-icons/svg/svg/signal_wifi_3_bar/baseline.svg'), {className: 'material'}],
["signal_wifi_3_bar_lock", require('@material-icons/svg/svg/signal_wifi_3_bar_lock/baseline.svg'), {className: 'material'}],
["signal_wifi_4_bar", require('@material-icons/svg/svg/signal_wifi_4_bar/baseline.svg'), {className: 'material'}],
["signal_wifi_4_bar_lock", require('@material-icons/svg/svg/signal_wifi_4_bar_lock/baseline.svg'), {className: 'material'}],
["signal_wifi_bad", require('@material-icons/svg/svg/signal_wifi_bad/baseline.svg'), {className: 'material'}],
["signal_wifi_connected_no_internet_4", require('@material-icons/svg/svg/signal_wifi_connected_no_internet_4/baseline.svg'), {className: 'material'}],
["signal_wifi_off", require('@material-icons/svg/svg/signal_wifi_off/baseline.svg'), {className: 'material'}],
["signal_wifi_statusbar_4_bar", require('@material-icons/svg/svg/signal_wifi_statusbar_4_bar/baseline.svg'), {className: 'material'}],
["signal_wifi_statusbar_connected_no_internet_4", require('@material-icons/svg/svg/signal_wifi_statusbar_connected_no_internet_4/baseline.svg'), {className: 'material'}],
["signal_wifi_statusbar_null", require('@material-icons/svg/svg/signal_wifi_statusbar_null/baseline.svg'), {className: 'material'}],
["signpost", require('@material-icons/svg/svg/signpost/baseline.svg'), {className: 'material'}],
["sim_card", require('@material-icons/svg/svg/sim_card/baseline.svg'), {className: 'material'}],
["sim_card_alert", require('@material-icons/svg/svg/sim_card_alert/baseline.svg'), {className: 'material'}],
["sim_card_download", require('@material-icons/svg/svg/sim_card_download/baseline.svg'), {className: 'material'}],
["single_bed", require('@material-icons/svg/svg/single_bed/baseline.svg'), {className: 'material'}],
["sip", require('@material-icons/svg/svg/sip/baseline.svg'), {className: 'material'}],
["skateboarding", require('@material-icons/svg/svg/skateboarding/baseline.svg'), {className: 'material'}],
["skip_next", require('@material-icons/svg/svg/skip_next/baseline.svg'), {className: 'material'}],
["skip_previous", require('@material-icons/svg/svg/skip_previous/baseline.svg'), {className: 'material'}],
["sledding", require('@material-icons/svg/svg/sledding/baseline.svg'), {className: 'material'}],
["slideshow", require('@material-icons/svg/svg/slideshow/baseline.svg'), {className: 'material'}],
["slow_motion_video", require('@material-icons/svg/svg/slow_motion_video/baseline.svg'), {className: 'material'}],
["smart_button", require('@material-icons/svg/svg/smart_button/baseline.svg'), {className: 'material'}],
["smart_display", require('@material-icons/svg/svg/smart_display/baseline.svg'), {className: 'material'}],
["smart_screen", require('@material-icons/svg/svg/smart_screen/baseline.svg'), {className: 'material'}],
["smart_toy", require('@material-icons/svg/svg/smart_toy/baseline.svg'), {className: 'material'}],
["smartphone", require('@material-icons/svg/svg/smartphone/baseline.svg'), {className: 'material'}],
["smoke_free", require('@material-icons/svg/svg/smoke_free/baseline.svg'), {className: 'material'}],
["smoking_rooms", require('@material-icons/svg/svg/smoking_rooms/baseline.svg'), {className: 'material'}],
["sms", require('@material-icons/svg/svg/sms/baseline.svg'), {className: 'material'}],
["sms_failed", require('@material-icons/svg/svg/sms_failed/baseline.svg'), {className: 'material'}],
["snapchat", require('@material-icons/svg/svg/snapchat/baseline.svg'), {className: 'material'}],
["snippet_folder", require('@material-icons/svg/svg/snippet_folder/baseline.svg'), {className: 'material'}],
["snooze", require('@material-icons/svg/svg/snooze/baseline.svg'), {className: 'material'}],
["snowboarding", require('@material-icons/svg/svg/snowboarding/baseline.svg'), {className: 'material'}],
["snowmobile", require('@material-icons/svg/svg/snowmobile/baseline.svg'), {className: 'material'}],
["snowshoeing", require('@material-icons/svg/svg/snowshoeing/baseline.svg'), {className: 'material'}],
["soap", require('@material-icons/svg/svg/soap/baseline.svg'), {className: 'material'}],
["social_distance", require('@material-icons/svg/svg/social_distance/baseline.svg'), {className: 'material'}],
["sort", require('@material-icons/svg/svg/sort/baseline.svg'), {className: 'material'}],
["sort_by_alpha", require('@material-icons/svg/svg/sort_by_alpha/baseline.svg'), {className: 'material'}],
["soup_kitchen", require('@material-icons/svg/svg/soup_kitchen/baseline.svg'), {className: 'material'}],
["source", require('@material-icons/svg/svg/source/baseline.svg'), {className: 'material'}],
["south", require('@material-icons/svg/svg/south/baseline.svg'), {className: 'material'}],
["south_america", require('@material-icons/svg/svg/south_america/baseline.svg'), {className: 'material'}],
["south_east", require('@material-icons/svg/svg/south_east/baseline.svg'), {className: 'material'}],
["south_west", require('@material-icons/svg/svg/south_west/baseline.svg'), {className: 'material'}],
["spa", require('@material-icons/svg/svg/spa/baseline.svg'), {className: 'material'}],
["space_bar", require('@material-icons/svg/svg/space_bar/baseline.svg'), {className: 'material'}],
["space_dashboard", require('@material-icons/svg/svg/space_dashboard/baseline.svg'), {className: 'material'}],
["speaker", require('@material-icons/svg/svg/speaker/baseline.svg'), {className: 'material'}],
["speaker_group", require('@material-icons/svg/svg/speaker_group/baseline.svg'), {className: 'material'}],
["speaker_notes", require('@material-icons/svg/svg/speaker_notes/baseline.svg'), {className: 'material'}],
["speaker_notes_off", require('@material-icons/svg/svg/speaker_notes_off/baseline.svg'), {className: 'material'}],
["speaker_phone", require('@material-icons/svg/svg/speaker_phone/baseline.svg'), {className: 'material'}],
["speed", require('@material-icons/svg/svg/speed/baseline.svg'), {className: 'material'}],
["spellcheck", require('@material-icons/svg/svg/spellcheck/baseline.svg'), {className: 'material'}],
["splitscreen", require('@material-icons/svg/svg/splitscreen/baseline.svg'), {className: 'material'}],
["spoke", require('@material-icons/svg/svg/spoke/baseline.svg'), {className: 'material'}],
["sports", require('@material-icons/svg/svg/sports/baseline.svg'), {className: 'material'}],
["sports_bar", require('@material-icons/svg/svg/sports_bar/baseline.svg'), {className: 'material'}],
["sports_baseball", require('@material-icons/svg/svg/sports_baseball/baseline.svg'), {className: 'material'}],
["sports_basketball", require('@material-icons/svg/svg/sports_basketball/baseline.svg'), {className: 'material'}],
["sports_cricket", require('@material-icons/svg/svg/sports_cricket/baseline.svg'), {className: 'material'}],
["sports_esports", require('@material-icons/svg/svg/sports_esports/baseline.svg'), {className: 'material'}],
["sports_football", require('@material-icons/svg/svg/sports_football/baseline.svg'), {className: 'material'}],
["sports_golf", require('@material-icons/svg/svg/sports_golf/baseline.svg'), {className: 'material'}],
["sports_gymnastics", require('@material-icons/svg/svg/sports_gymnastics/baseline.svg'), {className: 'material'}],
["sports_handball", require('@material-icons/svg/svg/sports_handball/baseline.svg'), {className: 'material'}],
["sports_hockey", require('@material-icons/svg/svg/sports_hockey/baseline.svg'), {className: 'material'}],
["sports_kabaddi", require('@material-icons/svg/svg/sports_kabaddi/baseline.svg'), {className: 'material'}],
["sports_martial_arts", require('@material-icons/svg/svg/sports_martial_arts/baseline.svg'), {className: 'material'}],
["sports_mma", require('@material-icons/svg/svg/sports_mma/baseline.svg'), {className: 'material'}],
["sports_motorsports", require('@material-icons/svg/svg/sports_motorsports/baseline.svg'), {className: 'material'}],
["sports_rugby", require('@material-icons/svg/svg/sports_rugby/baseline.svg'), {className: 'material'}],
["sports_score", require('@material-icons/svg/svg/sports_score/baseline.svg'), {className: 'material'}],
["sports_soccer", require('@material-icons/svg/svg/sports_soccer/baseline.svg'), {className: 'material'}],
["sports_tennis", require('@material-icons/svg/svg/sports_tennis/baseline.svg'), {className: 'material'}],
["sports_volleyball", require('@material-icons/svg/svg/sports_volleyball/baseline.svg'), {className: 'material'}],
["square", require('@material-icons/svg/svg/square/baseline.svg'), {className: 'material'}],
["square_foot", require('@material-icons/svg/svg/square_foot/baseline.svg'), {className: 'material'}],
["ssid_chart", require('@material-icons/svg/svg/ssid_chart/baseline.svg'), {className: 'material'}],
["stacked_bar_chart", require('@material-icons/svg/svg/stacked_bar_chart/baseline.svg'), {className: 'material'}],
["stacked_line_chart", require('@material-icons/svg/svg/stacked_line_chart/baseline.svg'), {className: 'material'}],
["stadium", require('@material-icons/svg/svg/stadium/baseline.svg'), {className: 'material'}],
["stairs", require('@material-icons/svg/svg/stairs/baseline.svg'), {className: 'material'}],
["star", require('@material-icons/svg/svg/star/baseline.svg'), {className: 'material'}],
["star_border", require('@material-icons/svg/svg/star_border/baseline.svg'), {className: 'material'}],
["star_border_purple500", require('@material-icons/svg/svg/star_border_purple500/baseline.svg'), {className: 'material'}],
["star_half", require('@material-icons/svg/svg/star_half/baseline.svg'), {className: 'material'}],
["star_outline", require('@material-icons/svg/svg/star_outline/baseline.svg'), {className: 'material'}],
["star_purple500", require('@material-icons/svg/svg/star_purple500/baseline.svg'), {className: 'material'}],
["star_rate", require('@material-icons/svg/svg/star_rate/baseline.svg'), {className: 'material'}],
["stars", require('@material-icons/svg/svg/stars/baseline.svg'), {className: 'material'}],
["start", require('@material-icons/svg/svg/start/baseline.svg'), {className: 'material'}],
["stay_current_landscape", require('@material-icons/svg/svg/stay_current_landscape/baseline.svg'), {className: 'material'}],
["stay_current_portrait", require('@material-icons/svg/svg/stay_current_portrait/baseline.svg'), {className: 'material'}],
["stay_primary_landscape", require('@material-icons/svg/svg/stay_primary_landscape/baseline.svg'), {className: 'material'}],
["stay_primary_portrait", require('@material-icons/svg/svg/stay_primary_portrait/baseline.svg'), {className: 'material'}],
["sticky_note_2", require('@material-icons/svg/svg/sticky_note_2/baseline.svg'), {className: 'material'}],
["stop", require('@material-icons/svg/svg/stop/baseline.svg'), {className: 'material'}],
["stop_circle", require('@material-icons/svg/svg/stop_circle/baseline.svg'), {className: 'material'}],
["stop_screen_share", require('@material-icons/svg/svg/stop_screen_share/baseline.svg'), {className: 'material'}],
["storage", require('@material-icons/svg/svg/storage/baseline.svg'), {className: 'material'}],
["store", require('@material-icons/svg/svg/store/baseline.svg'), {className: 'material'}],
["store_mall_directory", require('@material-icons/svg/svg/store_mall_directory/baseline.svg'), {className: 'material'}],
["storefront", require('@material-icons/svg/svg/storefront/baseline.svg'), {className: 'material'}],
["storm", require('@material-icons/svg/svg/storm/baseline.svg'), {className: 'material'}],
["straight", require('@material-icons/svg/svg/straight/baseline.svg'), {className: 'material'}],
["straighten", require('@material-icons/svg/svg/straighten/baseline.svg'), {className: 'material'}],
["stream", require('@material-icons/svg/svg/stream/baseline.svg'), {className: 'material'}],
["streetview", require('@material-icons/svg/svg/streetview/baseline.svg'), {className: 'material'}],
["strikethrough_s", require('@material-icons/svg/svg/strikethrough_s/baseline.svg'), {className: 'material'}],
["stroller", require('@material-icons/svg/svg/stroller/baseline.svg'), {className: 'material'}],
["style", require('@material-icons/svg/svg/style/baseline.svg'), {className: 'material'}],
["subdirectory_arrow_left", require('@material-icons/svg/svg/subdirectory_arrow_left/baseline.svg'), {className: 'material'}],
["subdirectory_arrow_right", require('@material-icons/svg/svg/subdirectory_arrow_right/baseline.svg'), {className: 'material'}],
["subject", require('@material-icons/svg/svg/subject/baseline.svg'), {className: 'material'}],
["subscript", require('@material-icons/svg/svg/subscript/baseline.svg'), {className: 'material'}],
["subscriptions", require('@material-icons/svg/svg/subscriptions/baseline.svg'), {className: 'material'}],
["subtitles", require('@material-icons/svg/svg/subtitles/baseline.svg'), {className: 'material'}],
["subtitles_off", require('@material-icons/svg/svg/subtitles_off/baseline.svg'), {className: 'material'}],
["subway", require('@material-icons/svg/svg/subway/baseline.svg'), {className: 'material'}],
["summarize", require('@material-icons/svg/svg/summarize/baseline.svg'), {className: 'material'}],
["superscript", require('@material-icons/svg/svg/superscript/baseline.svg'), {className: 'material'}],
["supervised_user_circle", require('@material-icons/svg/svg/supervised_user_circle/baseline.svg'), {className: 'material'}],
["supervisor_account", require('@material-icons/svg/svg/supervisor_account/baseline.svg'), {className: 'material'}],
["support", require('@material-icons/svg/svg/support/baseline.svg'), {className: 'material'}],
["support_agent", require('@material-icons/svg/svg/support_agent/baseline.svg'), {className: 'material'}],
["surfing", require('@material-icons/svg/svg/surfing/baseline.svg'), {className: 'material'}],
["surround_sound", require('@material-icons/svg/svg/surround_sound/baseline.svg'), {className: 'material'}],
["swap_calls", require('@material-icons/svg/svg/swap_calls/baseline.svg'), {className: 'material'}],
["swap_horiz", require('@material-icons/svg/svg/swap_horiz/baseline.svg'), {className: 'material'}],
["swap_horizontal_circle", require('@material-icons/svg/svg/swap_horizontal_circle/baseline.svg'), {className: 'material'}],
["swap_vert", require('@material-icons/svg/svg/swap_vert/baseline.svg'), {className: 'material'}],
["swap_vertical_circle", require('@material-icons/svg/svg/swap_vertical_circle/baseline.svg'), {className: 'material'}],
["swipe", require('@material-icons/svg/svg/swipe/baseline.svg'), {className: 'material'}],
["swipe_down", require('@material-icons/svg/svg/swipe_down/baseline.svg'), {className: 'material'}],
["swipe_down_alt", require('@material-icons/svg/svg/swipe_down_alt/baseline.svg'), {className: 'material'}],
["swipe_left", require('@material-icons/svg/svg/swipe_left/baseline.svg'), {className: 'material'}],
["swipe_left_alt", require('@material-icons/svg/svg/swipe_left_alt/baseline.svg'), {className: 'material'}],
["swipe_right", require('@material-icons/svg/svg/swipe_right/baseline.svg'), {className: 'material'}],
["swipe_right_alt", require('@material-icons/svg/svg/swipe_right_alt/baseline.svg'), {className: 'material'}],
["swipe_up", require('@material-icons/svg/svg/swipe_up/baseline.svg'), {className: 'material'}],
["swipe_up_alt", require('@material-icons/svg/svg/swipe_up_alt/baseline.svg'), {className: 'material'}],
["swipe_vertical", require('@material-icons/svg/svg/swipe_vertical/baseline.svg'), {className: 'material'}],
["switch_access_shortcut", require('@material-icons/svg/svg/switch_access_shortcut/baseline.svg'), {className: 'material'}],
["switch_access_shortcut_add", require('@material-icons/svg/svg/switch_access_shortcut_add/baseline.svg'), {className: 'material'}],
["switch_account", require('@material-icons/svg/svg/switch_account/baseline.svg'), {className: 'material'}],
["switch_camera", require('@material-icons/svg/svg/switch_camera/baseline.svg'), {className: 'material'}],
["switch_left", require('@material-icons/svg/svg/switch_left/baseline.svg'), {className: 'material'}],
["switch_right", require('@material-icons/svg/svg/switch_right/baseline.svg'), {className: 'material'}],
["switch_video", require('@material-icons/svg/svg/switch_video/baseline.svg'), {className: 'material'}],
["synagogue", require('@material-icons/svg/svg/synagogue/baseline.svg'), {className: 'material'}],
["sync", require('@material-icons/svg/svg/sync/baseline.svg'), {className: 'material'}],
["sync_alt", require('@material-icons/svg/svg/sync_alt/baseline.svg'), {className: 'material'}],
["sync_disabled", require('@material-icons/svg/svg/sync_disabled/baseline.svg'), {className: 'material'}],
["sync_lock", require('@material-icons/svg/svg/sync_lock/baseline.svg'), {className: 'material'}],
["sync_problem", require('@material-icons/svg/svg/sync_problem/baseline.svg'), {className: 'material'}],
["system_security_update", require('@material-icons/svg/svg/system_security_update/baseline.svg'), {className: 'material'}],
["system_security_update_good", require('@material-icons/svg/svg/system_security_update_good/baseline.svg'), {className: 'material'}],
["system_security_update_warning", require('@material-icons/svg/svg/system_security_update_warning/baseline.svg'), {className: 'material'}],
["system_update", require('@material-icons/svg/svg/system_update/baseline.svg'), {className: 'material'}],
["system_update_alt", require('@material-icons/svg/svg/system_update_alt/baseline.svg'), {className: 'material'}],
["tab", require('@material-icons/svg/svg/tab/baseline.svg'), {className: 'material'}],
["tab_unselected", require('@material-icons/svg/svg/tab_unselected/baseline.svg'), {className: 'material'}],
["table_bar", require('@material-icons/svg/svg/table_bar/baseline.svg'), {className: 'material'}],
["table_chart", require('@material-icons/svg/svg/table_chart/baseline.svg'), {className: 'material'}],
["table_restaurant", require('@material-icons/svg/svg/table_restaurant/baseline.svg'), {className: 'material'}],
["table_rows", require('@material-icons/svg/svg/table_rows/baseline.svg'), {className: 'material'}],
["table_view", require('@material-icons/svg/svg/table_view/baseline.svg'), {className: 'material'}],
["tablet", require('@material-icons/svg/svg/tablet/baseline.svg'), {className: 'material'}],
["tablet_android", require('@material-icons/svg/svg/tablet_android/baseline.svg'), {className: 'material'}],
["tablet_mac", require('@material-icons/svg/svg/tablet_mac/baseline.svg'), {className: 'material'}],
["tag", require('@material-icons/svg/svg/tag/baseline.svg'), {className: 'material'}],
["tag_faces", require('@material-icons/svg/svg/tag_faces/baseline.svg'), {className: 'material'}],
["takeout_dining", require('@material-icons/svg/svg/takeout_dining/baseline.svg'), {className: 'material'}],
["tap_and_play", require('@material-icons/svg/svg/tap_and_play/baseline.svg'), {className: 'material'}],
["tapas", require('@material-icons/svg/svg/tapas/baseline.svg'), {className: 'material'}],
["task", require('@material-icons/svg/svg/task/baseline.svg'), {className: 'material'}],
["task_alt", require('@material-icons/svg/svg/task_alt/baseline.svg'), {className: 'material'}],
["taxi_alert", require('@material-icons/svg/svg/taxi_alert/baseline.svg'), {className: 'material'}],
["telegram", require('@material-icons/svg/svg/telegram/baseline.svg'), {className: 'material'}],
["temple_buddhist", require('@material-icons/svg/svg/temple_buddhist/baseline.svg'), {className: 'material'}],
["temple_hindu", require('@material-icons/svg/svg/temple_hindu/baseline.svg'), {className: 'material'}],
["terminal", require('@material-icons/svg/svg/terminal/baseline.svg'), {className: 'material'}],
["terrain", require('@material-icons/svg/svg/terrain/baseline.svg'), {className: 'material'}],
["text_decrease", require('@material-icons/svg/svg/text_decrease/baseline.svg'), {className: 'material'}],
["text_fields", require('@material-icons/svg/svg/text_fields/baseline.svg'), {className: 'material'}],
["text_format", require('@material-icons/svg/svg/text_format/baseline.svg'), {className: 'material'}],
["text_increase", require('@material-icons/svg/svg/text_increase/baseline.svg'), {className: 'material'}],
["text_rotate_up", require('@material-icons/svg/svg/text_rotate_up/baseline.svg'), {className: 'material'}],
["text_rotate_vertical", require('@material-icons/svg/svg/text_rotate_vertical/baseline.svg'), {className: 'material'}],
["text_rotation_angledown", require('@material-icons/svg/svg/text_rotation_angledown/baseline.svg'), {className: 'material'}],
["text_rotation_angleup", require('@material-icons/svg/svg/text_rotation_angleup/baseline.svg'), {className: 'material'}],
["text_rotation_down", require('@material-icons/svg/svg/text_rotation_down/baseline.svg'), {className: 'material'}],
["text_rotation_none", require('@material-icons/svg/svg/text_rotation_none/baseline.svg'), {className: 'material'}],
["text_snippet", require('@material-icons/svg/svg/text_snippet/baseline.svg'), {className: 'material'}],
["textsms", require('@material-icons/svg/svg/textsms/baseline.svg'), {className: 'material'}],
["texture", require('@material-icons/svg/svg/texture/baseline.svg'), {className: 'material'}],
["theater_comedy", require('@material-icons/svg/svg/theater_comedy/baseline.svg'), {className: 'material'}],
["theaters", require('@material-icons/svg/svg/theaters/baseline.svg'), {className: 'material'}],
["thermostat", require('@material-icons/svg/svg/thermostat/baseline.svg'), {className: 'material'}],
["thermostat_auto", require('@material-icons/svg/svg/thermostat_auto/baseline.svg'), {className: 'material'}],
["thumb_down", require('@material-icons/svg/svg/thumb_down/baseline.svg'), {className: 'material'}],
["thumb_down_alt", require('@material-icons/svg/svg/thumb_down_alt/baseline.svg'), {className: 'material'}],
["thumb_down_off_alt", require('@material-icons/svg/svg/thumb_down_off_alt/baseline.svg'), {className: 'material'}],
["thumb_up", require('@material-icons/svg/svg/thumb_up/baseline.svg'), {className: 'material'}],
["thumb_up_alt", require('@material-icons/svg/svg/thumb_up_alt/baseline.svg'), {className: 'material'}],
["thumb_up_off_alt", require('@material-icons/svg/svg/thumb_up_off_alt/baseline.svg'), {className: 'material'}],
["thumbs_up_down", require('@material-icons/svg/svg/thumbs_up_down/baseline.svg'), {className: 'material'}],
["tiktok", require('@material-icons/svg/svg/tiktok/baseline.svg'), {className: 'material'}],
["time_to_leave", require('@material-icons/svg/svg/time_to_leave/baseline.svg'), {className: 'material'}],
["timelapse", require('@material-icons/svg/svg/timelapse/baseline.svg'), {className: 'material'}],
["timeline", require('@material-icons/svg/svg/timeline/baseline.svg'), {className: 'material'}],
["timer", require('@material-icons/svg/svg/timer/baseline.svg'), {className: 'material'}],
["timer_10", require('@material-icons/svg/svg/timer_10/baseline.svg'), {className: 'material'}],
["timer_10_select", require('@material-icons/svg/svg/timer_10_select/baseline.svg'), {className: 'material'}],
["timer_3", require('@material-icons/svg/svg/timer_3/baseline.svg'), {className: 'material'}],
["timer_3_select", require('@material-icons/svg/svg/timer_3_select/baseline.svg'), {className: 'material'}],
["timer_off", require('@material-icons/svg/svg/timer_off/baseline.svg'), {className: 'material'}],
["tips_and_updates", require('@material-icons/svg/svg/tips_and_updates/baseline.svg'), {className: 'material'}],
["tire_repair", require('@material-icons/svg/svg/tire_repair/baseline.svg'), {className: 'material'}],
["title", require('@material-icons/svg/svg/title/baseline.svg'), {className: 'material'}],
["toc", require('@material-icons/svg/svg/toc/baseline.svg'), {className: 'material'}],
["today", require('@material-icons/svg/svg/today/baseline.svg'), {className: 'material'}],
["toggle_off", require('@material-icons/svg/svg/toggle_off/baseline.svg'), {className: 'material'}],
["toggle_on", require('@material-icons/svg/svg/toggle_on/baseline.svg'), {className: 'material'}],
["token", require('@material-icons/svg/svg/token/baseline.svg'), {className: 'material'}],
["toll", require('@material-icons/svg/svg/toll/baseline.svg'), {className: 'material'}],
["tonality", require('@material-icons/svg/svg/tonality/baseline.svg'), {className: 'material'}],
["topic", require('@material-icons/svg/svg/topic/baseline.svg'), {className: 'material'}],
["touch_app", require('@material-icons/svg/svg/touch_app/baseline.svg'), {className: 'material'}],
["tour", require('@material-icons/svg/svg/tour/baseline.svg'), {className: 'material'}],
["toys", require('@material-icons/svg/svg/toys/baseline.svg'), {className: 'material'}],
["track_changes", require('@material-icons/svg/svg/track_changes/baseline.svg'), {className: 'material'}],
["traffic", require('@material-icons/svg/svg/traffic/baseline.svg'), {className: 'material'}],
["train", require('@material-icons/svg/svg/train/baseline.svg'), {className: 'material'}],
["tram", require('@material-icons/svg/svg/tram/baseline.svg'), {className: 'material'}],
["transfer_within_a_station", require('@material-icons/svg/svg/transfer_within_a_station/baseline.svg'), {className: 'material'}],
["transform", require('@material-icons/svg/svg/transform/baseline.svg'), {className: 'material'}],
["transgender", require('@material-icons/svg/svg/transgender/baseline.svg'), {className: 'material'}],
["transit_enterexit", require('@material-icons/svg/svg/transit_enterexit/baseline.svg'), {className: 'material'}],
["translate", require('@material-icons/svg/svg/translate/baseline.svg'), {className: 'material'}],
["travel_explore", require('@material-icons/svg/svg/travel_explore/baseline.svg'), {className: 'material'}],
["trending_down", require('@material-icons/svg/svg/trending_down/baseline.svg'), {className: 'material'}],
["trending_flat", require('@material-icons/svg/svg/trending_flat/baseline.svg'), {className: 'material'}],
["trending_up", require('@material-icons/svg/svg/trending_up/baseline.svg'), {className: 'material'}],
["trip_origin", require('@material-icons/svg/svg/trip_origin/baseline.svg'), {className: 'material'}],
["try", require('@material-icons/svg/svg/try/baseline.svg'), {className: 'material'}],
["tty", require('@material-icons/svg/svg/tty/baseline.svg'), {className: 'material'}],
["tune", require('@material-icons/svg/svg/tune/baseline.svg'), {className: 'material'}],
["tungsten", require('@material-icons/svg/svg/tungsten/baseline.svg'), {className: 'material'}],
["turn_left", require('@material-icons/svg/svg/turn_left/baseline.svg'), {className: 'material'}],
["turn_right", require('@material-icons/svg/svg/turn_right/baseline.svg'), {className: 'material'}],
["turn_sharp_left", require('@material-icons/svg/svg/turn_sharp_left/baseline.svg'), {className: 'material'}],
["turn_sharp_right", require('@material-icons/svg/svg/turn_sharp_right/baseline.svg'), {className: 'material'}],
["turn_slight_left", require('@material-icons/svg/svg/turn_slight_left/baseline.svg'), {className: 'material'}],
["turn_slight_right", require('@material-icons/svg/svg/turn_slight_right/baseline.svg'), {className: 'material'}],
["turned_in", require('@material-icons/svg/svg/turned_in/baseline.svg'), {className: 'material'}],
["turned_in_not", require('@material-icons/svg/svg/turned_in_not/baseline.svg'), {className: 'material'}],
["tv", require('@material-icons/svg/svg/tv/baseline.svg'), {className: 'material'}],
["tv_off", require('@material-icons/svg/svg/tv_off/baseline.svg'), {className: 'material'}],
["two_wheeler", require('@material-icons/svg/svg/two_wheeler/baseline.svg'), {className: 'material'}],
["u_turn_left", require('@material-icons/svg/svg/u_turn_left/baseline.svg'), {className: 'material'}],
["u_turn_right", require('@material-icons/svg/svg/u_turn_right/baseline.svg'), {className: 'material'}],
["umbrella", require('@material-icons/svg/svg/umbrella/baseline.svg'), {className: 'material'}],
["unarchive", require('@material-icons/svg/svg/unarchive/baseline.svg'), {className: 'material'}],
["undo", require('@material-icons/svg/svg/undo/baseline.svg'), {className: 'material'}],
["unfold_less", require('@material-icons/svg/svg/unfold_less/baseline.svg'), {className: 'material'}],
["unfold_more", require('@material-icons/svg/svg/unfold_more/baseline.svg'), {className: 'material'}],
["unpublished", require('@material-icons/svg/svg/unpublished/baseline.svg'), {className: 'material'}],
["unsubscribe", require('@material-icons/svg/svg/unsubscribe/baseline.svg'), {className: 'material'}],
["upcoming", require('@material-icons/svg/svg/upcoming/baseline.svg'), {className: 'material'}],
["update", require('@material-icons/svg/svg/update/baseline.svg'), {className: 'material'}],
["update_disabled", require('@material-icons/svg/svg/update_disabled/baseline.svg'), {className: 'material'}],
["upgrade", require('@material-icons/svg/svg/upgrade/baseline.svg'), {className: 'material'}],
["upload", require('@material-icons/svg/svg/upload/baseline.svg'), {className: 'material'}],
["upload_file", require('@material-icons/svg/svg/upload_file/baseline.svg'), {className: 'material'}],
["usb", require('@material-icons/svg/svg/usb/baseline.svg'), {className: 'material'}],
["usb_off", require('@material-icons/svg/svg/usb_off/baseline.svg'), {className: 'material'}],
["vaccines", require('@material-icons/svg/svg/vaccines/baseline.svg'), {className: 'material'}],
["vape_free", require('@material-icons/svg/svg/vape_free/baseline.svg'), {className: 'material'}],
["vaping_rooms", require('@material-icons/svg/svg/vaping_rooms/baseline.svg'), {className: 'material'}],
["verified", require('@material-icons/svg/svg/verified/baseline.svg'), {className: 'material'}],
["verified_user", require('@material-icons/svg/svg/verified_user/baseline.svg'), {className: 'material'}],
["vertical_align_bottom", require('@material-icons/svg/svg/vertical_align_bottom/baseline.svg'), {className: 'material'}],
["vertical_align_center", require('@material-icons/svg/svg/vertical_align_center/baseline.svg'), {className: 'material'}],
["vertical_align_top", require('@material-icons/svg/svg/vertical_align_top/baseline.svg'), {className: 'material'}],
["vertical_distribute", require('@material-icons/svg/svg/vertical_distribute/baseline.svg'), {className: 'material'}],
["vertical_split", require('@material-icons/svg/svg/vertical_split/baseline.svg'), {className: 'material'}],
["vibration", require('@material-icons/svg/svg/vibration/baseline.svg'), {className: 'material'}],
["video_call", require('@material-icons/svg/svg/video_call/baseline.svg'), {className: 'material'}],
["video_camera_back", require('@material-icons/svg/svg/video_camera_back/baseline.svg'), {className: 'material'}],
["video_camera_front", require('@material-icons/svg/svg/video_camera_front/baseline.svg'), {className: 'material'}],
["video_file", require('@material-icons/svg/svg/video_file/baseline.svg'), {className: 'material'}],
["video_label", require('@material-icons/svg/svg/video_label/baseline.svg'), {className: 'material'}],
["video_library", require('@material-icons/svg/svg/video_library/baseline.svg'), {className: 'material'}],
["video_settings", require('@material-icons/svg/svg/video_settings/baseline.svg'), {className: 'material'}],
["video_stable", require('@material-icons/svg/svg/video_stable/baseline.svg'), {className: 'material'}],
["videocam", require('@material-icons/svg/svg/videocam/baseline.svg'), {className: 'material'}],
["videocam_off", require('@material-icons/svg/svg/videocam_off/baseline.svg'), {className: 'material'}],
["videogame_asset", require('@material-icons/svg/svg/videogame_asset/baseline.svg'), {className: 'material'}],
["videogame_asset_off", require('@material-icons/svg/svg/videogame_asset_off/baseline.svg'), {className: 'material'}],
["view_agenda", require('@material-icons/svg/svg/view_agenda/baseline.svg'), {className: 'material'}],
["view_array", require('@material-icons/svg/svg/view_array/baseline.svg'), {className: 'material'}],
["view_carousel", require('@material-icons/svg/svg/view_carousel/baseline.svg'), {className: 'material'}],
["view_column", require('@material-icons/svg/svg/view_column/baseline.svg'), {className: 'material'}],
["view_comfy", require('@material-icons/svg/svg/view_comfy/baseline.svg'), {className: 'material'}],
["view_comfy_alt", require('@material-icons/svg/svg/view_comfy_alt/baseline.svg'), {className: 'material'}],
["view_compact", require('@material-icons/svg/svg/view_compact/baseline.svg'), {className: 'material'}],
["view_compact_alt", require('@material-icons/svg/svg/view_compact_alt/baseline.svg'), {className: 'material'}],
["view_cozy", require('@material-icons/svg/svg/view_cozy/baseline.svg'), {className: 'material'}],
["view_day", require('@material-icons/svg/svg/view_day/baseline.svg'), {className: 'material'}],
["view_headline", require('@material-icons/svg/svg/view_headline/baseline.svg'), {className: 'material'}],
["view_in_ar", require('@material-icons/svg/svg/view_in_ar/baseline.svg'), {className: 'material'}],
["view_kanban", require('@material-icons/svg/svg/view_kanban/baseline.svg'), {className: 'material'}],
["view_list", require('@material-icons/svg/svg/view_list/baseline.svg'), {className: 'material'}],
["view_module", require('@material-icons/svg/svg/view_module/baseline.svg'), {className: 'material'}],
["view_quilt", require('@material-icons/svg/svg/view_quilt/baseline.svg'), {className: 'material'}],
["view_sidebar", require('@material-icons/svg/svg/view_sidebar/baseline.svg'), {className: 'material'}],
["view_stream", require('@material-icons/svg/svg/view_stream/baseline.svg'), {className: 'material'}],
["view_timeline", require('@material-icons/svg/svg/view_timeline/baseline.svg'), {className: 'material'}],
["view_week", require('@material-icons/svg/svg/view_week/baseline.svg'), {className: 'material'}],
["vignette", require('@material-icons/svg/svg/vignette/baseline.svg'), {className: 'material'}],
["villa", require('@material-icons/svg/svg/villa/baseline.svg'), {className: 'material'}],
["visibility", require('@material-icons/svg/svg/visibility/baseline.svg'), {className: 'material'}],
["visibility_off", require('@material-icons/svg/svg/visibility_off/baseline.svg'), {className: 'material'}],
["voice_chat", require('@material-icons/svg/svg/voice_chat/baseline.svg'), {className: 'material'}],
["voice_over_off", require('@material-icons/svg/svg/voice_over_off/baseline.svg'), {className: 'material'}],
["voicemail", require('@material-icons/svg/svg/voicemail/baseline.svg'), {className: 'material'}],
["volume_down", require('@material-icons/svg/svg/volume_down/baseline.svg'), {className: 'material'}],
["volume_mute", require('@material-icons/svg/svg/volume_mute/baseline.svg'), {className: 'material'}],
["volume_off", require('@material-icons/svg/svg/volume_off/baseline.svg'), {className: 'material'}],
["volume_up", require('@material-icons/svg/svg/volume_up/baseline.svg'), {className: 'material'}],
["volunteer_activism", require('@material-icons/svg/svg/volunteer_activism/baseline.svg'), {className: 'material'}],
["vpn_key", require('@material-icons/svg/svg/vpn_key/baseline.svg'), {className: 'material'}],
["vpn_key_off", require('@material-icons/svg/svg/vpn_key_off/baseline.svg'), {className: 'material'}],
["vpn_lock", require('@material-icons/svg/svg/vpn_lock/baseline.svg'), {className: 'material'}],
["vrpano", require('@material-icons/svg/svg/vrpano/baseline.svg'), {className: 'material'}],
["wallpaper", require('@material-icons/svg/svg/wallpaper/baseline.svg'), {className: 'material'}],
["warehouse", require('@material-icons/svg/svg/warehouse/baseline.svg'), {className: 'material'}],
["warning", require('@material-icons/svg/svg/warning/baseline.svg'), {className: 'material'}],
["warning_amber", require('@material-icons/svg/svg/warning_amber/baseline.svg'), {className: 'material'}],
["wash", require('@material-icons/svg/svg/wash/baseline.svg'), {className: 'material'}],
["watch", require('@material-icons/svg/svg/watch/baseline.svg'), {className: 'material'}],
["watch_later", require('@material-icons/svg/svg/watch_later/baseline.svg'), {className: 'material'}],
["watch_off", require('@material-icons/svg/svg/watch_off/baseline.svg'), {className: 'material'}],
["water", require('@material-icons/svg/svg/water/baseline.svg'), {className: 'material'}],
["water_damage", require('@material-icons/svg/svg/water_damage/baseline.svg'), {className: 'material'}],
["water_drop", require('@material-icons/svg/svg/water_drop/baseline.svg'), {className: 'material'}],
["waterfall_chart", require('@material-icons/svg/svg/waterfall_chart/baseline.svg'), {className: 'material'}],
["waves", require('@material-icons/svg/svg/waves/baseline.svg'), {className: 'material'}],
["waving_hand", require('@material-icons/svg/svg/waving_hand/baseline.svg'), {className: 'material'}],
["wb_auto", require('@material-icons/svg/svg/wb_auto/baseline.svg'), {className: 'material'}],
["wb_cloudy", require('@material-icons/svg/svg/wb_cloudy/baseline.svg'), {className: 'material'}],
["wb_incandescent", require('@material-icons/svg/svg/wb_incandescent/baseline.svg'), {className: 'material'}],
["wb_iridescent", require('@material-icons/svg/svg/wb_iridescent/baseline.svg'), {className: 'material'}],
["wb_shade", require('@material-icons/svg/svg/wb_shade/baseline.svg'), {className: 'material'}],
["wb_sunny", require('@material-icons/svg/svg/wb_sunny/baseline.svg'), {className: 'material'}],
["wb_twilight", require('@material-icons/svg/svg/wb_twilight/baseline.svg'), {className: 'material'}],
["wc", require('@material-icons/svg/svg/wc/baseline.svg'), {className: 'material'}],
["web", require('@material-icons/svg/svg/web/baseline.svg'), {className: 'material'}],
["web_asset", require('@material-icons/svg/svg/web_asset/baseline.svg'), {className: 'material'}],
["web_asset_off", require('@material-icons/svg/svg/web_asset_off/baseline.svg'), {className: 'material'}],
["webhook", require('@material-icons/svg/svg/webhook/baseline.svg'), {className: 'material'}],
["wechat", require('@material-icons/svg/svg/wechat/baseline.svg'), {className: 'material'}],
["weekend", require('@material-icons/svg/svg/weekend/baseline.svg'), {className: 'material'}],
["west", require('@material-icons/svg/svg/west/baseline.svg'), {className: 'material'}],
["whatsapp", require('@material-icons/svg/svg/whatsapp/baseline.svg'), {className: 'material'}],
["whatshot", require('@material-icons/svg/svg/whatshot/baseline.svg'), {className: 'material'}],
["wheelchair_pickup", require('@material-icons/svg/svg/wheelchair_pickup/baseline.svg'), {className: 'material'}],
["where_to_vote", require('@material-icons/svg/svg/where_to_vote/baseline.svg'), {className: 'material'}],
["widgets", require('@material-icons/svg/svg/widgets/baseline.svg'), {className: 'material'}],
["wifi", require('@material-icons/svg/svg/wifi/baseline.svg'), {className: 'material'}],
["wifi_calling", require('@material-icons/svg/svg/wifi_calling/baseline.svg'), {className: 'material'}],
["wifi_calling_3", require('@material-icons/svg/svg/wifi_calling_3/baseline.svg'), {className: 'material'}],
["wifi_channel", require('@material-icons/svg/svg/wifi_channel/baseline.svg'), {className: 'material'}],
["wifi_find", require('@material-icons/svg/svg/wifi_find/baseline.svg'), {className: 'material'}],
["wifi_lock", require('@material-icons/svg/svg/wifi_lock/baseline.svg'), {className: 'material'}],
["wifi_off", require('@material-icons/svg/svg/wifi_off/baseline.svg'), {className: 'material'}],
["wifi_password", require('@material-icons/svg/svg/wifi_password/baseline.svg'), {className: 'material'}],
["wifi_protected_setup", require('@material-icons/svg/svg/wifi_protected_setup/baseline.svg'), {className: 'material'}],
["wifi_tethering", require('@material-icons/svg/svg/wifi_tethering/baseline.svg'), {className: 'material'}],
["wifi_tethering_error", require('@material-icons/svg/svg/wifi_tethering_error/baseline.svg'), {className: 'material'}],
["wifi_tethering_error_rounded", require('@material-icons/svg/svg/wifi_tethering_error_rounded/baseline.svg'), {className: 'material'}],
["wifi_tethering_off", require('@material-icons/svg/svg/wifi_tethering_off/baseline.svg'), {className: 'material'}],
["window", require('@material-icons/svg/svg/window/baseline.svg'), {className: 'material'}],
["wine_bar", require('@material-icons/svg/svg/wine_bar/baseline.svg'), {className: 'material'}],
["woman", require('@material-icons/svg/svg/woman/baseline.svg'), {className: 'material'}],
["woo_commerce", require('@material-icons/svg/svg/woo_commerce/baseline.svg'), {className: 'material'}],
["wordpress", require('@material-icons/svg/svg/wordpress/baseline.svg'), {className: 'material'}],
["work", require('@material-icons/svg/svg/work/baseline.svg'), {className: 'material'}],
["work_off", require('@material-icons/svg/svg/work_off/baseline.svg'), {className: 'material'}],
["work_outline", require('@material-icons/svg/svg/work_outline/baseline.svg'), {className: 'material'}],
["workspace_premium", require('@material-icons/svg/svg/workspace_premium/baseline.svg'), {className: 'material'}],
["workspaces", require('@material-icons/svg/svg/workspaces/baseline.svg'), {className: 'material'}],
["wrap_text", require('@material-icons/svg/svg/wrap_text/baseline.svg'), {className: 'material'}],
["wrong_location", require('@material-icons/svg/svg/wrong_location/baseline.svg'), {className: 'material'}],
["wysiwyg", require('@material-icons/svg/svg/wysiwyg/baseline.svg'), {className: 'material'}],
["yard", require('@material-icons/svg/svg/yard/baseline.svg'), {className: 'material'}],
["youtube_searched_for", require('@material-icons/svg/svg/youtube_searched_for/baseline.svg'), {className: 'material'}],
["zoom_in", require('@material-icons/svg/svg/zoom_in/baseline.svg'), {className: 'material'}],
["zoom_in_map", require('@material-icons/svg/svg/zoom_in_map/baseline.svg'), {className: 'material'}],
["zoom_out", require('@material-icons/svg/svg/zoom_out/baseline.svg'), {className: 'material'}],
["zoom_out_map", require('@material-icons/svg/svg/zoom_out_map/baseline.svg'), {className: 'material'}]
]