
import Icon from '../index'

export default Icon

Icon.register(
	['book-jacket', require('./book-jacket.svg.html')],
	['paperback', require('./paperback.svg.html')],
	['endsheet', require('./endsheet.svg.html')],
	['book-insert', require('./book-insert.svg.html')],
	['partner', require('./partner.svg.html')],
	['directory-files', require('./directory-files.svg.html')],
	['design', require('./design.svg.html')],
	['book-interior', require('./book-interior.svg.html')],
	['book-case-wrap', require('./book-case-wrap.svg.html')],
	['pod', require('./pod.svg.html')],
	['alert', require('./alert.svg.html')],
	['amazon', require('./amazon.svg.html')],
	['company', require('./company.svg.html')],
	['android', require('./android.svg.html')],
	['appstore', require('./appstore.svg.html')],
	['arrows-ccw', require('./arrows-ccw.svg.html')],
	['article-alt', require('./article-alt.svg.html')],
	['at', require('./at.svg.html')],
	['attention-1', require('./attention-1.svg.html')],
	['attention-circle', require('./attention-circle.svg.html')],
	['award', require('./award.svg.html')],
	['back-in-time', require('./back-in-time.svg.html')],
	['bank', require('./bank.svg.html')],
	['barcode', require('./barcode.svg.html')],
	['basket', require('./basket.svg.html')],
	['blackstone', require('./blackstone.svg.html')],
	['block', require('./block.svg.html')],
	['book-1', require('./book-1.svg.html')],
	['book-open', require('./book-open.svg.html')],
	['books', require('./books.svg.html')],
	['box-1', require('./box-1.svg.html')],
	['box', require('./box.svg.html')],
	['braille', require('./braille.svg.html')],
	['briefcase', require('./briefcase.svg.html')],
	['bucket', require('./bucket.svg.html')],
	['bug', require('./bug.svg.html')],
	['bus', require('./bus.svg.html')],
	['calc', require('./calc.svg.html')],
	['camera', require('./camera.svg.html')],
	['campsite', require('./campsite.svg.html')],
	['cancel-1', require('./cancel-1.svg.html')],
	['cancel-circled', require('./cancel-circled.svg.html')],
	['cancel', require('./cancel.svg.html')],
	['cassette', require('./cassette.svg.html')],
	['category', require('./category.svg.html')],
	['cc-nc-eu', require('./cc-nc-eu.svg.html')],
	['cc-nc', require('./cc-nc.svg.html')],
	['ccw', require('./ccw.svg.html')],
	['cd', require('./cd.svg.html')],
	['certificate', require('./certificate.svg.html')],
	['chart-1', require('./chart-1.svg.html')],
	['chart-bar', require('./chart-bar.svg.html')],
	['chart-line', require('./chart-line.svg.html')],
	['chart-pie', require('./chart-pie.svg.html')],
	['chart', require('./chart.svg.html')],
	['chat-1', require('./chat-1.svg.html')],
	['chat-empty', require('./chat-empty.svg.html')],
	['chat', require('./chat.svg.html')],
	['check-empty', require('./check-empty.svg.html')],
	['check', require('./check.svg.html')],
	['check-box', require('./check-box.svg.html')],
	['check-box-active', require('./check-box-active.svg.html')],
	['check-circle', require('./check-circle.svg.html')],
	['check-circle-uncheck', require('./check-circle-uncheck.svg.html')],
	['circle-empty', require('./circle-empty.svg.html')],
	['circle', require('./circle.svg.html')],
	['clearclose', require('./clearclose.svg.html')],
	['clock', require('./clock.svg.html')],
	['cog-alt', require('./cog-alt.svg.html')],
	['cog', require('./cog.svg.html')],
	['collections-photos', require('./collections-photos.svg.html')],
	['collections-text', require('./collections-text.svg.html')],
	['columns', require('./columns.svg.html')],
	['comment-2', require('./comment-2.svg.html')],
	['comment', require('./comment.svg.html')],
	['compass-1', require('./compass-1.svg.html')],
	['compass', require('./compass.svg.html')],
	['cube', require('./cube.svg.html')],
	['cubes', require('./cubes.svg.html')],
	['cw', require('./cw.svg.html')],
	['database', require('./database.svg.html')],
	['desktop', require('./desktop.svg.html')],
	['direction', require('./direction.svg.html')],
	['doc-1', require('./doc-1.svg.html')],
	['doc-2', require('./doc-2.svg.html')],
	['doc-inv', require('./doc-inv.svg.html')],
	['doc-text-inv', require('./doc-text-inv.svg.html')],
	['doc-text', require('./doc-text.svg.html')],
	['doc', require('./doc.svg.html')],
	['docs', require('./docs.svg.html')],
	['dollar-1', require('./dollar-1.svg.html')],
	['dollar', require('./dollar.svg.html')],
	['dot-2', require('./dot-2.svg.html')],
	['dot-3', require('./dot-3.svg.html')],
	['dot', require('./dot.svg.html')],
	['down-1', require('./down-1.svg.html')],
	['down-circled', require('./down-circled.svg.html')],
	['down-open', require('./down-open.svg.html')],
	['down', require('./down.svg.html')],
	['download-cloud-1', require('./download-cloud-1.svg.html')],
	['download-cloud', require('./download-cloud.svg.html')],
	['dropbox', require('./dropbox.svg.html')],
	['droplet', require('./droplet.svg.html')],
	['erase', require('./erase.svg.html')],
	['export', require('./export.svg.html')],
	['file-excel', require('./file-excel.svg.html')],
	['file-image', require('./file-image.svg.html')],
	['file-pdf', require('./file-pdf.svg.html')],
	['file-powerpoint', require('./file-powerpoint.svg.html')],
	['file-word', require('./file-word.svg.html')],
	['folder-open-empty', require('./folder-open-empty.svg.html')],
	['font-size', require('./font-size.svg.html')],
	['format-color-fill', require('./format-color-fill.svg.html')],
	['gauge-1', require('./gauge-1.svg.html')],
	['gauge', require('./gauge.svg.html')],
	['github', require('./github.svg.html')],
	['gitlab', require('./gitlab.svg.html')],
	['globe', require('./globe.svg.html')],
	['google', require('./google.svg.html')],
	['hdd', require('./hdd.svg.html')],
	['headphones', require('./headphones.svg.html')],
	['heart', require('./heart.svg.html')],
	['height', require('./height.svg.html')],
	['home-1', require('./home-1.svg.html')],
	['home', require('./home.svg.html')],
	['info-1', require('./info-1.svg.html')],
	['info-circled', require('./info-circled.svg.html')],
	['instagram', require('./instagram.svg.html')],
	['invert-colors-on', require('./invert-colors-on.svg.html')],
	['key-inv', require('./key-inv.svg.html')],
	['key', require('./key.svg.html')],
	['layers', require('./layers.svg.html')],
	['leaf', require('./leaf.svg.html')],
	['left-1', require('./left-1.svg.html')],
	['left-circled-1', require('./left-circled-1.svg.html')],
	['left-circled', require('./left-circled.svg.html')],
	['left-open-big', require('./left-open-big.svg.html')],
	['left-open', require('./left-open.svg.html')],
	['left', require('./left.svg.html')],
	['link-ext', require('./link-ext.svg.html')],
	['list-add', require('./list-add.svg.html')],
	['list-numbered', require('./list-numbered.svg.html')],
	['list2', require('./list2.svg.html')],
	['location', require('./location.svg.html')],
	['lock-open-alt', require('./lock-open-alt.svg.html')],
	['mail-1', require('./mail-1.svg.html')],
	['megaphone', require('./megaphone.svg.html')],
	['minus-circled', require('./minus-circled.svg.html')],
	['minus-squared-alt', require('./minus-squared-alt.svg.html')],
	['minus-squared', require('./minus-squared.svg.html')],
	['minus', require('./minus.svg.html')],
	['move', require('./move.svg.html')],
	['move-item', require('./move-item.svg.html')],
	['music', require('./music.svg.html')],
	['na', require('./na.svg.html')],
	['net', require('./net.svg.html')],
	['note', require('./note.svg.html')],
	['note-outline', require('./note-outline.svg.html')],
	['ok-circled', require('./ok-circled.svg.html')],
	['ok-circled2', require('./ok-circled2.svg.html')],
	['ok', require('./ok.svg.html')],
	['paragraph-center', require('./paragraph-center.svg.html')],
	['paste', require('./paste.svg.html')],
	['phone', require('./phone.svg.html')],
	['photo_size_select_small', require('./photo_size_select_small.svg.html')],
	['picture', require('./picture.svg.html')],
	['play', require('./play.svg.html')],
	['plus-1', require('./plus-1.svg.html')],
	['plus-circled', require('./plus-circled.svg.html')],
	['plus-squared', require('./plus-squared.svg.html')],
	['plus', require('./plus.svg.html')],
	['print-error', require('./print-error.svg.html')],
	['qrcode', require('./qrcode.svg.html')],
	['quote-right', require('./quote-right.svg.html')],
	['rain-inv', require('./rain-inv.svg.html')],
	['rain', require('./rain.svg.html')],
	['recycle', require('./recycle.svg.html')],
	['reply-all', require('./reply-all.svg.html')],
	['resize-full', require('./resize-full.svg.html')],
	['resize-small', require('./resize-small.svg.html')],
	['retweet', require('./retweet.svg.html')],
	['right-1', require('./right-1.svg.html')],
	['right-circled-1', require('./right-circled-1.svg.html')],
	['right-circled', require('./right-circled.svg.html')],
	['right-open-big', require('./right-open-big.svg.html')],
	['right-open', require('./right-open.svg.html')],
	['right', require('./right.svg.html')],
	['scissors', require('./scissors.svg.html')],
	['scissors1', require('./scissors1.svg.html')],
	['settings', require('./settings.svg.html')],
	['shield', require('./shield.svg.html')],
	['shop', require('./shop.svg.html')],
	['shuffle', require('./shuffle.svg.html')],
	['signal', require('./signal.svg.html')],
	['sort-alt-down', require('./sort-alt-down.svg.html')],
	['sort-alt-up', require('./sort-alt-up.svg.html')],
	['sort-down', require('./sort-down.svg.html')],
	['sort-name-down', require('./sort-name-down.svg.html')],
	['sort-name-up', require('./sort-name-up.svg.html')],
	['sort-number-down', require('./sort-number-down.svg.html')],
	['sort-number-up', require('./sort-number-up.svg.html')],
	['sort-up', require('./sort-up.svg.html')],
	['sort', require('./sort.svg.html')],
	['stackoverflow', require('./stackoverflow.svg.html')],
	['stamp', require('./stamp.svg.html')],
	['star-empty', require('./star-empty.svg.html')],
	['stop', require('./stop.svg.html')],
	['strikethrough', require('./strikethrough.svg.html')],
	['suitcase', require('./suitcase.svg.html')],
	['tag-1', require('./tag-1.svg.html')],
	['tag', require('./tag.svg.html')],
	['tags', require('./tags.svg.html')],
	['tasks', require('./tasks.svg.html')],
	['terminal', require('./terminal.svg.html')],
	['th-list-1', require('./th-list-1.svg.html')],
	['th-list', require('./th-list.svg.html')],
	['th-thumb', require('./th-thumb.svg.html')],
	['thumbs-down', require('./thumbs-down.svg.html')],
	['thumbs-up', require('./thumbs-up.svg.html')],
	['ticket', require('./ticket.svg.html')],
	['top-list', require('./top-list.svg.html')],
	['underline', require('./underline.svg.html')],
	['up-1', require('./up-1.svg.html')],
	['up-circled', require('./up-circled.svg.html')],
	['up-open', require('./up-open.svg.html')],
	['up', require('./up.svg.html')],
	['upload-cloud', require('./upload-cloud.svg.html')],
	['user-add', require('./user-add.svg.html')],
	['users', require('./users.svg.html')],
	['vcard', require('./vcard.svg.html')],
	['video', require('./video.svg.html')],
	['videocam', require('./videocam.svg.html')],
	['view-mode', require('./view-mode.svg.html')],
	['window', require('./window.svg.html')],
	['wrench-1', require('./wrench-1.svg.html')],
	['wrench', require('./wrench.svg.html')],
	['youtube', require('./youtube.svg.html')]
)
